import React, { useMemo } from 'react';
import axios from 'axios';
//import GWStatus from '../personalDash/gwStatus'
//import TableSort from "table-sort-js/table-sort.js";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FilterList } from '@mui/icons-material';


class WaiverPlannerLayout extends React.Component {
  constructor(props) {
    super(props);
    //const distinctValues = ['GKP', 'DEF', 'MID', 'FWD']; // add this line to initialize distinctValues
    this.state = {
      leagueID: this.props.leagueID,
      globalData: '',
      errorMessage: false,
      //selectedPosition: new Set(distinctValues), // add all distinct values to Set()
      //filter: false,
      //filteredData: []
      isSpecialLeague: false,
      selectedLeagueSize: 8,
      selectedTypes: new Set(),
      inLineup: new Set(),
      isOwned: new Set(),
      filteredSummaryData: [],
      rosteredHistory: null,
      selectedPlayer: null,
      searchText: '',
      filterTeams: new Set(),
      sortedColumn: null,
      sortDirection: null, // 'asc', 'desc', or null
      selectAllChecked: true,
      placeholder: false
    }
  }

  componentDidMount() {
    if (this.state.placeholder != true) {
    this.setSpecialLeague(() => this.getData(this.props.leagueID));}
    //this.getData(this.props.leagueID);
    //TableSort()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({ globalData: '' }, () => {
        this.setSpecialLeague(() => this.updateContent(this.props.leagueID));
      });
      //this.initializeTable();
    }
  }

  updateContent(prop) {
    this.setState({ leagueID: prop })
    this.getData(prop)
  }

  toggleFilter = () => {
    !this.state.filter ? this.setState({ filter: true }) : this.setState({ filter: false })
  }

  getData(prop) {
    if (prop) {
      if (this.state.isSpecialLeague == true) {
        axios
          .post('/api/getGlobalData', { leagueID: prop, leagueSize: this.state.selectedLeagueSize })
          .then((res) => {
            const response = res.data;
            this.setState({ globalData: response, filteredSummaryData: response.liveSummary }, () => {
              const typeNames = this.getDistinctTypeShortNames();
              const inLineup = this.getInLineup();
              const isOwned = this.getisOwned();
              const teams = this.getTeams();
              this.setState({ selectedTypes: new Set(typeNames), inLineup: new Set(inLineup), isOwned: new Set(isOwned), filterTeams: new Set(teams) })
            })
            this.setState({ errorMessage: false });

          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
      else {
        axios
          .post('/api/getGlobalData', { leagueID: prop })
          .then((res) => {
            const response = res.data;
            this.setState({ globalData: response, filteredSummaryData: response.liveSummary }, () => {
              const typeNames = this.getDistinctTypeShortNames();
              const inLineup = this.getInLineup();
              const isOwned = this.getisOwned();
              const teams = this.getTeams();
              this.setState({ selectedTypes: new Set(typeNames), inLineup: new Set(inLineup), isOwned: new Set(isOwned), filterTeams: new Set(teams) })
            })
            this.setState({ errorMessage: false });

          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
    }
  }

  handleLeagueSizeChange = (event) => {
    this.setState({
      selectedLeagueSize: event.target.value,
    }, () => {
      this.getData(this.props.leagueID)
      // You can call the function you want to run here
    });
  }

  createNumbersArray() {
    let numbers = [];
    for (let i = 2; i <= 16; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  setSpecialLeague(callback) {
    const isSpecialLeague = ['9999996', '9999997', '9999998'].includes(this.props.leagueID);
    this.setState({ isSpecialLeague }, callback);
  }

  getDistinctTypeShortNames() {
    if (!this.state.globalData || !this.state.globalData.liveSummary) {
      return [];
    }
    const typeNames = this.state.globalData.liveSummary.map(item => item.type_short_name);
    return [...new Set(typeNames)];
  }

  getisOwned() {
    const isOwned = ['Owned', 'Available']
    return [...new Set(isOwned)];
  }

  getInLineup() {
    if (!this.state.globalData || !this.state.globalData.liveSummary) {
      return [];
    }
    const isInLineup = this.state.globalData.liveSummary.map(item => item.starting);
    return [...new Set(isInLineup)];
  }

  getTeams() {
    if (!this.state.globalData || !this.state.globalData.liveSummary) {
      return [];
    }
    const teams = this.state.globalData.liveSummary.map(item => item.team_short_name);
    return [...new Set(teams)];
  }

  handleFilterButtonClick(labelValue, label) {
    const selectedTypes = new Set(this.state.selectedTypes);
    const isOwned = new Set(this.state.isOwned);
    const inLineup = new Set(this.state.inLineup);
    const filterTeams = new Set(this.state.filterTeams);

    if (label == 'typeFilter') {
      if (selectedTypes.has(labelValue)) {
        selectedTypes.delete(labelValue);
      } else {
        selectedTypes.add(labelValue);
      }
    }

    if (label == 'lineupFilter') {
      if (inLineup.has(labelValue)) {
        inLineup.delete(labelValue);
      } else {
        inLineup.add(labelValue);
      }
    }

    if (label == 'ownedFilter') {
      if (isOwned.has(labelValue)) {
        isOwned.delete(labelValue);
      } else {
        isOwned.add(labelValue);
      }
    }

    if (label == 'teamsFilter') {
      if (filterTeams.has(labelValue)) {
        filterTeams.delete(labelValue);
      } else {
        filterTeams.add(labelValue);
      }
    }

    let filteredSummaryData = this.state.globalData.liveSummary.filter(item => selectedTypes.has(item.type_short_name));
    filteredSummaryData = filteredSummaryData.filter(item => inLineup.has(item.starting));
    filteredSummaryData = filteredSummaryData.filter(item => {
      if (isOwned.has('Available') && !item.entry_name) {
        return true;  // Keep the item in the resulting array
      } else if (isOwned.has('Owned') && item.entry_name) {
        return true;  // Keep the item in the resulting array
      }
      return false;  // Discard the item
    });
    filteredSummaryData = filteredSummaryData.filter(item => filterTeams.has(item.team_short_name));

    const selectAllChecked = filterTeams.size === this.getTeams().length;

    this.setState({ selectedTypes, isOwned, inLineup, filteredSummaryData, filterTeams, selectAllChecked});
  }

  handleSearchInputChange = (event) => {
    const searchText = event.target.value;
    const selectedTypes = new Set(this.state.selectedTypes);
    const isOwned = new Set(this.state.isOwned);
    const inLineup = new Set(this.state.inLineup);

    let filteredSummaryData = this.state.globalData.liveSummary.filter(item => selectedTypes.has(item.type_short_name));
    filteredSummaryData = filteredSummaryData.filter(item => inLineup.has(item.starting));
    filteredSummaryData = filteredSummaryData.filter(item => {
      if (isOwned.has('Available') && !item.entry_name) {
        return true;  // Keep the item in the resulting array
      } else if (isOwned.has('Owned') && item.entry_name) {
        return true;  // Keep the item in the resulting array
      }
      return false;  // Discard the item
    });
    filteredSummaryData = filteredSummaryData.filter(item => item.web_name.toLowerCase().includes(searchText.toLowerCase()));

  this.setState({ filteredSummaryData });
}


  getRosteredHistory(el_id) {
    axios
      .post('/api/getRosteredHistory', { el_id: el_id, leagueSize: this.state.globalData.league_size })
      .then((res) => {
        const response = res.data;
        this.setState({ rosteredHistory: response })
      })
      .catch((error) => this.setState({ errorMessage: true }));

    const player = this.state.globalData.liveSummary.find(item => item.el_id === el_id);

    if (player && player.web_name) {
      this.setState({ selectedPlayer: player.web_name });
    }
  }

  onColumnHeaderClick = (columnKey) => {
    console.log('clicked')
    this.setState((prevState) => {
      let { sortedColumn, sortDirection } = prevState;
      if (sortedColumn === columnKey) {
        if (sortDirection === 'asc') {
          sortDirection = 'desc';
        } else if (sortDirection === 'desc') {
          sortDirection = null;
          sortedColumn = null;
        } else {
          sortDirection = 'asc';
        }
      } else {
        sortedColumn = columnKey;
        sortDirection = 'asc';
      }
      return { sortedColumn, sortDirection };
    });
  }

  getCellClassName = (percentValue) => {
    const min = 0;  // change this to your desired minimum
    const max = 0.8

    const numClasses = 8;
    const intervalSize = (max - min) / numClasses;

    // Find out which interval the percentValue belongs to
    let intervalIndex = Math.ceil((percentValue - min) / intervalSize);

    // Ensure it does not exceed the number of available classes
    intervalIndex = Math.min(intervalIndex, numClasses);

    return `fdr-${intervalIndex}`;
  }

  handleSelectAllChange = () => {
    const allTeams = new Set(this.getTeams());
    let { filterTeams, selectAllChecked, globalData, selectedTypes, isOwned, inLineup } = this.state;
  
    if (!selectAllChecked) {
      // If 'Select All' is not checked, select all teams
      filterTeams = allTeams;
    } else {
      // If 'Select All' is checked, clear the selection
      filterTeams.clear();
    }
  
    let filteredSummaryData = globalData.liveSummary.filter(item => 
      selectedTypes.has(item.type_short_name) &&
      inLineup.has(item.starting) &&
      ((isOwned.has('Available') && !item.entry_name) || 
      (isOwned.has('Owned') && item.entry_name)) &&
      filterTeams.has(item.team_short_name)
    );
  
    this.setState({ filterTeams, selectAllChecked: !selectAllChecked, filteredSummaryData });
  };


  render() {
    const { globalData, isSpecialLeague, filteredSummaryData, selectedPlayer, sortedColumn, sortDirection, placeholder } = this.state
    const numbersArray = this.createNumbersArray();

    if (placeholder) {
      return (<div>
        <h2>The new Waiver Planner will be ready soon.</h2>
        <p>We have made major upgrades to our prediction model, and are finalising this before launching our improved Waiver Planner on Monday 29th July.</p>
        </div>)
    }


    if (!globalData) {
      return (
        <div>
       <h2>Waiver Planner</h2> 
      <div className="skeleton-container">
      <div className="skeleton-header"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-block"></div>
      </div></div>)
    }

    if (this.state.errorMessage & !globalData) {
      return <div><p>There was en error in getting the Waiver Planner data. Please refresh the page to try again</p></div>;
    }

    if (globalData.downloading == '1') {
      return (<div><p>The {globalData.deadline} data is downloading. Please come back in around 10 minutes.</p></div>);
    }

    const distinctValues = Array.from(new Set(globalData.liveSummary.map((row) => row.type_short_name)));
    distinctValues.sort((a, b) => {
      const order = { GKP: 0, DEF: 1, MID: 2, FWD: 3 };
      return order[a] - order[b];
    });
    //const filteredData = globalData.liveSummary.filter((row) => selectedPosition.has(row.type_short_name));

    const LiveSummaryTable = ({ data }) => {

      const columns = useMemo(
        () => [
          { header: 'Player', accessorKey: 'web_name', className:'w-120'},
          { header: 'Team', accessorKey: 'team_short_name', className:'text-center'},
          { header: 'Fixtures', accessorKey: 'fixture', className:'text-center' },
          { header: 'In Lineup', accessorKey: 'in_lineup', className:'text-center' },
          { header: 'Rostered', accessorKey: 'rostered', className:'text-center' },
          { header: 'Waivered%', accessorKey: 'waivered', className:'text-center'},
          { header: 'Top 100 Waivered%', accessorKey: 'top_waivered', className:'text-center'},
          { header: 'FA %', accessorKey: 'free_agent', className:'text-center' },
          { header: 'Win', accessorKey: 'win_percent', className:'text-center' },
          { header: 'CS', accessorKey: 'cs_percent', className:'text-center' },
          { header: 'xPts1', accessorKey: 'xPts1', className:'text-center'},
          { header: 'xPts3', accessorKey: 'xPts3', className:'text-center'},
          { header: 'xPts5', accessorKey: 'xPts5', className:'text-center'},
          { header: 'History', accessorKey: 'rostered_map', className:'text-center'},
        ],
        [this.state.isSpecialLeague]
      )

      const tableData = data.map(item => ({
        web_name: 
        <div>
          <div className="flex g-5 align-center">
          <span className="text-overflow">{item.web_name} </span> <span className={item.type_short_name}>{item.type_short_name}</span>
          </div>
          <div>
          {item.player_first_name ? <span className="italic">{item.player_first_name} </span> : '-'}
          </div>
        </div> 
        || '-',
        team_short_name: item.team_short_name || '-',
        fixture: <span className={`fixture ${this.getCellClassName(item.win_percent)}`}>{item.fixture} </span> || '-',
        rostered: item.percent_picked ? `${item.percent_picked}%` : '-',
        waivered: item.waivered ? `${item.waivered}%` : '-',
        top_waivered: item.top_waivered_percent ? `${item.top_waivered_percent}%` : '-',
        free_agent: item.free_agent ? `${item.free_agent}%` : '-',
        win_percent: item.win_percent ? `${(item.win_percent).toFixed(0)}%` : '-',
        cs_percent: item.cs_percent ? `${(item.cs_percent).toFixed(0)}%` : '-',
        xPts1: Number(item.gw1_xFPL_pts).toFixed(1) || '-',
        xPts3: Number(item.gw3_xFPL_pts).toFixed(1) || '-',
        xPts5: Number(item.gw5_xFPL_pts).toFixed(1) || '-',
        in_lineup: <span className="text-center block">{item.starting == 1 ? 'Y': 'N'}</span>,
        rostered_map: <span className="onclick bold" onClick={() => this.getRosteredHistory(item.el_id)}>Get Map</span>
      }))

      const sortedData = [...tableData].sort((a, b) => {
        let aValue = a[sortedColumn];
        let bValue = b[sortedColumn];

        if (aValue === '-' || aValue === Infinity) return 1;
        if (bValue === '-' || bValue === Infinity) return -1;

        // Check if values are numbers
        const aValueIsNumber = !isNaN(parseFloat(aValue));
        const bValueIsNumber = !isNaN(parseFloat(bValue));

        if (aValueIsNumber && bValueIsNumber) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (!sortedColumn || !sortDirection) return 0;

        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });

      return (
        <table className="h2h-table">
          <thead>
            <tr>
              {columns.map(column => (
                <th className={column.className} key={column.accessorKey} onClick={() => this.onColumnHeaderClick(column.accessorKey)}>
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(column => (
                  <td className={column.className} key={column.accessorKey}>{row[column.accessorKey]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    const typeNames = this.getDistinctTypeShortNames();
    const isOwned = this.getisOwned();
    const inLineup = this.getInLineup();

    const teams = [...new Set(globalData.liveSummary.map((data) => data.team_short_name))]

    return (

      <div>
        {isSpecialLeague == true &&
          <div className="mb-20">
            <h3 className="mb-10">Select League Size</h3>
            <select
              className="purple-border"
              value={this.state.selectedLeagueSize}
              onChange={this.handleLeagueSizeChange}
            >
              <option value="">Select a league size</option>
              {numbersArray.map((number, index) => (
                <option key={index} value={number}>{number}</option>
              ))}
            </select>
          </div>
        }
        {globalData.downloading == 0 ?
          <>
            <div className="mb-20">
              <h3 className="mb-10">Waiver Planner</h3>
              <p className="italic mb-20">Rostered and waiver data is for all {globalData.league_size} person leagues. Use the buttons below to filter the table and click on the headings to sort.</p>
              <div className="flex flex-wrap g-10 mb-20">
                <div className="filter-wrapper">
                  <p className="bold mb-5">Position</p>
                  {typeNames.map(typeName => (
                    <button key={typeName} className={this.state.selectedTypes.has(typeName) ? 'button-on' : 'button-off'} onClick={() => this.handleFilterButtonClick(typeName, 'typeFilter')}>
                      {typeName}
                    </button>))}

                </div>
                <div className="filter-wrapper">
                  <p className="bold mb-5">Is Available?</p>
                  {isOwned.map(ownedValue => (
                    <button key={ownedValue} className={this.state.isOwned.has(ownedValue) ? 'button-on' : 'button-off'} onClick={() => this.handleFilterButtonClick(ownedValue, 'ownedFilter')}>
                      {ownedValue}
                    </button>))}

                </div>
                <div className="filter-wrapper">
                  <p className="bold mb-5">In Starting XI?</p>
                  {inLineup.map(lineupValue => (
                    <button key={lineupValue} className={this.state.inLineup.has(lineupValue) ? 'button-on' : 'button-off'} onClick={() => this.handleFilterButtonClick(lineupValue, 'lineupFilter')}>
                      {lineupValue == 1 ? 'Y' : 'N'}
                    </button>))}

                </div>
                <div className="filter-wrapper">
                  <p className="bold mb-5">Player Search</p>
                  <input
                    type="text"
                    placeholder="Enter player name"
                    onChange={this.handleSearchInputChange}
                  />
                </div>
                <div className="filter-wrapper">
                <p className="bold mb-5">Team</p>
            <button className="filter" onClick={this.toggleFilter}><FilterList/></button>
            {!this.state.filter ? null :
              <div className="filter-container flex column g-5 mb-10 text-left ">
                <label className="text-overflow">
                  <input
                    type="checkbox"
                    name="selectAll"
                    checked={this.state.selectAllChecked}
                    onChange={this.handleSelectAllChange}
                  />
                  Select All
                </label>
                {teams.map((value, index) => (
                  <label className="text-overflow" key={index}>
                    <input
                      type="checkbox"
                      name={value}
                      checked={this.state.filterTeams.has(value)}
                      onChange={() => this.handleFilterButtonClick(value, 'teamsFilter')}
                      />
                    {value}
                  </label>
                ))}
              </div>}</div>
              </div>
              <div className="table-wrapper vh-60"><LiveSummaryTable data={filteredSummaryData} /></div>
            </div>
            <div className="content-wrapper mb-20">
              <h3>Rostered History - {selectedPlayer}</h3>
              {this.state.rosteredHistory ? (
                <div className="mt-20 vh-50" style={{ width: '100%' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={this.state.rosteredHistory}
                      margin={{ top: 0, right: 0, left: 5, bottom: 20 }}>
                      <CartesianGrid strokeDasharray="0 10" />
                      <XAxis dataKey="gw" label={{ value: 'Gameweek', position: 'insideBottom', dy: 15 }} />
                      <YAxis tickFormatter={(tick) => `${(tick * 100).toFixed(1)}%`} label={{ value: '% Rostered', position: 'insideLeft', dx: 0, angle: -90 }} />
                      <Tooltip formatter={(value) => `${(value * 100).toFixed(1)}%`} />
                      <Line dataKey="percent_picked" fill="#8884d8" strokeWidth={3} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : null}
            </div>
            <div>
              <p>GW{globalData.current_gw} {globalData.deadline} have been processed. </p></div>
          </>
          :
          <div>
            <p>The deadline for GW{globalData.current_gw} {globalData.deadline} has passed and the data is now downloading. Please check back in about 5 minutes.</p>
          </div>}
      </div>
    );
  }
}

export default WaiverPlannerLayout;
