import React, { useMemo } from 'react';
import axios from 'axios';
import GWStatus from '../personalDash/gwStatus'
import Shield from '../../images/logo_shield.png';
//import TableSort from "table-sort-js/table-sort.js";
import html2canvas from 'html2canvas';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



class GlobalRankLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      globalRankDetail: '',
      globalRankSummary: '',
      scoring: '',
      gwResponse: {},
      errorMessage: false,
      gw: this.props.gw
    }
  }

  componentDidMount() {
    if (this.state.gw != 0) {
    this.getData(this.props.leagueID)};
  }

  componentDidUpdate(prevProps) {                                           
    if (prevProps.leagueID !== this.props.leagueID) {
        this.setState({ globalRankDetail: '' });
        this.updateContent(this.props.leagueID)  
        //this.initializeTable();  
    }
  }

  updateContent(prop) {
    this.setState({ league_ids: prop })
    this.getData(prop)           
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  getData(prop) {
    if (prop) {
      axios.post('/api/getGlobalRank', {leagueID: prop})
      .then((res) => {
        const detail = res.data.globalRankDetail;
        const summary = res.data.globalRankSummary;
        const rankMap = res.data.globalRankMap;
        this.setState({globalRankSummary : summary, globalRankMap : rankMap, globalRankDetail : detail } , () => {
          //this.initializeTable(); // initialize the table once the data is loaded
        })
        this.setState({errorMessage: false});
      })
      .catch((error) => this.setState({errorMessage: true}));
    }
  }


  handleDownloadImage = () => {
    const divToCapture = document.querySelector('.global-rank');
    const tempP = document.createElement('p');
    tempP.textContent = 'Find out your draft rank at DraftFC.co.uk';
    tempP.classList.add('bold', 'purple');
    divToCapture.appendChild(tempP);
    html2canvas(divToCapture).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'ofpl_global_rank.png';
      link.href = imgData;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      divToCapture.removeChild(tempP); // remove the temporary <p> element
    });
  }

  

  /*initializeTable() {
    if (!this.table) {
      // check if the table reference is available
      this.table = new TableSort(document.querySelector('.table-sort')); // initialize the table-sort-js plugin
    }
  }*/
  

  render() {
    const { globalRankDetail, globalRankSummary, globalRankMap, gwResponse, errorMessage, gw } = this.state

    if (gw == 0) {
      return (<div>
        <h2>Global Rankings will be available after GW1</h2>
        <p>As the season hasn't started, there is no data to display in this view.</p>
        </div>)
    }
    
    if (!globalRankDetail || !gwResponse) {
      return (
        <div>
        <h2>Global Rank</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>
        )
    }

    if (errorMessage & !globalRankDetail) {
      return <div><p>There was en error in getting the Global Rank data. Please refresh the page to try again</p></div>;
    }


    const LiveSummaryTable = ({ data }) => {
      const columns = useMemo(
        () => [
          { header: 'League Name', accessorKey: 'name', size: 150 },
          { header: 'Global Rank', accessorKey: 'global_rank', size: 100 },
          { header: 'Scoring', accessorKey: 'scoring', size: 150 },
          { header: 'Total Pts', accessorKey: 'league_total_points', size: 100 },

        ], [])

      const tableData = data.map((item) => ({
        name: item.name || '',
        global_rank: item.global_rank || '',
        scoring: item.scoring || '',
        league_total_points: item.league_total_points.toLocaleString() || '',
      }))
    
      const [sortedColumn, setSortedColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(null);  // 'asc', 'desc', or null

    const onColumnHeaderClick = (columnKey) => {
        if (sortedColumn === columnKey) {
            if (sortDirection === 'asc') {
                setSortDirection('desc');
            } else if (sortDirection === 'desc') {
                setSortDirection(null);
                setSortedColumn(null);
            } else {
                setSortDirection('asc');
            }
        } else {
            setSortedColumn(columnKey);
            setSortDirection('asc');
        }
    }

    const sortedData = [...tableData].sort((a, b) => {
      let aValue = a[sortedColumn];
      let bValue = b[sortedColumn];

      if (aValue === '-' || aValue === Infinity) return 1;
      if (bValue === '-' || bValue === Infinity) return -1;

      // Check if values are numbers
      const aValueIsNumber = !isNaN(parseFloat(aValue));
      const bValueIsNumber = !isNaN(parseFloat(bValue));

      if (aValueIsNumber && bValueIsNumber) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
      }

      if (!sortedColumn || !sortDirection) return 0;

      if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
      } else {
          return aValue < bValue ? 1 : -1;
      }
  });
  
    return (
      <table>
          <thead>
            <tr>
              {columns.map(column => (
                <th className="onclick" key={column.accessorKey} onClick={() => onColumnHeaderClick(column.accessorKey)}>
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(column => (
                  <td key={column.accessorKey}>{row[column.accessorKey]}</td>
                ))}
              </tr>
            ))}
          </tbody>
      </table>
  );
    
    }
  

    return (
        <div>
          <div className="global-card mb-30">
          <div className="grey-bg content-wrapper mb-10">
            <div className="flex space-between"><h3 className="mb-5">GW{globalRankSummary.gw} OFPL Draft Rank</h3> <img className="shield" src={Shield} /></div>
            <div className="mb-20"><p className="italic">{globalRankSummary.league_size} person leagues</p></div>
            <div className="flex space-between"><p className="bold">#1 Ranked League Total Points</p> <p className="bold">{globalRankSummary.top_total_points.toLocaleString()}</p></div>
            <div className="flex space-between"><p className="bold">Your League Total Points</p> <p className="bold">{globalRankSummary.league_total_points.toLocaleString()}</p></div>
            <div className="flex space-between"><p className="bold">Your League Global Rank</p> <p className="bold">{globalRankSummary.league_global_rank.toLocaleString()}/{globalRankSummary.total_leagues.toLocaleString()}</p></div>
          </div>
          </div>
          <div className="content-wrapper mb-30">
            <h3>Rank History</h3>
            {globalRankMap ? (
            <div className="mt-20 vh-50" style={{ width: '100%' }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={globalRankMap}
                  margin={{ top: 0, right: 0, left: 5, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="0 10" />
                  <XAxis dataKey="gw" label={{ value: 'Gameweek', position: 'insideBottom', dy: 15 }} />
                  <YAxis tickFormatter={(tick) => `${tick}`} label={{ value: 'Rank', position: 'insideLeft', dx: 0, angle: -90 }} />
                  <Tooltip formatter={(value, name) => [`${value}`, 'Global Rank']} 
                   labelFormatter={(label) => `Gameweek ${label}`} />
                  <Line dataKey="global_rank" fill="#8884d8" strokeWidth={3} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : null}
          </div>

          <div className="mb-20">
          <h3 className="mb-10">League Global Rankings</h3>
          <div className="table-wrapper vh-50"><LiveSummaryTable data={globalRankDetail}/></div>
          </div>
  
        </div>
    );
  }
}

export default GlobalRankLayout;
