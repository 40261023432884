import React from 'react';
import { personalChartConfig } from './personalChartConfiguration';
import { h2hPersonalChartConfig } from './h2hPersonalChartConfiguration';


class H2HPersonalMenu extends React.Component {

  constructor(props){
    super(props);

  }

  handleClick(component){
    this.props.onChange(component);
  }
  
  render() {

    const buttons = h2hPersonalChartConfig.map(obj => {
      const Component = obj.component; // Get the component from the configuration
      return (
        <button onClick={() => this.handleClick(Component)} value={obj.name} className="menuItem">{obj.name}</button>
      );
    });

    return (
      <div className="menuWrapper">
        {buttons}
      </div>
    );
  }
}

export default H2HPersonalMenu;
