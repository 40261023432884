import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { CSVLink } from 'react-csv';
import { FilterList } from '@mui/icons-material';

class SubPointsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal: this.props.personalData,
      selectedMonth: new Set(['August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May']),
      viewToggle: 'table'
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ personal: this.props.personalData })
    }
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const { selectedMonth } = this.state;
  
    if (selectedMonth.has(name)) {
      selectedMonth.delete(name);
    } else {
      selectedMonth.add(name);
    }
  
    this.setState({ selectedMonth });
  };

  handleTableClick= () => {
    this.setState({viewToggle: 'table'});
  }
  
  handleChartClick= () => {
    this.setState({viewToggle: 'chart'});
  }

  toggleFilter = () => {
    !this.state.filter ? this.setState({filter: true}) : this.setState({filter: false})
  }

  
  render() {
    const { personal, selectedMonth } = this.state

    if (!personal) {
      return <div>loading...</div>;
    }

    const startStatuses = [...new Set(personal.personalSubPoints.map((data) => data.startstatus))]; // Get distinct rounds

    const months = [...new Set(personal.personalSubPoints.map((data) => data.gw_month))]; // Get distinct rounds
    const sortedMonths = months
    .map(m => [new Date(`${m} 1, 2000`).getMonth(), m])
    .sort((a, b) => (a[0] < 7 ? a[0] + 12 : a[0]) - (b[0] < 7 ? b[0] + 12 : b[0]))
    .map(([_, m]) => m);

    const filteredData = personal.personalSubPoints.filter((row) => selectedMonth.has(row.gw_month));

    const chartData = Object.values(filteredData.reduce((acc, curr) => {
      const { entry_id, entry_name, colour, short_name, fixture_total_points, startstatus } = curr;
      const key = `${entry_id}-${entry_name}-${colour}-${short_name}`;
      if (!acc[key]) {
        acc[key] = {
          entry_id,
          entry_name,
          colour,
          short_name,
          fixture_total_points: 0,
          startstatus,
          startpoints: 0,
          subpoints: 0
        };
      }
      acc[key].fixture_total_points += fixture_total_points;
      if (startstatus === 'Start') {
        acc[key].startpoints += fixture_total_points; // add fixture_total_points to startpoints
      } else if (startstatus === 'Sub') {
        acc[key].subpoints += fixture_total_points; // add fixture_total_points to subpoints
      };
      return acc;
    }, {})).sort((a, b) => b.fixture_total_points - a.fixture_total_points);
    

    const managers = [...new Set(personal.personalSubPoints.map((data) => data.entry_name))]; // Get distinct managers
    const managerPoints = managers.map(manager => ({
      entry_name: manager,
      fixture_total_points: personal.personalSubPoints
        .filter(data => data.entry_name === manager)
        .reduce((acc, data) => acc + data.fixture_total_points, 0)
    }));    
    const sortedManagers = managerPoints.sort((a, b) => b.fixture_total_points - a.fixture_total_points)
      .map(manager => manager.entry_name);
    
    const headers = [
        { label: 'Manager', key: 'manager' },
        ...startStatuses.map((startstatus) => ({ label: startstatus, key: startstatus })),
      ];
    
    const csvData = sortedManagers.map((manager) => {
        const rowData = { manager };
        startStatuses.forEach((startstatus) => {
          const csvFilteredData = filteredData.filter((item) => item.entry_name === manager && item.startstatus === startstatus);
          const sum = csvFilteredData.reduce((accumulator, currentValue) => accumulator + currentValue.fixture_total_points, 0);
          rowData[startstatus] = sum || 0;
        });
        return rowData;
      });  

    return (
      <div>
        <div className="content-wrapper mb-10">
        <div className="flex flex-wrap space-between mb-20 align-center g-10">
            <h3 className="mb-0">Start v Sub Points</h3>
            <div className="chart-buttons flex flex-wrap space-between align-center g-20">
            <button className="show-desktop download-data"><CSVLink className="download-link" data={csvData} headers={headers} filename={'subPoints_data.csv'}>Download Data</CSVLink></button>
                <div className="tablechart-toggle onclick">
                  <button className={this.state.viewToggle == 'table' ? "active": null} onClick={this.handleTableClick}>Table</button>
                  <button className={this.state.viewToggle == 'chart' ? "active": null} onClick={this.handleChartClick}>Chart</button>
                </div>
              <div className="text-right">
                <button className="filter" onClick={this.toggleFilter}><FilterList/></button>
                {!this.state.filter ? null :
                  <div className="filter-container flex column g-5 mb-10 text-left ">
                    {sortedMonths.map((value, index) => (
                      <label className="text-overflow" key={index}>
                        <input
                          type="checkbox"
                          name={value}
                          checked={selectedMonth.has(value)}
                          onChange={this.handleCheckboxChange}
                        />
                        {value}
                      </label>
                    ))}
                  </div>}</div>
            </div>
          </div>
          {this.state.viewToggle == 'table' ?
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Manager</th>
                    {startStatuses.map((startstatus) => (
                      <th key={startstatus}>{startstatus}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortedManagers.map((manager) => (
                    <tr key={manager}>
                      <td className="content-width">{manager}</td>
                      {startStatuses.map((startstatus) => {
                        const tableFilteredData = filteredData.filter((item) => item.entry_name === manager && item.startstatus === startstatus);
                        const sum = tableFilteredData.reduce((accumulator, currentValue) => accumulator + currentValue.fixture_total_points, 0);
                        return (
                          <td className="content-width" key={`${manager}-${startstatus}`}>
                            {sum > 0 ? <div>{sum}</div> : ''}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> :
            <div className="mt-20 vh-50" style={{ width: '100%' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="short_name" label={{ value: 'Manager', position: 'insideBottom', dy: 10, fontSize: '12px' }} tick={{ fontSize: '12px' }} />
                  <YAxis label={{ value: 'Points', position: 'insideLeft', dx: 10, angle: -90, fontSize: '12px' }} tick={{ fontSize: '12px' }}/>
                  <Tooltip dataKey="entry_name" />
                  <Bar dataKey="startpoints" radius={[4, 4, 0, 0]}>
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill={data.colour} />
                    ))}
                  </Bar>
                  <Bar dataKey="subpoints" radius={[4, 4, 0, 0]}>
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill={data.colour} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>}
        </div>
        <button className="show-mobile fullwidth mb-20 download-data"><CSVLink className="download-link" data={csvData} headers={headers} filename={'subPoints_data.csv'}>Download Data</CSVLink></button>
        <div className="br-16 p-20 grey-bg flex flex-wrap flex-start g-10">
        {sortedManagers.map((manager) => {
          const managerData = personal.personalTotalPoints.find((data) => data.entry_name === manager);
          return (
            <span key={manager} className="p-5">
              <span className="circle" style={{background: managerData.colour }}></span>
              {manager}
            </span>
          );
        })}</div>

      </div>
    );
  }
}

export default SubPointsChart;
