import React from 'react';
import axios from 'axios';

class xptsTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorLoading: false,
      token: '',
      xptsData: null,
      gw: 1,
      loading: true,
      teamNames: [],
      selectedTeam: 'all',
      league_size: 8,
      // States for LiveSummaryTable
      sortDirectionSummary: 'asc',
      sortedColumnSummary: '',
      // States for TeamsOddsTable
      sortDirectionOdds: 'asc',
      sortedColumnOdds: '',
    };
  }

  componentDidMount() {
    this.xptsRequest();
  }

  xptsRequest = () => {
    this.setState({ loading: true, errorLoading: false });
    axios.post('/api/getXpts', { gw: this.state.gw, league_size: this.state.league_size })
      .then((response) => {
        this.setState({
          errorLoading: false,
          xptsData: response.data,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({ errorLoading: true, loading: false });
      });
  }

  handleGWChange = (event) => {
    this.setState({ gw: event.target.value }, this.xptsRequest);
  }

  handleTeamChange = (event) => {
    this.setState({ selectedTeam: event.target.value });
  }

  handleLeagueSizeChange = (event) => {
    this.setState({ league_size: event.target.value }, this.xptsRequest);
  }

  onColumnHeaderClickSummary = (column) => {
    const { sortDirectionSummary, sortedColumnSummary } = this.state;
    const newSortDirection = sortedColumnSummary === column && sortDirectionSummary === 'asc' ? 'desc' : 'asc';
    this.setState({ sortedColumnSummary: column, sortDirectionSummary: newSortDirection });
  }

  onColumnHeaderClickOdds = (column) => {
    const { sortDirectionOdds, sortedColumnOdds } = this.state;
    const newSortDirection = sortedColumnOdds === column && sortDirectionOdds === 'asc' ? 'desc' : 'asc';
    this.setState({ sortedColumnOdds: column, sortDirectionOdds: newSortDirection });
  }

  render() {
    const {
      xptsData,
      selectedTeam,
      gw,
      league_size,
      loading,
      errorLoading,
      sortedColumnSummary,
      sortDirectionSummary,
      sortedColumnOdds,
      sortDirectionOdds,
    } = this.state;

    if (loading && xptsData === null) return <div>Loading...</div>;
    if (errorLoading) return <div>Error loading data</div>;

    const filteredXptsData = selectedTeam === 'all'
      ? xptsData.elements_fixtures_xpts
      : xptsData.elements_fixtures_xpts.filter(item => item.team_pulse_id === parseInt(selectedTeam));

    const filteredTeamsOdds = selectedTeam === 'all'
      ? xptsData.teams_odds.filter(item => item.gw === parseInt(gw))
      : xptsData.teams_odds.filter(item => item.gw === parseInt(gw));

    const gwOptions = Array.from({ length: 38 }, (_, i) => i + 1).map(num => (
      <option key={num} value={num}>{num}</option>
    ));

    const leagueSizeOptions = Array.from({ length: 15 }, (_, i) => i + 2).map(num => (
      <option key={num} value={num}>{num}</option>
    ));

    const teamOptions = [
      <option key="all" value="all">Select All</option>,
      ...Array.from(new Set(xptsData.teams_odds.map(team => team.team_pulse_id_x)))
        .map(teamId => {
          const team = xptsData.teams_odds.find(item => item.team_pulse_id_x === teamId);
          return <option key={teamId} value={teamId}>{team.team_short_name_x}</option>;
        })
    ];

    const LiveSummaryTable = ({ data, onColumnHeaderClick }) => {
      const columns = [
        { header: 'Player', accessorKey: 'web_name', className: 'w-120' },
        { header: 'Position', accessorKey: 'type_short_name', className: 'text-center' },
        { header: 'Team', accessorKey: 'team_short_name', className: 'text-center' },
        { header: 'Opponent', accessorKey: 'opponent_short_name', className: 'text-center' },
        { header: 'H/A', accessorKey: 'h_a', className: 'text-center' },
        { header: 'Owned %', accessorKey: 'percent_picked', className: 'text-center' },
        { header: 'xFPL pts', accessorKey: 'expected_fpl_points', className: 'text-center' },
        { header: '% Win', accessorKey: 'win', className: 'text-center' },
        { header: '% Draw', accessorKey: 'draw', className: 'text-center' },
        { header: '% CS', accessorKey: 'cs', className: 'text-center' },
        { header: 'ROS xFPL Pts', accessorKey: 'ros_expected_fpl_points', className: 'text-center' },
      ];

      const formattedData = data.map(row => ({
        ...row,
        percent_picked: row.percent_picked ? `${(row.percent_picked * 100).toFixed(0)}%` : '-',
        expected_fpl_points: row.expected_fpl_points ? row.expected_fpl_points.toFixed(1) : '-',
        win: row.win ? `${row.win.toFixed(0)}%` : '-',
        draw: row.draw ? `${row.draw.toFixed(0)}%` : '-',
        cs: row.cs ? `${row.cs.toFixed(0)}%` : '-',
        ros_expected_fpl_points: row.ros_expected_fpl_points ? row.ros_expected_fpl_points.toFixed(1) : '-',
      }));

      const sortedData = [...formattedData].sort((a, b) => {
        let aValue = a[sortedColumnSummary];
        let bValue = b[sortedColumnSummary];

        if (aValue === '-' || aValue === Infinity) return 1;
        if (bValue === '-' || bValue === Infinity) return -1;

        const aValueIsNumber = !isNaN(parseFloat(aValue));
        const bValueIsNumber = !isNaN(parseFloat(bValue));

        if (aValueIsNumber && bValueIsNumber) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (!sortedColumnSummary || !sortDirectionSummary) return 0;

        if (sortDirectionSummary === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });

      return (
        <table className="h2h-table">
          <thead>
            <tr>
              {columns.map(column => (
                <th
                  className={column.className}
                  key={column.accessorKey}
                  onClick={() => onColumnHeaderClick(column.accessorKey)}
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(column => (
                  <td className={column.className} key={column.accessorKey}>{row[column.accessorKey]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    const TeamsOddsTable = ({ data, onColumnHeaderClick }) => {
      const columns = [
        { header: 'Team', accessorKey: 'team_short_name_x', className: 'text-center' },
        { header: 'Opponent', accessorKey: 'team_short_name_y', className: 'text-center' },
        { header: 'H/A', accessorKey: 'h_a', className: 'text-center' },
        { header: '% Win', accessorKey: 'win', className: 'text-center' },
        { header: '% Draw', accessorKey: 'draw', className: 'text-center' },
        { header: '% CS', accessorKey: 'cs', className: 'text-center' },
        { header: 'proj_xG', accessorKey: 'expected_goals', className: 'text-center' },
        { header: 'proj_xGC', accessorKey: 'expected_goals_conceded', className: 'text-center' },
      ];

      const formattedData = data.map(row => ({
        ...row,
        win: row.win ? `${row.win.toFixed(0)}%` : '-',
        draw: row.draw ? `${row.draw.toFixed(0)}%` : '-',
        cs: row.cs ? `${row.cs.toFixed(0)}%` : '-',
        expected_goals: row.expected_goals ? row.expected_goals.toFixed(1) : '-',
        expected_goals_conceded: row.expected_goals_conceded ? row.expected_goals_conceded.toFixed(1) : '-',
      }));

      const sortedData = [...formattedData].sort((a, b) => {
        let aValue = a[sortedColumnOdds];
        let bValue = b[sortedColumnOdds];

        if (aValue === '-' || aValue === Infinity) return 1;
        if (bValue === '-' || bValue === Infinity) return -1;

        const aValueIsNumber = !isNaN(parseFloat(aValue));
        const bValueIsNumber = !isNaN(parseFloat(bValue));

        if (aValueIsNumber && bValueIsNumber) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (!sortedColumnOdds || !sortDirectionOdds) return 0;

        if (sortDirectionOdds === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });

      return (
        <table className="h2h-table">
          <thead>
            <tr>
              {columns.map(column => (
                <th
                  className={column.className}
                  key={column.accessorKey}
                  onClick={() => onColumnHeaderClick(column.accessorKey)}
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(column => (
                  <td className={column.className} key={column.accessorKey}>{row[column.accessorKey]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <div>
        <div className="mb-20 flex g-10">
          <label className="block">
            Gameweek:
            <select className="ml-5" value={gw} onChange={this.handleGWChange}>
              {gwOptions}
            </select>
          </label>
          <label className="block">
            League Size:
            <select className="ml-5" value={league_size} onChange={this.handleLeagueSizeChange}>
              {leagueSizeOptions}
            </select>
          </label>
          <label className="block">
            Team:
            <select className="ml-5" value={selectedTeam} onChange={this.handleTeamChange}>
              {teamOptions}
            </select>
          </label>
        </div>

        <div className={`table-wrapper vh-60 mb-20 ${loading && xptsData ? 'blur' : ''}`}>
          <LiveSummaryTable
            data={filteredXptsData}
            onColumnHeaderClick={this.onColumnHeaderClickSummary}
          />
        </div>

        <div className={`table-wrapper vh-60 ${loading && xptsData ? 'blur' : ''}`}>
          <TeamsOddsTable
            data={filteredTeamsOdds}
            onColumnHeaderClick={this.onColumnHeaderClickOdds}
          />
        </div>
      </div>
    );
  }
}

export default xptsTest;
