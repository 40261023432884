import React from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

class downloadStat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorLoading: false,
      leagueDownloads: null,
      leagueDates: null
    };
  }

  componentDidMount() {
    this.downloadStatRequest();
  }

  downloadStatRequest() {
    axios.post('/api/getDownloadStat', {})
      .then((response) => {
        this.setState({
          errorLoading: false,
          leagueDownloads: response.data.leagueDownloads,
          leagueDates: response.data.leagueDates,
          dfc_averages: response.data.dfc_averages
        });
      })
      .catch((error) => {
        this.setState({errorLoading: true});
      });
  }
  

  render() {

    const {errorLoading, leagueDownloads, leagueDates, dfc_averages} = this.state;

    if(!leagueDownloads){
      return <div><h2>Loading...</h2></div>
    }

    if (errorLoading & !leagueDownloads) {
      return <div><p>Error. Refresh Page</p></div>;
    }
   
    return (
      <div>
        <h3>DFC User Averages</h3>
        <div className="table-wrapper max-vh-40 mb-20">
        <table>
      <thead>
        <tr>
          <th>League Size</th>
          <th>Global Avg Pts</th>
          <th>DFC Avg Pts</th>
          <th>DFC Pts Uplift</th>
          <th>Global Avg Rank</th>
          <th>DFC Avg Rank</th>
          <th>DFC Teams Count</th>
          <th>DFC 1st Count</th>
          <th>DFC 1st Index</th>
        </tr>
      </thead>
      <tbody>
        {dfc_averages.map((item, index) => (
          <tr key={index}>
            <td>{parseInt(item.league_size)}</td>
            <td>{parseFloat(item.all_avg).toFixed(1)}</td>
            <td>{parseFloat(item.dfc_avg).toFixed(1)}</td>
            <td>{((parseFloat(item.dfc_avg).toFixed(1)/parseFloat(item.all_avg).toFixed(1))*100).toFixed(0)}%</td>
            <td>{parseFloat(item.rank_avg_all).toFixed(1)}</td>
            <td>{parseFloat(item.rank_avg_node).toFixed(1)}</td>
            <td>{parseInt(item.dfc_count)}</td>
            <td>{parseInt(item.dfc_first_count)}</td>
            <td>{parseInt(item.dfc_first_index)}</td>
          </tr>
        ))}
      </tbody>
    </table>
        </div>
        <h3>Draft Data Download Log</h3>
      <div className="table-wrapper max-vh-40 mb-20">
        <table>
          <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Leagues</th>
              <th>#Drafted</th>
              <th>Leagues Success</th>
              <th>Leagues Error</th>
              <th>Drafted Success</th>
              <th>Drafted Error</th>
              <th>Middle Complete</th>
            </tr>
          </thead>
          <tbody>
            {leagueDownloads.map((download, index) => {
              let startDate = new Date(download.start_date).toLocaleString();
              let endDate = new Date(download.end_date).toLocaleString();
    
              return (
                <tr key={index}>
                  <td>{startDate}</td>
                  <td>{endDate}</td>
                  <td>{download.leagues_count}</td>
                  <td>{download.drafted_count}</td>
                  <td>{download.leagues_complete}</td>
                  <td>{download.leagues_error}</td>
                  <td>{download.drafted_complete}</td>
                  <td>{download.drafted_error}</td>
                  <td>{download.middle_complete}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
        <div className="vh-50 content-wrapper" >
          <h3>Leagues Per Draft Date</h3>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={leagueDates}
                margin={{ top: 0, right: 0, left: 5, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" label={{ value: 'Day', position: 'insideBottom', dy: 15 }} />
                  <YAxis label={{ value: 'Count Leagues', position: 'insideLeft', dx: 0, angle: -90 }} />
                  <Tooltip />
                  <Bar dataKey="count" radius={[4, 4, 0, 0]}>
                    
                  </Bar>

                </BarChart>
              </ResponsiveContainer>

            </div>
      </div>
    );
    
  }
}

export default downloadStat;