import arsenal from './arsenal.png'
import aston_villa from './aston_villa.png'
import bournemouth from './bournemouth.png'
import brentford from './brentford.png'
import brighton from './brighton.png'
import chelsea from './chelsea.png'
import crystal_palace from './crystal_palace.png'
import everton from './everton.png'
import fulham from './fulham.png'
import leicester from './leicester.png'
import leeds from './leeds.png'
import liverpool from './liverpool.png'
import man_city from './man_city.png'
import man_utd from './man_utd.png'
import newcastle from './newcastle.png'
import notts_forest from './notts_forest.png'
import southampton from './southampton.png'
import spurs from './spurs.png'
import west_ham from './west_ham.png'
import wolves from './wolves.png'
import luton from './luton.png'
import burnley from './burnley.png'
import sheffield_united from './sheffield_united.png'


const teamImages_2023 = [
  { team_id: 1, src: 'https://draftfc.co.uk/wp-content/uploads/arsenal.png' },
  { team_id: 2, src: 'https://draftfc.co.uk/wp-content/uploads/aston_villa.png' },
  { team_id: 3, src: 'https://draftfc.co.uk/wp-content/uploads/bournemouth.png' },
  { team_id: 4, src: 'https://draftfc.co.uk/wp-content/uploads/brentford.png' },
  { team_id: 5, src: 'https://draftfc.co.uk/wp-content/uploads/brighton.png' },
  { team_id: 10, src: 'https://draftfc.co.uk/wp-content/uploads/burnley.png' },
  { team_id: 6, src: 'https://draftfc.co.uk/wp-content/uploads/chelsea.png' },
  { team_id: 7, src: 'https://draftfc.co.uk/wp-content/uploads/crystal_palace.png' },
  { team_id: 8, src: 'https://draftfc.co.uk/wp-content/uploads/everton.png' },
  { team_id: 9, src: 'https://draftfc.co.uk/wp-content/uploads/fulham.png' },
  { team_id: 11, src: 'https://draftfc.co.uk/wp-content/uploads/liverpool.png' },
  { team_id: 12, src: 'https://draftfc.co.uk/wp-content/uploads/luton.png' },
  { team_id: 13, src: 'https://draftfc.co.uk/wp-content/uploads/man_city.png' },
  { team_id: 14, src: 'https://draftfc.co.uk/wp-content/uploads/man_utd.png' },
  { team_id: 15, src: 'https://draftfc.co.uk/wp-content/uploads/newcastle.png' },
  { team_id: 16, src: 'https://draftfc.co.uk/wp-content/uploads/notts_forest.png' },
  { team_id: 17, src: 'https://draftfc.co.uk/wp-content/uploads/sheffield_united.png' },
  { team_id: 18, src: 'https://draftfc.co.uk/wp-content/uploads/spurs.png' },
  { team_id: 19, src: 'https://draftfc.co.uk/wp-content/uploads/west_ham.png' },
  { team_id: 20, src: 'https://draftfc.co.uk/wp-content/uploads/wolves.png' },
];

export default teamImages_2023;

  