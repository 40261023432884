import React from 'react';
import axios from 'axios';

class InputDraftRankDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: null,
      filteredElements: null,
      errorMessage: false,
      teamShortNames: [],
      typeShortNames: [],
      selectedTeamShortNames: new Set(),
      selectedTypeShortNames: new Set(),
      searchValue: '',
      rankDates: [],
      selectedRankDate: null,
      saveDraftStatus: null,
      currentSeason: this.props.currentSeason,
      inputRanks: {} // New state to track input values
    };
  }

  componentDidMount() {
    this.getDraftElements();
    this.getRankDates();
  }

  getDraftElements() {
    axios
      .post('/api/getDraftElements')
      .then((res) => {
        const elements = res.data.elements.map((element) => ({
          ...element,
          rank: null,
          desired_rank: null,
          risk: null, // Add risk property
        }));
        const teamShortNames = [...new Set(elements.map((element) => element.team_short_name))]
          .sort((a, b) => a.localeCompare(b));
        const customOrder = ['GKP', 'DEF', 'MID', 'FWD'];
        const typeShortNames = [...new Set(elements.map((element) => element.type_short_name))]
          .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));
        this.setState({ elements, teamShortNames, typeShortNames, errorMessage: false }, this.applyFilters);
      })
      .catch((error) => this.setState({ errorMessage: true }));
  }

  setDFCRanking() {
    this.setState({saveDraftStatus: 'saving...'})
    console.log(this.state.elements)
    axios
      .post('/api/insertDFCRank', {
        dfc_rank: this.state.elements,
      })
      .then((response) => {
        this.setState({saveDraftStatus: response.data.Status})
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  loadDFCRanking() {
    axios
      .post('/api/loadDFCRank', {
        date: this.state.selectedRankDate,
      })
      .then((response) => {
        this.setLoadedRanking(response.data)
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  getRankDates() {
    axios
      .post('/api/getRankDates')
      .then((response) => {
        this.setState({rankDates: response.data.dates});
        this.setState({selectedRankDate: response.data.dates[0]});

      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
  }

  setLoadedRanking = (data) => {
    const updatedElements = this.state.elements.map((element) => {
      const matchingElement = data.loadedElements.find(
        (loadedElement) => loadedElement.el_id === element.el_id
      );
  
      if (matchingElement) {
        return { 
          ...element, 
          rank: matchingElement.dfc_draft_rank,
          risk: matchingElement.risk
        };
      } else {
        return element;
      }
    });
  
    this.setState({ elements: updatedElements }, this.applyFilters);
  };
  

  applyFilters = () => {
    const { elements, selectedTeamShortNames, selectedTypeShortNames, searchValue } = this.state;

    let filteredElements = [...elements];

    if (selectedTeamShortNames.size > 0) {
      filteredElements = filteredElements.filter((element) => selectedTeamShortNames.has(element.team_short_name));
    }

    if (selectedTypeShortNames.size > 0) {
      filteredElements = filteredElements.filter((element) => selectedTypeShortNames.has(element.type_short_name));
    }

    if (searchValue) {
      filteredElements = filteredElements.filter((element) =>
        element.web_name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    this.setState({ filteredElements });

  };

  handleFilterChange = (filterName, value) => {
    if (filterName === 'searchValue') {
      this.setState({ searchValue: value }, this.applyFilters);
    } else {
      this.setState((prevState) => {
        const selectedValues = new Set(prevState[filterName]);
        if (selectedValues.has(value)) {
          selectedValues.delete(value);
        } else {
          selectedValues.add(value);
        }
        return { [filterName]: selectedValues };
      }, this.applyFilters);
    }
  };

  handleRankChange = (focusWebName, desired_rank) => {
    const { elements } = this.state;
    const rankedElements = elements.filter((element) => element.rank !== null);
    const highestRank = rankedElements.length > 0 ? Math.max(...rankedElements.map((element) => element.rank)) : 1;

    if (desired_rank === null) {
      desired_rank = highestRank + 1;
    }

    if (desired_rank < 1) {
      desired_rank = 1;
    }

    const updatedElements = elements.map((element) => {
      if (element.el_id === focusWebName) {
        element.desired_rank = desired_rank;
        element.rank = null;
      }
      return element;
    });

    this.updateRanks(updatedElements);
  };

  handleTextInputRankChange = (el_id, desired_rank) => {
    const { elements } = this.state;
  
    // Parse the desired rank
    const newRank = desired_rank === null ? null : parseInt(desired_rank, 10);
  
    // Update the element's rank
    let updatedElements = elements.map((element) => {
      if (element.el_id === el_id) {
        element.rank = newRank;
        element.desired_rank = null;
      }
      return element;
    });
  
    // If newRank is null, we need to clear the rank and fill gaps
    if (newRank === null) {
      this.setState({ elements: updatedElements }, () => this.updateRanks(updatedElements));
      return;
    }
  
    // Sort elements by rank, skipping those with null rank
    let rankedElements = updatedElements.filter((element) => element.rank !== null).sort((a, b) => a.rank - b.rank);
  
    // Resolve duplicates by incrementing subsequent ranks
    let previousRank = null;
    for (let i = 0; i < rankedElements.length; i++) {
      if (previousRank !== null && rankedElements[i].rank === previousRank) {
        rankedElements[i].rank = previousRank + 1;
      }
      previousRank = rankedElements[i].rank;
    }
  
    // Ensure no gaps in the ranking sequence
    for (let i = 0; i < rankedElements.length; i++) {
      rankedElements[i].rank = i + 1;
    }
  
    // Update the updatedElements array with the corrected ranks from rankedElements
    updatedElements = updatedElements.map((element) => {
      const rankedElement = rankedElements.find((e) => e.el_id === element.el_id);
      return rankedElement ? rankedElement : element;
    });
  
    // Update the state with the corrected ranks
    this.setState({ elements: updatedElements });
  };
  
  
  

  handleInputChange = (el_id, value) => {
    this.setState((prevState) => ({
      inputRanks: {
        ...prevState.inputRanks,
        [el_id]: value
      }
    }));
  };

  updateRanks = (elements) => {
    // Step 3: Fill gaps in ranking
    const rankedElements = elements
      .filter((element) => element.rank !== null)
      .sort((a, b) => a.rank - b.rank);
  
    let rank = 1;
    for (const element of rankedElements) {
      if (element.rank !== rank) {
        element.rank = rank;
      }
      rank++;
    }
  
    // Step 4: Resolve clashes
    const focusWebName = elements.find(
      (element) => element.desired_rank != null
    );
    if (focusWebName) {
      const clashElement = rankedElements.find(
        (element) => element.rank === focusWebName.desired_rank
      );
      if (clashElement) {
        rankedElements.forEach((element) => {
          if (element.rank >= focusWebName.desired_rank) {
            element.rank++;
          }
        });
      }
  
      // Step 5: Set the new rank for focusWebName
      focusWebName.rank = focusWebName.desired_rank;
      focusWebName.desired_rank = null;
    }
  
    this.setState({ elements });
  };
  

  handleDateChange(e) {
    this.setState({ selectedRankDate: e.target.value });
  }

  handleRiskChange = (el_id, risk) => {
    const updatedElements = this.state.elements.map((element) => {
      if (element.el_id === el_id) {
        element.risk = element.risk === risk ? null : risk; // Toggle the risk value
      }
      return element;
    });
    this.setState({ elements: updatedElements }, this.applyFilters);
  };
  

  render() {
    const { filteredElements, teamShortNames, typeShortNames, selectedTeamShortNames, selectedTypeShortNames, rankDates, selectedRankDate, saveDraftStatus, currentSeason, inputRanks } = this.state;
  
    const sortedElements = filteredElements
      ? filteredElements
          .slice()
          .sort((a, b) => {
            if (a.rank === null && b.rank === null) {
              return 0;
            }
            if (a.rank === null) {
              return 1;
            }
            if (b.rank === null) {
              return -1;
            }
            return a.rank - b.rank;
          })
      : [];
  
    return (
      <div className="flex column align-center">
        <div className="flex g-5 mb-20">
      <select
          value={this.state.selectedRankDate}
          onChange={(e) => this.handleDateChange(e)}>
              <option value="" disabled>
                Select a date
              </option>
              {this.state.rankDates.map((dateStr, index) => {
                const date = new Date(dateStr);
                const displayDate = date.toDateString();
                return (
                  <option key={index} value={dateStr}>
                    {displayDate}
                  </option>
                );
              })}
            </select>
            {selectedRankDate ?
            <button onClick={() => this.loadDFCRanking()}>Load Previous Rank</button> :
            null}
          </div>
      <div className="flex flex-wrap g-10 flex-center mb-20">
        <div>
        <div className="content-wrapper middleBlock mb-10">
            <input
              className="fullwidth"
              type="text"
              placeholder="Search player"
              onChange={(e) => this.handleFilterChange('searchValue', e.target.value)}
            />
          </div>
        <div className="mitch-draft-rank content-wrapper vh-60">
          {sortedElements.map((element, index) => (
            <div className="flex mb-10 space-between align-center" key={index}>
              <div className="flex g-5 align-center">
              <input className="no-spinners text-center"
                type="number"
                min="1"
                value={inputRanks[element.el_id] !== undefined ? inputRanks[element.el_id] : element.rank || ''}
                onChange={(e) => this.handleInputChange(element.el_id, parseInt(e.target.value))}
                onBlur={(e) =>
                  this.handleTextInputRankChange(
                    element.el_id,
                    e.target.value === '' ? null : parseInt(e.target.value)
                  )
                }
              />
              <span className="position-entry">{element.team_short_name}</span>
              <span className={element.type_short_name}>{element.type_short_name}</span>
              <span>{element.web_name}</span>
              </div>
              <div className="flex g-5 align-center risk-buttons">
              <button 
                className={element.risk === 'Low' ? 'position-entry' : 'position-entry unselected'} 
                onClick={() => this.handleRiskChange(element.el_id, 'Low')}
              >
                Low
              </button>
              <button 
                className={element.risk === 'Mid' ? 'position-entry' : 'position-entry unselected'} 
                onClick={() => this.handleRiskChange(element.el_id, 'Mid')}
              >
                Mid
              </button>
              <button 
                className={element.risk === 'High' ? 'position-entry' : 'position-entry unselected'} 
                onClick={() => this.handleRiskChange(element.el_id, 'High')}
              >
                High
              </button>
              {element.rank > 0 ?
              <>
              <button onClick={() => this.handleRankChange(element.el_id, element.rank - 1)}>↑</button>
              <button onClick={() => this.handleRankChange(element.el_id, element.rank + 1)}>↓</button>
              </> :
              <button onClick={() => this.handleRankChange(element.el_id, null)}>+</button>}
              </div>
            </div>
          ))}
        </div>
        </div>
        <div className="flex g-10">
          <div className="content-wrapper">
            <h4 className="mb-10">Team</h4>
            {teamShortNames.map((teamShortName, index) => (
              <div key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedTeamShortNames.has(teamShortName)}
                  onChange={() => this.handleFilterChange('selectedTeamShortNames', teamShortName)}
                />
                {teamShortName}
              </label>
              </div>
            ))}
          </div>
          <div className="content-wrapper">
            <h4 className="mb-10">Position</h4>
            {typeShortNames.map((typeShortName, index) => (
              <div key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedTypeShortNames.has(typeShortName)}
                  onChange={() => this.handleFilterChange('selectedTypeShortNames', typeShortName)}
                />
                {typeShortName}
              </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
      <button onClick={() => this.setDFCRanking()}>Submit DFC Rank</button>
      <p>{saveDraftStatus}</p>
    </div>
    </div>
    );
  }
}

export default InputDraftRankDash;
