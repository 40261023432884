import DraftRankingLayout from "./draftRankingLayout";
import KeeperPairingsLayout from "./keeperPairingsLayout";


export const DraftRoomConfiguration = [
	{
		name: 'Draft Rankings',
		component: DraftRankingLayout,
	},
	{
		name: 'Keeper Pairings',
		component: KeeperPairingsLayout,
	}

];