import React from 'react';



class HistoryPlaceholder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {

    return (
      <div>
        <h3>Build in progress</h3>
        <p>Your league data has been saved, and we're working to add more ways to view your league history before the data is wiped from FPL for next season.</p>
      </div>
    );
  }
}

export default HistoryPlaceholder;
