import arsenal from './arsenal.png'
import aston_villa from './aston_villa.png'
import bournemouth from './bournemouth.png'
import brentford from './brentford.png'
import brighton from './brighton.png'
import chelsea from './chelsea.png'
import crystal_palace from './crystal_palace.png'
import everton from './everton.png'
import fulham from './fulham.png'
import leicester from './leicester.png'
import leeds from './leeds.png'
import liverpool from './liverpool.png'
import man_city from './man_city.png'
import man_utd from './man_utd.png'
import newcastle from './newcastle.png'
import notts_forest from './notts_forest.png'
import southampton from './southampton.png'
import spurs from './spurs.png'
import west_ham from './west_ham.png'
import wolves from './wolves.png'

const teamImages_2022 = [
    { team_id: 1, src: arsenal },
    { team_id: 2, src: aston_villa },
    { team_id: 3, src: bournemouth  },
    { team_id: 4, src: brentford  },
    { team_id: 5, src: brighton },
    { team_id: 6, src: chelsea  },
    { team_id: 7, src: crystal_palace  },
    { team_id: 8, src: everton  },
    { team_id: 9, src: fulham },
    { team_id: 10, src: leicester  },
    { team_id: 11, src: leeds },
    { team_id: 12, src: liverpool },
    { team_id: 13, src: man_city  },
    { team_id: 14, src: man_utd },
    { team_id: 15, src: newcastle  },
    { team_id: 16, src: notts_forest },
    { team_id: 17, src: southampton },
    { team_id: 18, src: spurs },
    { team_id: 19, src: west_ham},
    { team_id: 20, src: wolves },
  ];
  
  export default teamImages_2022;
  