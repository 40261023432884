import React from 'react';
import axios from 'axios';

class KeeperPairingsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: false,
      fdr: [],
      viewToggle: 'average', // Use 'average' as default view
      placeholder: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    this.setState({loading: true})

    axios
      .post('/api/getKeeperPairings')
      .then((res) => {
        this.setState({ fdr: res.data, errorMessage: false, loading: false, placeholder: false });
      })
      .catch((error) => this.setState({ errorMessage: true, loading: false }));
  }
  
  getCellDataByTeams = (team, teamPair) => {
    const match = this.state.fdr.find(item => item.team_short_name === team && item.team_short_name_pair === teamPair);
    const scenario = this.state.viewToggle + '_scenario';
    return match ? match[scenario] : null;
  }

  getCellClassName = (value) => {
    const { fdr, viewToggle } = this.state;
    const scenario = viewToggle + '_scenario';
    const values = fdr.map(item => item[scenario]);
    const min = Math.min(...values)-1;
    const max = Math.max(...values);
    const numClasses = 8;
    const intervalSize = (max - min) / numClasses;

    let intervalIndex = Math.ceil((value - min) / intervalSize);
    intervalIndex = Math.min(intervalIndex, numClasses);

    return `fdr-${intervalIndex}`;
  }

  render() {
    const { fdr, loading, errorMessage, placeholder, viewToggle } = this.state;

    if (placeholder) {
      return (
        <div className="p-20">
          <h2>FDR will be ready soon.</h2>
          <p>We have made major upgrades to our prediction model, and are finalising this before launching our new and improved FDR on Monday 29th July.</p>
        </div>
      )
    }

    if (loading) {
      return (
        <div className="p-20">
          <h2>Keeper Pairings</h2> 
          <div className="skeleton-container">
            <div className="skeleton-header"></div>
            <div className="skeleton-paragraph"></div>
            <div className="skeleton-block"></div>
          </div>
        </div>
      )
    }

    if (errorMessage) {
      return (<div><h3>There was an error loading the data. Please refresh the page.</h3></div>)
    }

    const distinctTeams = Array.from(new Set(fdr.map(item => item.team_short_name))).sort();
    const distinctTeamPairs = Array.from(new Set(fdr.map(item => item.team_short_name_pair))).sort();

    const scenarioText = {
      average: 'Average season projected points for the keeper pairing',
      best: 'Season projected points if you select the best keeper from the pairing each week',
      worst: 'Season projected points if you select the worst keeper from the pairing each week'
    };

    return (
      <div>
        <div className="menuWrapper">
          <button onClick={() => this.setState({ viewToggle: 'average' })}>Average Scenario</button>
          <button onClick={() => this.setState({ viewToggle: 'best' })}>Best Scenario</button>
          <button onClick={() => this.setState({ viewToggle: 'worst' })}>Worst Scenario</button>
        </div>
        
        <div className="p-20">
          <div>
            <p>{scenarioText[viewToggle]}</p>
          </div>
          <div className="table-wrapper vh-70">
            <table>
              <thead>
                <tr>
                  <th>Team Pair</th>
                  {distinctTeams.map(team => (
                    <th className="text-center" key={team}>{team}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {distinctTeamPairs.map(teamPair => (
                  <tr key={teamPair} className="fdr-row">
                    <td>{teamPair}</td>
                    {distinctTeams.map(team => {
                      const value = this.getCellDataByTeams(team, teamPair);
                      return (
                        <td key={team} className={`fdr-cell ${value !== null ? this.getCellClassName(value) : ''}`}>
                          <div className="flex flex-center">
                            {value !== null ? (
                              <div className="semibold text-center flex w-50">
                                <div className="cell-block fullwidth">
                                  <span>{value.toFixed(0)}</span>
                                </div>
                              </div>
                            ) : '-'}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default KeeperPairingsLayout;
