import React from 'react';
import axios from 'axios';
import teamImages from '../../images/shirts/shirtsLookup.js';
import Pitch from '../../images/pitch.png';
import { Tungsten } from '@mui/icons-material';


class LineupsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: null,
      errorMessage: false,
      teamShortNames: [],
      typeShortNames: [],
      selectedTeamShortNames: new Set(),
      selectedTypeShortNames: new Set(),
      searchValue: '',
      selectedLineupGW: (this.props.gw + 1).toString(),
      realElementTypes: ['GKP', 'DEF', 'MID', 'FWD'],
      savedLineupGWs: null,
      selectedLoadLineupGW: null,
      leagueID: this.props.leagueID,
      isSpecialLeague: null,
      lineupDate: null,
      selectedLeagueSize: 8,
      placeholder: false
    };
  }

  componentDidMount() {
    if (this.state.placeholder != true) {
    this.getLineupsElements(this.state.selectedLineupGW, this.state.leagueID);}
    //this.getRankDates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
        this.setState({
            elements: '', // This might need to be changed based on your intention.
            leagueID: this.props.leagueID
        }, () => {
            this.setSpecialLeague(this.props.leagueID);
            this.getLineupsElements(this.state.selectedLineupGW, this.state.leagueID);
        });
    }
}

  setSpecialLeague() {
    const isSpecialLeague = ['9999996', '9999997', '9999998'].includes(this.state.leagueID);
    this.setState({ isSpecialLeague });
  }

  getLineupsElements(gw, leagueID) {
  if (this.state.isSpecialLeague == true) {
    axios
      .post('/api/getLineupElements', { gw: gw, leagueID: leagueID, league_size: this.state.selectedLeagueSize })
      .then((res) => {
        const elements = res.data.elements.map((element) => ({
          ...element,
          position: null,
          desired_position_rank: null,
          realElementType: element.type_short_name,
          position_rank: null,
        }));
        this.setState({ elements, errorMessage: false }, () => {
          // This callback will be executed after the state is updated
          this.loadPredictedLineups(this.state.selectedLineupGW);
          this.getSavedLineupDate();
        });
      })
      .catch((error) => this.setState({ errorMessage: true }));}
      else {
        axios
      .post('/api/getLineupElements', { gw: gw, leagueID: leagueID })
      .then((res) => {
        const elements = res.data.elements.map((element) => ({
          ...element,
          position: null,
          realElementType: element.type_short_name,
          position_rank: null,
        }));
        this.setState({ elements, errorMessage: false }, () => {
          // This callback will be executed after the state is updated
          this.loadPredictedLineups(this.state.selectedLineupGW);
          this.loadPersonalElements();
          this.getSavedLineupDate();
        });
      })
      .catch((error) => this.setState({ errorMessage: true }));
      }
    }


  loadPredictedLineups(gw) {
    axios
      .post('/api/loadPredictedLineups', {
        gw: gw,
      })
      .then((response) => {
        this.setLoadedLineups(response.data.loadedLineups)
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  loadPersonalElements() {
    axios
      .post('/api/loadPersonalLineupElements', {
        leagueID: this.state.leagueID,
      })
      .then((response) => {
        this.setPersonalisedElements(response.data.elements)
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  getSavedLineupDate() {
    axios
      .post('/api/getSavedLineupDate', {gw: this.state.selectedLineupGW})
      .then((response) => {
        this.setState({ lineupDate: response.data.date});

      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
  }

  handleLeagueSizeChange = (event) => {
    this.setState({
      selectedLeagueSize: event.target.value,
      elements: null
    }, () => {
      this.getLineupsElements(this.state.selectedLineupGW,  this.state.leagueID)
    });
  }

  selectLoadLeagueSize = (gw) => {
    this.setState({ selectedLoadLineupGW: String(gw) })
  }

  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
  }

  createNumbersArray() {
    let numbers = [];
    for (let i = 2; i <= 16; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  setLoadedLineups = (data) => {
    const updatedElements = this.state.elements.map((element) => {
      const matchingElement = data.find(
        (loadedElement) => loadedElement.el_id === element.el_id
      );

      if (matchingElement) {
        return { ...element, position: matchingElement.position, realElementType: matchingElement.realElementType, position_rank: matchingElement.position_rank };
      } else {
        return element;
      }
    });

    this.setState({ elements: updatedElements });
  };

  setPersonalisedElements = (data) => {
    const updatedElements = this.state.elements.map((element) => {
      const matchingElement = data.find(
        (loadedElement) => loadedElement.el_id === element.el_id
      );

      if (matchingElement) {
        return { ...element, short_name: matchingElement.short_name, status: matchingElement.status};
      } else {
        return element;
      }
    });

    this.setState({ elements: updatedElements });
  };


  render() {
    const { elements, selectedLineupGW, lineupDate, isSpecialLeague, placeholder } = this.state;
    const numbersArray = this.createNumbersArray();

    if (placeholder) {
      return (<div>
        <h2>Predicted Lineups are coming soon</h2>
        <p>We're quite far away from the season start, with a lot of games to play in preseason which will impact our predicted lineups.</p>
        <p>Stay tuned for the release of predicted lineups closer to GW1</p>
        </div>)
    }

    const sortedElements = elements
      ? elements
        .slice()
        .sort((a, b) => {
          return a.team - b.team;
        })
      : [];


    return (
      <div>
      {lineupDate ?
      <div>
          <div className="align-center mb-20 g-5">
            <h2 className="mb-10">{'Gameweek ' + selectedLineupGW + ' predicted lineups' }</h2>
            {lineupDate == 'Not submitted' ?
            <p>{'Lineups for GW ' + selectedLineupGW + ' have not been submitted yet.'}</p> :
            <p>{'Last updated on ' + new Date(lineupDate).toLocaleDateString()}</p>}
            <p className="italic">All fixture outcome odds and player xPts are modelled based on historical data and other relevant datapoints, 
            and should ONLY be used to help you make Draft FPL decisions. You must NOT rely on this data for any betting activities!</p>
        {isSpecialLeague ?
          <div>
            <select value={this.state.selectedLeagueSize} onChange={this.handleLeagueSizeChange}>
              <option value="">Select a league size</option>
              {numbersArray.map((number, index) => (
                <option key={index} value={number}>{number}</option>
              ))}
            </select> </div> : null }
            </div>
            
          
          
            <div className="flex flex-wrap g-20 align-center space-evenly">
  {sortedElements.filter((item, index, self) => 
    index === self.findIndex((t) => t.team === item.team && t.id === item.id)
  ).map((element, i) => {
      const teamId = element.team;
      const fixture = element.id;
      const teamElements = sortedElements.filter(el => el.team === teamId && el.id == fixture);
      const selectedTeamElements = teamElements.filter(el => el.position === 1)
      const teamName = teamElements[0]?.team_name;
      const opponentName = teamElements[0]?.opponent_name; // new
      const ha = teamElements[0]?.h_a; // new
      const win = teamElements[0]?.win;
      const draw = teamElements[0]?.draw;
      const cs = teamElements[0]?.cs;

      return (
        <div key={i} className="flex flex-wrap g-10 flex-center mb-20">
          {opponentName && ( // This checks if opponentName is not null
            <div className="live-gw_details text-center flex g-20 flex-wrap center-content space-around">
              <div key={teamId} className="entry-card flex space-between column" style={{ backgroundImage: `url(${Pitch})`, backgroundSize: 'cover' }}>
                <div className="live-header g-10 mt-10">
                  <div className="text-left white">
                    <h4 className="mb-0 white">{teamName}</h4>
                    <p className="mb-0 italic white">vs {opponentName} ({ha})</p>
                  </div>
                  <div className="text-left white team-odds-holder">
                    <p className="mb-0 white team-odds">{'Win: ' + (win).toFixed(0) + '%'}</p>
                    <p className="mb-0 white team-odds">{'Draw: ' + (draw).toFixed(0) + '%'}</p>
                    <p className="mb-0 white team-odds">{'CS: ' + (cs).toFixed(0) + '%'}</p>
                  </div>
                </div>
                <div className="start-players">
                  {["GKP", "DEF", "MID", "FWD"].map((type, index) => (
                    <div key={index} className="player-row flex space-evenly mb-20 mt-10">
                      {selectedTeamElements
                        .filter((el) => el.realElementType === type)
                        .sort((a, b) => a.position_rank - b.position_rank)
                        .map((el, idx, arr) => (
                          <div key={el.el_id} className="player-single flex-grow-1">
                            <div>{this.getTeamImage(el.team)}</div>
                            <div className="player_element_name text-overflow">{el.web_name}</div>
                            <div className="player_element_middle">{el.type_short_name}</div>
                            <div className="player_element_bottom">{(el.rostered * 100).toFixed(0) + '%'} | {el.gw1_xFPL_pts ? el.gw1_xFPL_pts.toFixed(1): '-'}</div>
                            {el.status ?
                            (el.status == 'a' ? <div className="player_element_available">Available</div> : <div className="player_element_owned">{el.short_name}</div>) : null}
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    })}
</div>
</div> : 
        <div>
        <h2>Predicted Lineups</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>
        
        
        }</div>
    );

  }
}

export default LineupsLayout;
