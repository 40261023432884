import React from 'react';
import axios from 'axios';
import teamImages from '../../images/shirts/shirtsLookup.js';
import Shield from '../../images/logo_shield.png';
import Pitch from '../../images/pitch.png';

class InputPredictedLineups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: null,
      errorMessage: false,
      teamShortNames: [],
      typeShortNames: [],
      selectedTeamShortNames: new Set(),
      selectedTypeShortNames: new Set(),
      searchValue: '',
      lineupGWs: null,
      selectedLineupGW: (this.props.gw + 1).toString(),
      saveDraftStatus: null,
      realElementTypes: ['GKP', 'DEF', 'MID', 'FWD'],
      savedLineupGWs: null,
      selectedLoadLineupGW: null,
      postedBlog: null
    };
  }

  componentDidMount() {
    this.getGameweeks();
    this.getLineupsElements(this.state.selectedLineupGW);
    this.getSavedLineupDates()
    //this.getRankDates();
  }

  getGameweeks() {
    axios
      .post('/api/getGameweeks')
      .then((res) => {
        this.setState({ lineupGWs: res.data.gameweeks });
      })
      .catch((error) => this.setState({ errorMessage: true }));
  }

  getLineupsElements(gw) {
    axios
      .post('/api/getLineupElements', { gw: gw })
      .then((res) => {
        const elements = res.data.elements.map((element) => ({
          ...element,
          position: null,
          desired_position_rank: null,
          realElementType: element.type_short_name,
          position_rank: null,

        }));
        this.setState({ elements, errorMessage: false });
      })
      .catch((error) => this.setState({ errorMessage: true }));
  }

  setPredictedLineups() {
    this.setState({ saveDraftStatus: 'saving...' })
    axios
      .post('/api/insertPredictedLineups', {
        dfc_rank: this.state.elements,
        gw: this.state.selectedLineupGW
      })
      .then((response) => {
        this.setState({ saveDraftStatus: response.data.Status });
        this.getSavedLineupDates();
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  loadPredictedLineups(gw) {
    axios
      .post('/api/loadPredictedLineups', {
        gw: this.state.selectedLoadLineupGW,
      })
      .then((response) => {
        this.setLoadedLineups(response.data.loadedLineups)
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  }

  getLineupsDates() {
    axios
      .post('/api/getLineupGameweeks')
      .then((response) => {
        this.setState({ lineupGWs: response.data.GWs });
        this.setState({ selectedLineupGW: response.data.GWs[0] });

      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
  }

  getSavedLineupDates() {
    axios
      .post('/api/getSavedLineupGameweeks')
      .then((response) => {
        this.setState({ savedLineupGWs: response.data.GWs, selectedLoadLineupGW: response.data.GWs[0] });

      })
      .catch((error) => {
        console.error('Error getting data:', error);
      });
  }

  selectGW = (gw) => {
    this.setState({ selectedLineupGW: String(gw) })
    this.getLineupsElements(gw)
  }

  selectLoadGW = (gw) => {
    this.setState({ selectedLoadLineupGW: String(gw) })
  }

  setLoadedLineups = (data) => {
    const updatedElements = this.state.elements.map((element) => {
      const matchingElement = data.find(
        (loadedElement) => loadedElement.el_id === element.el_id
      );

      if (matchingElement) {
        return { ...element, position: matchingElement.position, realElementType: matchingElement.realElementType, position_rank: matchingElement.position_rank };
      } else {
        return element;
      }
    });

    this.setState({ elements: updatedElements });
  };


  handleRankChange = (focusElement, desired_position) => {
    if (desired_position < 1 || isNaN(desired_position)) {
      desired_position = null;
    }

    const { elements } = this.state;

    let type;

    const updatedElements = elements.map((element) => {
      if (element.el_id === focusElement) {
        element.desired_position = desired_position;
        element.position = desired_position;
        type = element.realElementType;
        element.position_rank = null  // Set the type here
      }
      return element;
    });

    this.setState({ elements: updatedElements });

    // Make sure type has been set before calling moveRank
    if (type) {
      this.moveRank(focusElement, "up", type)
    }
  };


  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
  }


  handleDateChange(e) {
    this.setState({ selectedRankDate: e.target.value });
  }

  moveRank = (id, direction, type) => {
    // Find the selected element
    const selectedElement = this.state.elements.find(el => el.el_id === id);
    if (!selectedElement) return;  // element not found

    const team = selectedElement.team;

    // Then filter the elements based on the 'team' of the selected element
    let elementsOfType = this.state.elements.filter(
      el => el.realElementType === type && el.position === 1 && el.team === team
    );

    // Sort elementsOfType based on position_rank and assign new ranks
    elementsOfType = elementsOfType.sort((a, b) => a.position_rank - b.position_rank);
    elementsOfType.forEach((el, index) => el.position_rank = index + 1);

    // If selected element's rank is null or NaN, assign it the lowest available rank
    if (selectedElement.position_rank === null || isNaN(selectedElement.position_rank)) {
      const ranks = elementsOfType
        .filter(el => el.position_rank !== null && !isNaN(el.position_rank))
        .map(el => el.position_rank);
      let lowestRank = 1; // Set initial rank to 1
      while (ranks.includes(lowestRank)) { // If rank exists in ranks array, increment lowestRank
        lowestRank++;
      }
      selectedElement.position_rank = lowestRank;
    }

    const prevElement = elementsOfType.find(el => el.position_rank === selectedElement.position_rank - 1);
    const nextElement = elementsOfType.find(el => el.position_rank === selectedElement.position_rank + 1);

    if (direction === "down" && prevElement) {
      prevElement.position_rank++;
      selectedElement.position_rank--;
    } else if (direction === "up" && nextElement) {
      nextElement.position_rank--;
      selectedElement.position_rank++;
    }

    this.setState({ elements: this.state.elements });
  }

  handleRealElementTypeChange = (id, newType) => {
    this.setState(prevState => ({
      elements: prevState.elements.map(el => 
        el.el_id === id ? { ...el, realElementType: newType, position_rank: null } : el
      ),
    }));
    // Ensure that moveRank is called after the state has been updated
    this.setState({}, () => this.moveRank(id, "up", newType));
  }

  postAsBlog = async () => {
    // Getting the HTML content of all instances of the specified div
    const elements = document.querySelectorAll('#lineup.live-gw_details');
    let allHtmlContents = [];

    elements.forEach(element => {
        const clonedElement = element.cloneNode(true);

        // Removing all onclick attributes
        clonedElement.querySelectorAll('[onclick]').forEach(elem => {
            elem.removeAttribute('onclick');
        });

        // Removing the specific div containing the buttons
        clonedElement.querySelectorAll('.player-single-buttons').forEach(buttonsDiv => {
            buttonsDiv.remove();
        });

        allHtmlContents.push(clonedElement.outerHTML);
    });
    const htmlString = allHtmlContents.join('');

    // Sending the HTML content as a POST request
    try {
        const response = await axios.post('/api/postLineupsBlog', {
            lineups: htmlString,
            gw: this.state.selectedLineupGW,
            token: localStorage.getItem('token')
        });
    
        // Set the state with the received generated_slug
        this.setState({ postedBlog: response.data.generated_slug });
    
    } catch (error) {
        console.error("Error posting as blog:", error);
    }
  }


  render() {
    const { elements, selectedLineupGW, saveDraftStatus, lineupGWs, realElementTypes, savedLineupGWs, selectedLoadLineupGW, postedBlog } = this.state;

    const sortedElements = elements
      ? elements
        .slice()
        .sort((a, b) => {
          return a.el_id - b.el_id;
        })
      : [];


    return (
      <div className=" flex column align-center">

        {lineupGWs ?
          <div className="flex align-center mb-20 g-5">
            <p className="mb-0">Select Gameweek</p>
            <select onChange={(e) => this.selectGW(e.target.value)}>
              {this.state.lineupGWs.map((gw, index) => (
                <option key={index} value={gw}>
                  {gw}
                </option>
              ))}
            </select> 
            <button onClick={() => this.setPredictedLineups()}>Submit Lineups for GW {selectedLineupGW}</button>
                    {saveDraftStatus ? <span>{saveDraftStatus}</span> : null}
          </div> : null}
          {savedLineupGWs && savedLineupGWs.length > 0 ?
          <div className="flex align-center mb-20 g-5">
            <p className="mb-0">Get data from gameweek</p>
            <select value={selectedLoadLineupGW} onChange={(e) => this.selectLoadGW(e.target.value)}>
              {this.state.savedLineupGWs.map((gw, index) => (
                <option key={index} value={gw}>
                  {gw}
                </option>
              ))}
            </select> 
            <button onClick={() => this.loadPredictedLineups()}>Load data</button>
          </div> : null}
          <div className="mb-20">
          <button onClick={this.postAsBlog}>Post as Blog</button> 
          {postedBlog ? <span>{postedBlog} posted</span> : null}
          </div>
        <div >
          
          
        {
    sortedElements
        .sort((a, b) => {
            if (a.team < b.team) return -1;
            if (a.team > b.team) return 1;
            return 0;
        })
        .filter((item, index, self) => 
            index === self.findIndex((t) => t.team === item.team && t.id === item.id && t.id != null)
        )
        .map((element, i) => {
            const teamId = element.team;
            const fixture = element.id;
            const teamElements = sortedElements.filter(el => el.team === teamId && el.id == fixture);
            const selectedTeamElements = teamElements.filter(el => el.position === 1)
            const teamName = teamElements[0]?.team_name;
            const opponentName = teamElements[0]?.opponent_name; // new
            const ha = teamElements[0]?.h_a; // new
            const win = teamElements[0]?.win;
            const draw = teamElements[0]?.draw;
            const cs = teamElements[0]?.cs;
            const countPositionOne = teamElements.filter(el => el.position === 1).length;

            return (
              <div key={i} className="flex flex-wrap g-10 flex-center mb-20">

                <div id="lineup" className="live-gw_details text-center flex g-20 flex-wrap center-content space-around">
                  <div key={teamId} className="entry-card flex space-between column" style={{ backgroundImage: "url(https://draftfc.co.uk/wp-content/uploads/pitch.png)", backgroundSize: 'cover' }}>
                    <div className="live-header g-10 mt-10">
                      <div className="text-left white">
                        <h4 className="mb-0 white">{teamName}</h4>
                        <p className="mb-0 italic white">vs {opponentName} ({ha})</p>
                      </div>
                      <div className="text-left white team-odds-holder">
                        <p className="mb-0 white team-odds">{'Win: ' + (win).toFixed(0) + '%'}</p>
                        <p className="mb-0 white team-odds">{'Draw: ' + (draw).toFixed(0) + '%'}</p>
                        <p className="mb-0 white team-odds">{'CS: ' + (cs).toFixed(0) + '%'}</p>
                      </div>
                    </div>
                    <div className="start-players">
                      {["GKP", "DEF", "MID", "FWD"].map((type, index) => (
                        <div key={index} className="player-row flex space-evenly mb-20 mt-10">
                          {selectedTeamElements
                            .filter((el) => el.realElementType === type)
                            .sort((a, b) => a.position_rank - b.position_rank)
                            .map((el, idx, arr) => (
                              <div key={el.el_id} className="onclick player-single flex-grow-1">
                                <div className="flex g-5 player-single-buttons space-evenly">
                                  <button  onClick={() => this.moveRank(el.el_id, "up", type)}>↑</button>
                                  <button onClick={() => this.moveRank(el.el_id, "down", type)}>↓</button>
                                </div>
                                <div>{this.getTeamImage(el.team)}</div>
                                <div className="player_element_name text-overflow">{el.web_name}</div>
                                <div className="player_element_points">{el.type_short_name}</div>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mitch-draft-rank content-wrapper vh-60">
                    {teamElements
                      .sort((a, b) => {
                        // If a.position is 1 and b.position is not 1, sort a first
                        if (a.position === 1 && b.position !== 1) return -1;
                        // If b.position is 1 and a.position is not 1, sort b first
                        if (a.position !== 1 && b.position === 1) return 1;
                  
                        // If both positions are 1 or both are not 1, sort by element_type
                        return a.element_type - b.element_type;
                      })
                      .map((element, index) => (
                        <div className="flex mb-10 space-between align-center" key={index}>
                          <div className="flex g-5 align-center">
                            {element.position == 1 ? (
                            <div className="flex g-5 align-center minus">
                              <button onClick={() => this.handleRankChange(element.el_id, null)}>-</button>
                            </div>
                          ) : (
                            <div>
                              {(countPositionOne < 11) &&
                                <button onClick={() => this.handleRankChange(element.el_id, 1)}>+</button>
                              }
                            </div>
                          )}
                            <span className={element.type_short_name}>{element.type_short_name}</span>
                            <span className="rostered">{(element.rostered * 100).toFixed(1) + '%'}</span>
                            <span>{element.web_name}</span>
                            <select value={element.realElementType} onChange={(e) => this.handleRealElementTypeChange(element.el_id, e.target.value)}>
                              {realElementTypes.map((type, index) => <option key={index} value={type}>{type}</option>)}
                            </select>
                          </div>
                        </div>
                      ))}
                  </div>

                </div>

              </div>
            );
          })}
        </div>

      </div>
    );

  }
}

export default InputPredictedLineups;