import React from 'react';
import axios from 'axios';
import GWStatus from '../personalDash/gwStatus'
import TableSort from "table-sort-js/table-sort.js"
import teamImages from '../../images/shirts/shirtsLookup.js';
import Shield from '../../images/logo_shield.png';
import Pitch from '../../images/pitch.png';


class LiveGWLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      liveGWDetail: '',
      liveGWSummary: '',
      scoring: '',
      selectedPlayer: null,
      errorMessage: false,
      gw: this.props.gw,
      buttonIcon: "↻"
    }
  }

  componentDidMount() {
    if (this.state.gw != 0) {
    this.getData(this.props.leagueID)};
  }

  componentDidUpdate(prevProps) {                                           
    if (prevProps.leagueID !== this.props.leagueID) {
        this.setState({liveGWDetail: ''})
        this.updateContent(this.props.leagueID)  
        this.initializeTable();  
    }
  }

  updateContent(prop) {
    this.setState({ leagueID: prop })
    this.getData(prop)           
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  getData(prop) {
  
    if (prop) {
        // Set the button icon to '...' when the data fetching starts
        this.setState({ buttonIcon: "..." });

        axios.post('/api/getLiveGW', {leagueID: prop})
        .then((res) => {
            const detail = res.data.liveDetail;
            const summary = res.data.liveSummary;
            const scoring = res.data.scoring;
            this.setState({
                liveGWDetail : detail,
                liveSummary : summary,
                scoring : scoring,
                errorMessage: false,
                buttonIcon: "✓" // Unicode character for check mark
            }, () => {
                this.initializeTable();
                // Reset the button icon back to circle arrow after 2 seconds
                setTimeout(() => {
                    this.setState({ buttonIcon: "↻" });
                }, 2000);
            });
        })
        .catch((error) => this.setState({errorMessage: true, buttonIcon: "↻"}));
    }
}

handlePlayerClick = (entryId, elId) => {
  this.setState({
    selectedPlayer: { entryId, elId }
  });
};

  
  handleModalClose = () => {
    this.setState({
      selectedPlayer: null
    });
  };

  initializeTable() {
    if (!this.table) {
      // check if the table reference is available
      this.table = new TableSort(document.querySelector('.table-sort')); // initialize the table-sort-js plugin
    }
  }

  getPlayerFixtureDetails = (entryId, elId) => {
    const entry = this.state.liveGWDetail.find((entry) => entry.entry_id === entryId);
    const player = entry.Elements.find((el) => el.el_id === elId);
    const fixturesArr = Object.values(player.fixtures);
    const webName = player.web_name
  
    return (
      <div className="points-module">
        {fixturesArr.map((fixture, index) => (
          <div className="live-fixture" key={index}>
              {fixture.opp ? 
            <div className="live-fixture_title">
              <span className="live-fixture_name"> {webName}: {fixture.opp}  ({fixture["H/A"]})</span><span className="live-fixture_status"> {fixture.fixture_status}</span>
            </div> :
            <div className="live-fixture_title">
              <span className="live-fixture_name"> {webName}:</span><span className="live-fixture_status"> No Fixture</span>
            </div>}
            <div className="live-fixture_details">
            {fixture.minutes != 0 && <div>Minutes: {fixture.minutes}</div>}
            {fixture.clean_sheets != 0 && <div>Clean Sheets: {fixture.clean_sheets}</div>}
            {fixture.assists != 0 && <div>Assists: {fixture.assists}</div>}
            {fixture.goals_scored != 0 && <div>Goals: {fixture.goals_scored}</div>}
            {fixture.own_goals != 0 && <div>Own Goals: {fixture.own_goals}</div>}
            {fixture.penalties_missed != 0 && <div>Pens Missed: {fixture.penalties_missed}</div>}
            {fixture.penalties_saved != 0 && <div>Pens Saved: {fixture.penalties_saved}</div>}
            {fixture.yellow_cards != 0 && <div>Yellow Cards: {fixture.yellow_cards}</div>}
            {fixture.red_cards != 0 && <div>Red Cards: {fixture.red_cards}</div>}
            {fixture.saves != 0 && <div>Saves: {fixture.saves}</div>}
            {fixture.bonus != 0 && <div>Bonus: {fixture.bonus}</div>}
            {fixture.goals_conceded != 0 && <div>Goals Conceded: {fixture.goals_conceded}</div>}
            </div>
          </div>
        ))}
      </div>
    );
  }
  

  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
}

  render() {
    const { liveGWDetail, liveSummary, scoring, gw, leagueID } = this.state

    if (gw == 0) {
      return (<div>
        <h2>Live Gameweek will be available in GW1</h2>
        <p>As the season hasn't started, there is no data to display in this view.</p>
        </div>)
    }
    
    if (!liveGWDetail) {
      return (
        <div>
       <h2>Live Gameweek</h2> 
      <div className="skeleton-container">
      <div className="skeleton-header"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-block"></div>
      </div></div>
        )
    }

    if (this.state.errorMessage & !liveGWDetail) {
      return <div><p>There was en error in getting the Live GW data. Please refresh the page to try again</p></div>;
    }

    for (let i = 0; i < liveGWDetail.length; i++) {
      liveGWDetail[i].Elements.sort((a, b) => a.position - b.position);
    }
    
    return (
      <div>
        <div className="flex flex-wrap g-10 mb-30">
        <h2 className="mb-0" >Live Gameweek</h2>
        <button className="bold refresh-button" onClick={() => this.getData(leagueID)}>{this.state.buttonIcon}</button></div>
        <div className="table-wrapper mb-30">
      {scoring == 'c' ?
      <table className="table-sort h2h-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Team Name</th>
            <th>Total Points</th>
            <th>GW Points</th>
          </tr>
        </thead>
        <tbody>
                {liveSummary.map((row, index) => (
                  <tr key={index}>
                      <td className="max-content">
                        <span>{row.rank}</span>
                        {row.rank === row.past_rank ? (
                        <span className="ml-10">-</span> )
                        : row.rank < row.past_rank ? (<span className="material-symbols-outlined ml-5 rank green">arrow_upward</span>)
                        : (<span className="material-symbols-outlined ml-5 rank red">arrow_downward</span>)}
                      </td>
                    <td className="text-overflow maxw-200">{row.entry_name}</td>
                    <td>{parseInt(row.total_points)}</td>
                    <td>{parseInt(row.fixture_total_points)}</td>

              </tr>
            ))}
        </tbody>
      </table>:
      <table className="table-sort h2h-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Team Name</th>
            <th>GW FPL Points</th>
            <th>Total Points</th>
            <th>Avg. Pts For</th>
            <th>Avg. Pts Against</th>
          </tr>
        </thead>
        <tbody>
                {liveSummary.map((row, index) => (
                  <tr key={index}>
                      <td className="max-content">
                        <span>{row.rank}</span>
                        {row.rank === row.past_rank ? (
                        <span className="ml-10">-</span> )
                        : row.rank < row.past_rank ? (<span className="material-symbols-outlined ml-5 rank green">arrow_upward</span>)
                        : (<span className="material-symbols-outlined ml-5 rank red">arrow_downward</span>)}
                      </td>
                    <td className="maxw-200">
                      <span className="flex align-center g-10">
                      <span className={row.win}>{row.win}</span>
                      <span>
                        <div className="text-overflow">{row.entry_name}</div>
                        <div className="italic text-overflow">vs {row.opp_name}</div>
                      </span>
                      </span>
                    </td>
                <td>{parseInt(row.fixture_total_points)}</td>
                <td>{parseInt(row.total_h2h_pts)}</td>
                <td>{parseFloat(row.avg_points_for).toFixed(1)}</td>
                <td>{parseFloat(row.avg_opp_points).toFixed(1)}</td>

              </tr>
            ))}
        </tbody>
      </table>
        }</div>
    <div className="live-gw_details text-center flex g-20 flex-wrap center-content space-evenly">
    {liveGWDetail.map((entry) => (
      <div key={entry.entry_id} className="entry-card flex space-evenly column" style={{ backgroundImage: `url(${Pitch})`,backgroundSize: 'cover' }}>
        <div className="live-header g-10 mt-10">
          <div className="flex align-center g-10">
        <img className="shield" src={Shield} />
        <h4 className="mb-0 white text-overflow">{entry.entry_name}</h4>
        </div>
        <h4 className="mb-0 p-5 br-8 border-white white">{entry.current_total_points}</h4>
        </div>
        
        <div className="start-players">
          <div className="player-row flex space-evenly mb-10">
            {entry.Elements.filter(
              (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "GKP"
            ).map((el) => (
              <div key={el.el_id} className=" onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className="player_element_points">
                  {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                </div>
              </div>
            ))}
          </div>
          <div className="player-row flex space-evenly mb-10 g-5">
            {entry.Elements.filter(
              (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "DEF"
            ).map((el) => (
              <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className="player_element_points">
                  {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                </div>
              </div>
            ))}
          </div>
          <div className="player-row flex space-evenly mb-10  g-5">
            {entry.Elements.filter(
              (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "MID"
            ).map((el) => (
              <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className="player_element_points">
                  {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                </div>
              </div>
            ))}
          </div>
          <div className="player-row flex space-evenly mb-20  g-5">
            {entry.Elements.filter(
              (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "FWD"
            ).map((el) => (
              <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className="player_element_points">
                  {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sub-players player-row flex space-evenly g-5">
          {entry.Elements.filter((el) => el.position >= 12 && el.position <= 15).map((el) => {
            const totalPoints = Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0);
            return (
              <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className="player_element_points">
                  {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ))}
  </div>
  <div>
  {this.state.selectedPlayer && (
        <div className="live-fixture_modal">
          <div className="modal-content">
            <span className="modal-close onclick" onClick={this.handleModalClose}>
              &times;
            </span>
            {this.getPlayerFixtureDetails(this.state.selectedPlayer.entryId, this.state.selectedPlayer.elId)}
          </div>
        </div>
      )}
  </div>
  
        </div>
    );
  }
}

export default LiveGWLayout;
