import React from 'react';
import { PersonalChartConfig } from './personalChartConfiguration';
import { h2hPersonalChartConfig } from './h2hPersonalChartConfiguration';


class H2HPersonalMenu extends React.Component {

  constructor(props){
    super(props);

  }

  handleClick(component){
    this.props.onChange(component);
  }
  
  render() {

    const { selectedYear, selectedScoring } = this.state;

    const buttons = PersonalChartConfig.filter(obj => {
      if (obj.isBeyond2022 && selectedYear <= 2022) return false;
      if (obj.isClassic && selectedScoring !== 'c') return false;
      if (obj.isH2H && selectedScoring !== 'h') return false;
      return true;
    }).map(obj => {
      const Component = obj.component; // Get the component from the configuration
      return (
        <button onClick={() => this.handleClick(Component)} className="menuItem" key={obj.name}>
          {obj.name}
        </button>
      );
    });

    return (
      <div className="menuWrapper">
        {buttons}
      </div>
    );
  }
}

export default H2HPersonalMenu;
