import React from 'react';
import axios from 'axios';

class TaranButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogCopy: null,
      errorLoading: false,
      loading: false,
      podcastUrl: ''  // State to store the entered URL
    };

    // Bind methods
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUrlChange(event) {
    this.setState({ podcastUrl: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault(); // Prevent default form submission behavior
    this.transcribePodcast(this.state.podcastUrl);
  }

  runScraper() {
    this.setState({loading: true})
    axios.post('/api/getOrders')
      .then((response) => {
        this.setState({
          errorLoading: false,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({errorLoading: true, loading: false});
      });
  }

  getH2HCombinations() {
    this.setState({loading: true})
    axios.post('/api/getH2HCombinations')
      .then((response) => {
        this.setState({
          errorLoading: false,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({errorLoading: true, loading: false});
      });
  }

  render() {
    const {errorLoading, loading} = this.state;

    if(loading){
      return <div><h2>Loading...</h2></div>
    }

    if (errorLoading) {
      return <div><p>Error. Refresh Page</p></div>;
    }

    return (
      <div>
      <div>
          <button onClick={() => this.runScraper()}>Update Finance</button>
      </div>
       <div>
       <button onClick={() => this.getH2HCombinations()}>Get H2H Combinations</button>
   </div>
   </div>
    );
  }
}

export default TaranButtons;
