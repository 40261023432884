import React, { Component } from 'react';
import axios from 'axios';

class SelectLeague extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team_ids: [],
      leagues: [],
      all_ids: this.props.all_ids,
      errorMessage: false,
      leagueID: this.props.leagueID
    };
  }

  componentDidMount() {
    this.getLeagueDetails(this.props.all_ids);
  }

  componentDidUpdate(prevProps) {                                           
    if (prevProps.all_ids !== this.props.all_ids) {
        this.updateContent(this.props.all_ids)
      }
    if (prevProps.leagueID !== this.props.leagueID) {
        this.setState({leagueID: this.props.leagueID})
      }
    }
    
    updateContent(prop) {
      this.setState({ all_ids: prop },
        () => {this.getLeagueDetails(prop);}
      );           
  }

  getLeagueDetails(prop) {
    axios.post('/api/getLeagueDetails', { prop })
      .then(response => {
        this.setState({ leagues: response.data })
        console.log(this.state.leagues)
        response.data.map((league, index) => {
          if (index === 0) {
            this.props.parentCallback({leagueID : league.id.toString(), entryID : league.entry_id });
          }
          console.log(league.entry_id)
        })
        this.setState({errorMessage: false})
      })

      .catch(error => {
        this.setState({errorMessage: true});
      });
  }

  handleLeagueChange = (event) => {
    const selectedLeagueID = event.target.value; // Gets league.id
    const selectedOption = event.target.options[event.target.selectedIndex];
    const entryID = selectedOption.getAttribute('data-entry_id'); // Gets league.entry_id
    // Now you have both selectedLeagueID and entryID
    this.props.parentCallback({ leagueID: selectedLeagueID, entryID: entryID });
    
    // Trigger a storage event
    window.dispatchEvent(new Event('storage'));
  };


  render() {
    const { leagues, leagueID, all_ids } = this.state;

    if (this.state.errorMessage & !leagues) {
      return <div><p>There was en error loading your leagues. Please refresh the page</p></div>;
    }

    return (
      <div>
        {all_ids.length > 1 &&
        <div className=" black-bg p-10" >
          <div className="ml-10 flex flex-wrap g-10 align-center" >
        <label className="white flex" >
        Select your league:
        </label>
        <select className="minw-300 flex no-border" value={leagueID} onChange={this.handleLeagueChange}>
          {leagues.map((league, index) => (
            <option key={index} value={league.id} data-entry_id={league.entry_id}> {league.name}</option>
          ))}
        </select>
        
        </div>
      </div>}</div>
    );
  }
}

export default SelectLeague;
