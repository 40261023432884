import React, { Component } from 'react';
import axios from 'axios';
import Logo from '../../images/main_logo.png';
import RegisterScreenshot from '../../images/register_screenshot.png';

class EnterTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numbers: [''], // initial form entry
      isLoading: true, // add isLoading state to show loading
      errorGetMessage: false,
      errorSetMessage: false,
      draftFantasyChecked: false,
      fantraxChecked: false,
      notDraftedYetChecked: false,
      logged_in: this.props.logged_in
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true, errorGetMessage: false });
    axios.post('/api/checkLeague', { wp_id: localStorage.getItem('wp_id') })
      .then(response => {
        const teamIDs = response.data.map(data => data.team_id);
        const leagueIDs = response.data.map(data => data.league_id);
        this.props.parentCallback({ all_ids: response.data });

        const draftFantasyChecked = teamIDs.includes('9999996');
        const fantraxChecked = teamIDs.includes('9999997');
        const notDraftedYetChecked = teamIDs.includes('9999998');

        this.setState({ numbers: teamIDs, isLoading: false, errorGetMessage: false, draftFantasyChecked, fantraxChecked, notDraftedYetChecked });
      })
      .catch(error => {
        this.setState({ errorGetMessage: true });
      });
      console.log(this.state.logged_in)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.logged_in !== this.props.logged_in) {
        this.updateContent(this.props.logged_in)
    }
}

updateContent(prop) {
    this.setState({ logged_in: prop })
}

  handleInputChange = (event, index) => {
    const { value } = event.target;
    const numbers = [...this.state.numbers];
    numbers[index] = value.replace(/\D/, ''); // only allow numbers
    this.setState({ numbers });
  }

  handleCheckboxChange = (event, value) => {
    const { checked } = event.target;
    const numbers = [...this.state.numbers];
    if (checked && !numbers.includes(value.toString())) {
      // add value to numbers
      numbers.push(value.toString());
    } else if (!checked) {
      // remove value from numbers
      const index = numbers.indexOf(value.toString());
      if (index > -1) {
        numbers.splice(index, 1);
      }
    }
  
    // also update the corresponding checkbox state
    if (value === 9999996) {
      this.setState({ numbers, draftFantasyChecked: checked });
    } else if (value === 9999997) {
      this.setState({ numbers, fantraxChecked: checked });
    } else if (value === 9999998) {
      this.setState({ numbers, notDraftedYetChecked: checked });
    } else {
      this.setState({ numbers });
    }
  }

  handleAddEntry = () => {
    this.setState({ numbers: [...this.state.numbers, ''] }); // add new entry
  }

  handleRemoveEntry = (index) => {
    const numbers = [...this.state.numbers];
    numbers.splice(index, 1);
    this.setState({ numbers });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //this.checkStorage();
    const numbers = this.state.numbers
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i] === '') {
        return;
      }
    }
    this.setState({ isLoading: true });
    axios.post('/api/setLeague', { teamID: numbers, wp_id: localStorage.getItem('wp_id') })
      .then(response => {
        const leagueIDs = response.data.map(data => data.league_id);
        const teamIDs = response.data.map(data => data.team_id);
        this.props.parentCallback({ all_ids: response.data });
        this.setState({ numbers: teamIDs, isLoading: false, errorSetMessage: false });
      })
      .catch((error) => this.setState({ errorSetMessage: true }));
  }

  checkStorage = () => {
    const wp_id = localStorage.getItem('wp_id')
    const token = localStorage.getItem('token')
    const validate = localStorage.getItem('validate')
    if (token + wp_id !== validate) {
      window.localStorage.clear();
      window.dispatchEvent(new Event('storage'));
    }
  }


  render() {
    const { isLoading, logged_in } = this.state; // destructure isLoading from state

    if (this.state.errorGetMessage & !isLoading) {
      return <div><p>There was en error in getting your Leagues. Please refresh the page to try again</p></div>;
    }

    if (this.state.errorSetMessage & !isLoading) {
      return <div><p>There was en error in setting your Leagues. Please refresh the page to try again</p></div>;
    }



    return (

      <div className="middleBlock mt-30">
       {logged_in ? null :
        <div className="center-content">
          <img className="logo mb-30" src={Logo} />
        </div> }
        <div className="formBox">
          {isLoading ?
            <h2 className="text-center">Loading Team IDs...</h2> :
            <div>
              <h2>Link to your FPL Draft Team</h2>
              <p>To see all the information from your team and league, you need to enter your team ID.
                You can find this number on your official FPL account in the 'Team Details' page, in the URL. You can find this in the 'Status' tab, then under the 'Admin' panel. </p>
              <img className="fullwidth mb-30" src={RegisterScreenshot} />
              {this.state.numbers.length == 0 ? <p>You must enter at least one Team ID</p> : null}
              <form className="flex column g-20" onSubmit={this.handleSubmit}>
                {this.state.numbers.filter(number => ![9999996, 9999997, 9999998].includes(parseInt(number))).map((number, index) => (
                  <div className="flex g-20" key={index}>
                    <input
                      type="text"
                      value={number}
                      onChange={event => this.handleInputChange(event, index)}
                      className="grey-border"
                    />
                    <button type="button" onClick={() => this.handleRemoveEntry(index)}>-</button>
                  </div>
                ))}
                <div className="mb-10"><button type="button" onClick={this.handleAddEntry}>+ Team ID</button></div>
                <div className="flex column g-10">
                  <div>
                  <h4 className="mb-10">I don't have a Team ID</h4>
                  <p>There are a few reasons you might not have a team ID yet. If you don't,  you can select an option below to still use some Draft FC features.</p>
                  </div>
                  <div><label>
                    <input type="checkbox" value={9999996} checked={this.state.draftFantasyChecked} onChange={(e) => this.handleCheckboxChange(e, 9999996)} />{' '}
                    I play Draft Fantasy
                  </label></div>
                  <div><label>
                    <input type="checkbox" value={9999997} checked={this.state.fantraxChecked} onChange={(e) => this.handleCheckboxChange(e, 9999997)} />{' '}
                    I play Fantrax
                  </label></div>
                  <div><label>
                    <input type="checkbox" value={9999998} checked={this.state.notDraftedYetChecked} onChange={(e) => this.handleCheckboxChange(e, 9999998)} />{' '}
                    I'm not in a draft league yet.
                  </label></div>
                </div>
                <div><button className="fullwidth" type="submit">Submit</button></div>
              </form>
            </div>}
        </div>
      </div>
    );
  }
}

export default EnterTeam;
