import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import { CSVLink } from 'react-csv';
import { FilterList } from '@mui/icons-material';

class HowPointsScoredChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal: this.props.personalData,
      selectedMonth: new Set(['August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May']),
      viewToggle: 'table'
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ personal: this.props.personalData })
    }
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const { selectedMonth } = this.state;

    if (selectedMonth.has(name)) {
      selectedMonth.delete(name);
    } else {
      selectedMonth.add(name);
    }

    this.setState({ selectedMonth });
  };

  handleTableClick = () => {
    this.setState({ viewToggle: 'table' });
  }

  handleChartClick = () => {
    this.setState({ viewToggle: 'chart' });
  }

  toggleFilter = () => {
    !this.state.filter ? this.setState({ filter: true }) : this.setState({ filter: false })
  }

  render() {
    const { personal, selectedMonth } = this.state

    if (!personal) {
      return <div>loading...</div>;
    }

    const scoringTypes = [...new Set(personal.personalHowPointsScored.map((data) => data.scoring_type))]; // Get distinct rounds

    const months = [...new Set(personal.personalHowPointsScored.map((data) => data.gw_month))]; // Get distinct rounds
    const sortedMonths = months
      .map(m => [new Date(`${m} 1, 2000`).getMonth(), m])
      .sort((a, b) => (a[0] < 7 ? a[0] + 12 : a[0]) - (b[0] < 7 ? b[0] + 12 : b[0]))
      .map(([_, m]) => m);

    const filteredData = personal.personalHowPointsScored.filter((row) => selectedMonth.has(row.gw_month));

    const chartData = Object.values(filteredData.reduce((acc, curr) => {
      const { entry_id, entry_name, colour, short_name, score, scoring_type } = curr;
      const key = `${entry_id}-${entry_name}-${colour}-${short_name}`;
      if (!acc[key]) {
        acc[key] = {
          entry_id,
          entry_name,
          colour,
          short_name,
          fixture_total_points: 0,
          scoring_type,
          minutes: 0,
          clean_sheets: 0,
          yellow_cards: 0,
          assists: 0,
          bonus: 0,
          goals_scored: 0,
          goals_conceded: 0,
          saves: 0,
          own_goals: 0,
          penalties_missed: 0,
          red_cards: 0,
          penalties_saved: 0
        };
      }
      acc[key].score += score;
      if (scoring_type === 'minutes') {
        acc[key].minutes += score;
      } else if (scoring_type === 'clean_sheets') {
        acc[key].clean_sheets += score;
      } else if (scoring_type === 'yellow_cards') {
        acc[key].yellow_cards += score;
      } else if (scoring_type === 'assists') {
        acc[key].assists += score;
      } else if (scoring_type === 'bonus') {
        acc[key].bonus += score;
      } else if (scoring_type === 'goals_scored') {
        acc[key].goals_scored += score;
      } else if (scoring_type === 'goals_conceded') {
        acc[key].goals_conceded += score; // add fixture_total_points to subpoints
      } else if (scoring_type === 'saves') {
        acc[key].saves += score; // add fixture_total_points to subpoints
      } else if (scoring_type === 'own_goals') {
        acc[key].own_goals += score; // add fixture_total_points to subpoints
      } else if (scoring_type === 'penalties_missed') {
        acc[key].penalties_missed += score; // add fixture_total_points to subpoints
      } else if (scoring_type === 'red_cards') {
        acc[key].red_cards += score; // add fixture_total_points to subpoints
      } else if (scoring_type === 'penalties_saved') {
        acc[key].penalties_saved += score; // add fixture_total_points to subpoints
      };
      return acc;
    }, {})).sort((a, b) => b.fixture_total_points - a.fixture_total_points);


    const managers = [...new Set(personal.personalHowPointsScored.map((data) => data.entry_name))]; // Get distinct managers
    const managerPoints = managers.map(manager => ({
      entry_name: manager,
      fixture_total_points: personal.personalSubPoints
        .filter(data => data.entry_name === manager)
        .reduce((acc, data) => acc + data.fixture_total_points, 0)
    }));
    const sortedManagers = managerPoints.sort((a, b) => b.fixture_total_points - a.fixture_total_points)
      .map(manager => manager.entry_name);

      const scoringTypeLabels = {
        minutes: 'Mins',
        clean_sheets: 'Clean Sheets',
        yellow_cards: 'Yellow Cards',
        assists: 'Assists',
        bonus: 'Bonus',
        goals_scored: 'Goals Scored',
        goals_conceded: 'Goals Conceded',
        saves: 'Saves',
        own_goals: 'Own Goals',
        penalties_missed: 'Penalties Missed',
        red_cards: 'Red Cards',
        penalties_saved: 'Penalties Saved'
      };

      const headers = [
        { label: 'Manager', key: 'manager' },
        ...scoringTypes.map((scoring_type) => ({
          label: scoringTypeLabels[scoring_type], 
          key: scoring_type 
        })),
      ];
      
    const csvData = sortedManagers.map((manager) => {
      const rowData = { manager };
      scoringTypes.forEach((scoring_type) => {
        const csvFilteredData = filteredData.filter((item) => item.entry_name === manager && item.scoring_type === scoring_type);
        const sum = csvFilteredData.reduce((accumulator, currentValue) => accumulator + currentValue.score, 0);
        rowData[scoring_type] = sum || 0;
      });
      return rowData;
    });

    return (
      <div>
        <div className="content-wrapper mb-10">
          <div className="flex flex-wrap space-between mb-20 align-center g-10">
            <h3 className="mb-0">How Points Scored</h3>
            <div className="chart-buttons flex flex-wrap space-between align-center g-20">
              <button className="show-desktop download-data"><CSVLink className="download-link" data={csvData} headers={headers} filename={'howPointsScored_data.csv'}>Download Data</CSVLink></button>
              <div className="tablechart-toggle onclick">
                  <button className={this.state.viewToggle == 'table' ? "active": null} onClick={this.handleTableClick}>Table</button>
                  <button className={this.state.viewToggle == 'chart' ? "active": null} onClick={this.handleChartClick}>Chart</button>
                </div>
              <div className="text-right">
                <button className="filter" onClick={this.toggleFilter}><FilterList/></button>
                {!this.state.filter ? null :
                  <div className="filter-container flex column g-5 mb-10 text-left ">
                    {sortedMonths.map((value, index) => (
                      <label className="text-overflow" key={index}>
                        <input
                          type="checkbox"
                          name={value}
                          checked={selectedMonth.has(value)}
                          onChange={this.handleCheckboxChange}
                        />
                        {value}
                      </label>
                    ))}
                  </div>}</div>
            </div>
          </div>
          {this.state.viewToggle == 'table' ?
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Manager</th>
                    {scoringTypes.map((scoring_type) => (
                      <th key={scoring_type}>{scoringTypeLabels[scoring_type] || scoring_type}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortedManagers.map((manager) => (
                    <tr key={manager}>
                      <td className="content-width">{manager}</td>
                      {scoringTypes.map((scoring_type) => {
                        const tableFilteredData = filteredData.filter((item) => item.entry_name === manager && item.scoring_type === scoring_type);
                        const sum = tableFilteredData.reduce((accumulator, currentValue) => accumulator + currentValue.score, 0);
                        return (
                          <td className="content-width" key={`${manager}-${scoring_type}`}>
                            {sum ? <div>{sum}</div> : 0}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> :
            <div className="mt-20 vh-50" style={{ width: '100%' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData} margin={{ top: 0, right: 0, left: 5, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="short_name" label={{ value: 'Manager', position: 'insideBottom', dy: 15 }} />
                  <YAxis label={{ value: 'Points', position: 'insideLeft', angle: -90 }}/>
                  <Tooltip position="right" />
                  <ReferenceLine y={0} stroke="#000" />
                  <Bar dataKey="minutes" stackId="a" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#4A1D47" />
                    ))}
                  </Bar>
                  <Bar dataKey="clean_sheets" stackId="a">
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#081071" />
                    ))}
                  </Bar>
                  <Bar dataKey="goals_scored" stackId="a">
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#4653DF" />
                    ))}
                  </Bar>
                  <Bar dataKey="assists" stackId="a">
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#52B7AD" />
                    ))}
                  </Bar>
                  <Bar dataKey="saves" stackId="a" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#F7C443" />
                    ))}
                  </Bar>
                  <Bar dataKey="penalties_saved" stackId="a" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#B5D26D" />
                    ))}
                  </Bar>
                  <Bar dataKey="bonus" stackId="a" radius={[4, 4, 0, 0]} >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#FFA1EF" />
                    ))}
                  </Bar>
                  <Bar dataKey="yellow_cards" stackId="b" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#F1923C" />
                    ))}
                  </Bar>
                  <Bar dataKey="red_cards" stackId="b" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#60EBFF" />
                    ))}
                  </Bar>
                  <Bar dataKey="goals_conceded" stackId="b" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#B6263D" />
                    ))}
                  </Bar>
                  <Bar dataKey="penalties_missed" stackId="b" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#1E8F08" />
                    ))}
                  </Bar>
                  <Bar dataKey="own_goals" stackId="b" >
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill="#C54FDC" />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>}
        </div>

        <button className="show-mobile fullwidth mb-20 download-data"><CSVLink className="download-link" data={csvData} headers={headers} filename={'howPointsScored_data.csv'}>Download Data</CSVLink></button>

        <div className="br-16 p-20 grey-bg flex flex-wrap flex-start g-10">
          <span className="p-5">
            <span className="circle" style={{ background: '#4A1D47' }}></span>
            Minutes
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#081071' }}></span>
            Clean Sheets
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#4653DF' }}></span>
            Goals Scored
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#52B7AD' }}></span>
            Assists
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#F7C443' }}></span>
            Saves
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#B5D26D' }}></span>
            Penalties Saved
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#FFA1EF' }}></span>
            Bonus
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#F1923C' }}></span>
            Yellow Cards
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#60EBFF' }}></span>
            Red Cards
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#B6263D' }}></span>
            Goals Conceded
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#1E8F08' }}></span>
            Penalties Missed
          </span>
          <span className="p-5">
            <span className="circle" style={{ background: '#C54FDC' }}></span>
            Own Goals
          </span>
        </div>



      </div>
    );
  }
}

export default HowPointsScoredChart;
