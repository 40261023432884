import React, { useMemo } from 'react';
import axios from 'axios';
import teamImages from '../../images/shirts/shirtsLookup.js';
import Shield from '../../images/logo_shield.png';
import Pitch from '../../images/pitch.png';

class OptimalLineupLayout extends React.Component {
  constructor(props) {
    super(props);
    //const distinctValues = ['GKP', 'DEF', 'MID', 'FWD']; // add this line to initialize distinctValues
    this.state = {
      leagueID: this.props.leagueID,
      wp_id: this.props.wp_id,
      liveGWDetail: null,
      errorMessage: false,
      isSpecialLeague: false,
      loading: true,
      scoring: null,
      gw: this.props.gw,
      placeholder: false
    }
  }

  componentDidMount() {
    if (this.state.placeholder != true) {
    this.setSpecialLeague(() => this.getData(this.props.leagueID, this.state.wp_id));}
    //this.getData(this.props.leagueID, this.state.wp_id);
    //TableSort()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({globalData: ''}, () => {
        this.setSpecialLeague(() => this.updateContent(this.props.leagueID, this.state.wp_id));
      });
      //this.initializeTable();
    }
  }

  updateContent(prop) {
    this.setState({ leagueID: prop })
    this.getData(prop, this.state.wp_id)
  }

  getData(leagueID, wp_id) {
    this.setState({loading: true})
    if (leagueID) {
      if (this.state.isSpecialLeague == false) {
        axios
        .post('/api/getOptimalLineups', { leagueID: leagueID, wp_id: wp_id })
        .then((res) => {
          const response = res.data.optimalDetail;
          const scoring = res.data.scoring;
          this.setState({ liveGWDetail: response, loading: false, scoring: scoring }, () => {
            //this.initializeTable(); // initialize the table once the data is loaded
          })
          this.setState({errorMessage: false});
          
        })
        .catch((error) => this.setState({errorMessage: true}));
      }}
  }

  setSpecialLeague(callback) {
    const isSpecialLeague = ['9999996', '9999997', '9999998'].includes(this.props.leagueID);
    this.setState({ isSpecialLeague }, callback);
  }

  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
}

  
  render() {

    const { liveGWDetail, isSpecialLeague, loading, scoring, gw, placeholder}= this.state

    if (placeholder) {
      return (<div>
        <h2>Optimal Lineups will be ready ahead of GW1.</h2>
        </div>)
    }

    if (loading == true) {
      return (
        <div>
        <h2>Optimal Lineup</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>)
    }

    if (this.state.errorMessage & !liveGWDetail) {
      return <div><p>There was en error in getting the Live GW data. Please refresh the page to try again</p></div>;
    }

    for (let i = 0; i < liveGWDetail.length; i++) {
      liveGWDetail[i].Elements.sort((a, b) => a.position - b.position);
    }

    if (isSpecialLeague == true) {
      return (<div><h3>Not available in this mode</h3><p>You need to select an Official FPL League to use this feature</p></div>)
    }


    return (
      <div className="text-center flex column center-content space-evenly">
        <div className=" maxw-800 center-margin">
            <h3 className="mb-20">Optimal Lineup</h3>
            {scoring == 'c' ?
            <div className="mb-30 fullwidth"> 
            <p>Based on your current squad, this is our algorithm's suggestion for the best way to line up for GW{gw + 1}, with a view of your expected points.</p> 
            <p>Any player points in red indicate we think the player may not start.</p>
            <p>Please remember that these are modelled xPts and are just a guide of what may happen. We advise you don't solely rely on this data to make your FPL decisions.</p></div> : 
            <div className="mb-30 fullwdith"> 
            <p>Based on your current squad, this is our algorithm's suggestion for the best way to line up for GW{gw + 1}. We've also given you a view of how your H2H partner could line up, and their expected points.</p>
            <p>Any player points in red indicate we think the player may not start.</p>
            <p>Please remember that these are modelled xPts and are just a guide of what may happen. We advise you don't solely rely on this data to make your FPL decisions.</p>
            </div>}
            </div>
            <div className="live-gw_details text-center flex g-20 flex-wrap center-content space-evenly">
    {liveGWDetail.map((entry) => (
      <div key={entry.entry_id} className="entry-card flex space-evenly column" style={{ backgroundImage: `url(${Pitch})`,backgroundSize: 'cover' }}>
        <div className="live-header g-10 mt-10">
          <div className="flex align-center g-10">
        <img className="shield" src={Shield} />
        <h4 className="mb-0 white">{entry.entry_name}</h4>
        </div>
        <h4 className="mb-0 p-5 br-8 border-white white">{parseFloat(entry.current_total_points).toFixed(1)}</h4>
        </div>
        
        <div className="start-players">
          <div className="player-row flex space-evenly mb-10">
            {entry.Elements.filter(
              (el) => el.startstatus == 'Start' && el.singular_name_short === "GKP"
            ).map((el) => (
              <div key={el.el_id} className=" onclick player-single flex-grow-1" >
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className={el.starting == 1 ? "player_element_points" : "player_element_points-sub"}>
                  {Number(Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)).toFixed(1)}
                </div>
              </div>
            ))}
          </div>
          <div className="player-row flex space-evenly mb-10 g-5">
            {entry.Elements.filter(
              (el) => el.startstatus == 'Start' && el.singular_name_short === "DEF"
            ).map((el) => (
              <div key={el.el_id} className="onclick player-single flex-grow-1" >
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className={el.starting == 1 ? "player_element_points" : "player_element_points-sub"}>
                  {Number(Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)).toFixed(1)}
                </div>
              </div>
            ))}
          </div>
          <div className="player-row flex space-evenly mb-10  g-5">
            {entry.Elements.filter(
              (el) => el.startstatus == 'Start' && el.singular_name_short === "MID"
            ).map((el) => (
              <div key={el.el_id} className="onclick player-single flex-grow-1" >
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className={el.starting == 1 ? "player_element_points" : "player_element_points-sub"}>
                  {Number(Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)).toFixed(1)}
                </div>
              </div>
            ))}
          </div>
          <div className="player-row flex space-evenly mb-20  g-5">
            {entry.Elements.filter(
              (el) => el.startstatus == 'Start' && el.singular_name_short === "FWD"
            ).map((el) => (
              <div key={el.el_id} className="onclick player-single flex-grow-1" >
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className={el.starting == 1 ? "player_element_points" : "player_element_points-sub"}>
                  {Number(Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)).toFixed(1)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sub-players player-row flex space-evenly g-5">
          {entry.Elements.filter((el) => el.startstatus == 'Sub').map((el) => {
            const totalPoints = Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0);
            return (
              <div key={el.el_id} className="onclick player-single flex-grow-1">
                <div>{this.getTeamImage(el.team)}</div>
                <div className="player_element_name text-overflow">{el.web_name}</div>
                <div className={el.starting == 1 ? "player_element_points" : "player_element_points-sub"}>
                  {Number(Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)).toFixed(1)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ))}
  </div>
      </div>
    );
  }
}

export default OptimalLineupLayout;
