import TotalPointsChart from "./totalPointsTable";
import RunningPointsChart from "./runningPointsTable";
import HowPointsScoredChart from "./howPointsScoredTable";
import PointsPositionChart from "./pointsPositionTable";
import SubPointsChart from "./subPointsTable";
import PersonalGWTable from "./personalGWTable";
import HistoryPlaceholder from "./historyPlaceholder";
import GlobalRankLayout from "./globalRankLayout";
import H2HTotalPointsChart from "./h2hTotalPointsTable";
import H2HRunningPointsChart from "./h2hRunningPointsTable";
import PersonalMonthSummary from "./personalMonthSummary";


export const PersonalChartConfig = [

	{
		name: 'Gameweeks',
		isClassic: true,
		isH2H: true,
		isBeyond2022: false,
		component: PersonalGWTable,
	},
	{
		name: 'Total Points',
		isClassic: true,
		isH2H: true,
		isBeyond2022: false,
		component: TotalPointsChart,
	},
	{
		name: 'Running Points',
		isClassic: true,
		isH2H: true,
		isBeyond2022: false,
		component: RunningPointsChart,
	},
	{
		name: 'H2H Total Points',
		isClassic: false,
		isH2H: true,
		isBeyond2022: true,
		component: H2HTotalPointsChart,
	},
	{
		name: 'H2H Running Points',
		isClassic: false,
		isH2H: true,
		isBeyond2022: true,
		component: H2HRunningPointsChart,
	},
	{
		name: 'Start v Sub',
		isClassic: true,
		isH2H: true,
		isBeyond2022: false,
		component: SubPointsChart,
	},
	{
		name: 'Points By Position',
		isClassic: true,
		isH2H: true,
		isBeyond2022: false,
		component: PointsPositionChart,
	},
	{
		name: 'How Points Scored',
		isClassic: true,
		isH2H: true,
		isBeyond2022: false,
		component: HowPointsScoredChart,
	},
	{
		name: 'Monthly Reports',
		isClassic: true,
		isH2H: true,
		isBeyond2022: true,
		component: PersonalMonthSummary,
	},
	{
		name: 'Global Rank',
		isClassic: true,
		isH2H: true,
		isBeyond2022: true,
		component: GlobalRankLayout,
	},

];