import React from 'react';
import { PersonalChartConfig } from './personalChartConfiguration';


class PersonalMenu extends React.Component {

  constructor(props){
    super(props);

  }

  handleClick(component){
    this.props.onChange(component);
  }
  
  render() {

    const { selectedYear, selectedScoring } = this.props;

    const buttons = PersonalChartConfig.filter(obj => {
      // Inclusion logic for selectedYear and selectedScoring
      if (selectedYear === 2022 && !obj.isBeyond2022) return true;
      if (selectedYear !== 2022) return true;
      return false;
    }).filter(obj => {
      if (selectedScoring === 'c' && obj.isClassic) return true;
      if (selectedScoring === 'h' && obj.isH2H) return true;
      return false;
    }).map(obj => {
      const Component = obj.component; // Get the component from the configuration
      return (
        <button onClick={() => this.handleClick(Component)} className="menuItem" key={obj.name}>
          {obj.name}
        </button>
      );
    });

    return (
      <div className="menuWrapper">
        {buttons}
      </div>
    );
  }
}

export default PersonalMenu;
