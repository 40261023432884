import React, { Component } from 'react';
import Logo from '../../images/main_logo.png';
import GWStatus from '../personalDash/gwStatus'
import { FormatListNumberedOutlined, PublicOutlined, History, RocketLaunch, Workspaces, LiveTvOutlined, PersonSearchOutlined, AnalyticsOutlined, Diversity3Outlined, MenuRounded, CalendarViewMonth, HistoryEduOutlined, Forum } from '@mui/icons-material';

class MainNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_ids: this.props.all_ids,
            screenWidth: window.innerWidth,
            menuToggle: false,
            role: this.props.role,
            leagueID: this.props.leagueID
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleScreenWidthChange);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.all_ids !== this.props.all_ids) {
            this.updateContent(this.props.all_ids)
        }
        if (prevProps.leagueID !== this.props.leagueID) {
            this.updateLeagueID(this.props.leagueID)
        }
    }

    updateContent(prop) {
        this.setState({ all_ids: prop })
    }

    updateLeagueID(prop) {
        if(prop){
        this.setState({leagueID: prop.toString()})
        }
    }

    handleCallback = (data) => {
        this.props.parentCallback(data)
        this.setState(data)
    }

    handleCurrentGW = (data) => {
        this.handleCallback(data)
    }

    handleLogout = () => {
        this.props.parentCallback({all_ids: null, leagueID: null, wp_id: null, token: null})
        localStorage.clear();
        window.dispatchEvent(new Event('storage'));
    }

    handleMenuButtonClick = (event) => {
        const viewValue = event.target.value;
        localStorage.setItem('view', viewValue);
        this.handleCallback({view: viewValue})
        this.setState({ menuToggle: false });
    }

    handleScreenWidthChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };


    menuClick = () => {
        if (this.state.menuToggle == true) {
            this.setState({ menuToggle: false });

        } else {
            this.setState({ menuToggle: true });
        }
    }

    handleSetTeamClick = () => {
        localStorage.setItem('view', 'setteam');
        window.dispatchEvent(new Event('storage'));
      }

    render() {

        const { all_ids, screenWidth, menuToggle, leagueID } = this.state;

        return (

            <div>

                <div className={screenWidth >= 1000 ? "mainNav flex column " : "mobile-nav flex column space-between"}>

                    {screenWidth >= 1000 ?
                        <div className="flex mb-30 align-center space-between">
                            <img className="logo" src={Logo} />
                            <GWStatus onCurrentGWChange={this.handleCurrentGW} />
                        </div> :
                        <div className="flex space-between mobile-nav_header">
                            <img className="logo" src={Logo} />
                            <div><GWStatus onCurrentGWChange={this.handleCurrentGW} /></div>
                            <div className="transformYthree" onClick={this.menuClick}><MenuRounded /></div>
                        </div>}

                    <div className={screenWidth >= 1000 ? "flex column space-between h-100" : (menuToggle == true ? "mobile-nav_body" : "mobile-nav_body hidden")}>
                        <div className="menuButtons mb-20">
                            {!(["9999996", "9999997", "9999998"].includes(leagueID)) &&
                                <>
                                    <button className="fullwidth livegw mb-10 flex align-center g-5" value="livegw" onClick={this.handleMenuButtonClick}><LiveTvOutlined />Live GW</button>
                                    <button className="fullwidth personal mb-10 flex align center g-5" value="personal" onClick={this.handleMenuButtonClick}><AnalyticsOutlined />League Analytics</button>
                                    <button className="fullwidth personal mb-10 flex align center g-5" value="monthlyreport" onClick={this.handleMenuButtonClick}><HistoryEduOutlined />Monthly Reports</button>
                                    <button className="fullwidth globalrank mb-10 flex align center g-5" value="globalrank" onClick={this.handleMenuButtonClick}><PublicOutlined />Global Rank</button>
                                </>
                            }
                            <button className="fullwidth global mb-10 flex align center g-5" value="fdr" onClick={this.handleMenuButtonClick}><CalendarViewMonth />FDR</button>
                            <button className="fullwidth global mb-10 flex align center g-5" value="global" onClick={this.handleMenuButtonClick}><PersonSearchOutlined />Squad Planner</button>
                            <button className="fullwidth personal mb-10 flex align center g-5" value="lineups" onClick={this.handleMenuButtonClick}><Diversity3Outlined />Predicted Lineups</button>
                            <button className="fullwidth draftrank mb-10 flex align center g-5" value="draftroom" onClick={this.handleMenuButtonClick}><FormatListNumberedOutlined />Draft Room</button>
                            <button className="fullwidth history mb-10 flex align center g-5" value="history" onClick={this.handleMenuButtonClick}><History />Time Capsule</button>
                            <button className="fullwidth forum mb-10 flex align center g-5" value="discord" onClick={this.handleMenuButtonClick}><Forum />Join Discord</button>
                            {['author', 'editor', 'administrator'].includes(this.state.role) ?
                                <button className="fullwidth globalrank mb-10 flex align center g-5" value="mitch" onClick={this.handleMenuButtonClick}><RocketLaunch />Mitch's Room</button> : null
                            }
                        </div>
                        <div>
                            <button className="fullwidth mt-10 mb-30" onClick={this.handleSetTeamClick}>Add new leagues</button>
                            <button className="fullwidth flex-end" onClick={this.handleLogout}>Log out</button>
                        </div>
                    </div>


                </div> </div>
        );
    }
}

export default MainNav;