import React from 'react';
import axios from 'axios';
import TotalPointsChart from './totalPointsTable';
import PersonalMenu from './personalMenu';
import H2HPersonalMenu from './h2hPersonalMenu';
import { ConnectedTvOutlined } from '@mui/icons-material';

class PersonalHistoryLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponent: TotalPointsChart,
      personalHistoryData: null,
      personalHistoryConfig: null,
      errorMessage: false,
      wp_id: localStorage.getItem('wp_id'),
      loadingHistory: true,
      all_ids: this.props.all_ids,
      leagueID: null,
      selectedYear: null,
      selectedLeague: null,
      selectedScoring: null,
      innerLoading: false,
      updateFailed: false,
      updateHistoryError: false
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
    this.getHistoryData();

  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('personalHistoryConfig:' + this.state.personalHistoryConfig)
    console.log('personalHistoryData:' + this.state.personalHistoryData)
    if (this.state.personalHistoryConfig === 'no_data' && prevState.personalHistoryConfig !== 'no_data') {
      this.setHistoryData()
    }
    if (this.state.personalHistoryConfig === 'update_required' && prevState.personalHistoryConfig !== 'update_required') {
    this.updateHistoryData()
    }
    if (this.state.selectedLeague !== prevState.selectedLeague) {
      this.setState({innerLoading: true})
      this.getLeagueHistory(this.state.selectedYear, this.state.selectedLeague, this.state.selectedScoring)
    }
    if (this.state.updateHistoryError === true && prevState.updateHistoryError !== true){
    this.getHistoryData();
    }
  }

  setHistoryData() {
    const wp_id = this.state.wp_id
    const all_ids = this.state.all_ids
    axios.post('/api/setHistoryData', { wp_id, all_ids })
      .then((response) => {
        this.setState({
          personalHistoryConfig: response.data,
          selectedYear: response.data.years[0].year,
          selectedLeague: response.data.years[0].leagues[0].league_id,
          selectedScoring: response.data.years[0].leagues[0].scoring
        });
      })
      .catch(error => { this.setState({ errorMessage: true }); });
  }

  updateHistoryData() {
    console.log('updateHistoryData is triggered')
    const wp_id = this.state.wp_id
    const all_ids = this.state.all_ids
    axios.post('/api/updateHistoryData', { wp_id, all_ids })
      .then((response) => {
        this.setState({
          personalHistoryConfig: response.data,
          selectedYear: response.data.years[0].year,
          selectedLeague: response.data.years[0].leagues[0].league_id,
          selectedScoring: response.data.years[0].leagues[0].scoring,
          errorMessage: false, 
          updateHistoryError: false
        });
      })
      .catch(error => { this.setState({ errorMessage: true, updateHistoryError: true }); });
  }

  getHistoryData() {
    console.log('getHistoryData is triggered')
    const wp_id = this.state.wp_id;
    const all_ids = this.state.all_ids
    this.setState({ loadingHistory: true, errorMessage:false, updateHistoryError: false });
    axios.post('/api/getHistoryData', { wp_id, all_ids })
    .then((response) => {
      if (response.data === 'no_data' || response.data === 'update_required') {
        this.setState({
          personalHistoryConfig: response.data
        });
      } else {
        this.setState({
          personalHistoryConfig: response.data,
          selectedYear: response.data.years[0].year,
          selectedLeague: response.data.years[0].leagues[0].league_id,
          selectedScoring: response.data.years[0].leagues[0].scoring,
          errorMessage: false
        });
      }
    })
    .catch(error => {
      this.setState({ errorMessage: true, updateHistoryError: true });
    });
  }

  handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    const { personalHistoryConfig } = this.state;

    // Find the corresponding year object in personalHistoryConfig
    const yearObj = personalHistoryConfig.years.find(year => parseInt(year.year) === selectedYear);

    // If the year object is found, get the first league_id, otherwise set to null
    const selectedLeague = yearObj ? yearObj.leagues[0].league_id : null;

    // Set the state with selectedYear and selectedLeague
    if (yearObj) {
      // Find the league object that matches the selectedLeague within the matched year
      const leagueObj = yearObj.leagues.find(league => league.league_id === selectedLeague);

      if (leagueObj) {
          // Extract the scoring value from the matched league object
          const selectedScoring = leagueObj.scoring;

          // Set the state with the selected league and its corresponding scoring value
          this.setState({ 
              selectedLeague: selectedLeague,
              selectedScoring: selectedScoring,
              selectedYear: selectedYear 
          });
      } else {
          console.error('Selected league not found in the matched year');
      }
  } else {
      console.error('Selected year not found in personal history config');
  }
}

  handleLeagueChange = (event) => {
    const selectedLeague = parseInt(event.target.value); // Get the selected league ID from the event
    const { selectedYear, personalHistoryConfig } = this.state; // Destructure the state to get selectedYear and personalHistoryConfig
    console.log(selectedLeague)
    console.log(selectedYear)
    // Find the year object that matches the selectedYear
    const yearObj = personalHistoryConfig.years.find(year => parseInt(year.year) === parseInt(selectedYear));
    console.log(yearObj)

    if (yearObj) {
        // Find the league object that matches the selectedLeague within the matched year
        const leagueObj = yearObj.leagues.find(league => league.league_id === selectedLeague);

        if (leagueObj) {
            // Extract the scoring value from the matched league object
            const selectedScoring = leagueObj.scoring;

            // Set the state with the selected league and its corresponding scoring value
            this.setState({ 
                selectedLeague: selectedLeague,
                selectedScoring: selectedScoring 
            });
        } else {
            console.error('Selected league not found in the matched year');
        }
    } else {
        console.error('Selected year not found in personal history config');
    }
}


  getLeagueHistory(year, league_id, scoring) {
    axios.post('/api/getLeagueHistory', {leagueID: league_id, year: year, scoring: scoring})
      .then((res) => {
        const response = res.data;
        this.setState({
          personalHistoryData :response, 
          errorMessage: false, 
          innerLoading: false
        });
      })
      .catch((error) => this.setState({errorMessage: true}))
  }

  resetPersonalHistory = () => {
    this.setState({
      selectedComponent: TotalPointsChart,
      personalHistoryData: null,
      personalHistoryConfig: null,
      errorMessage: false,
      loadingHistory: true,
      selectedYear: null,
      selectedLeague: null,
      selectedScoring: null,
      innerLoading: false,
      updateFailed: false,
      updateHistoryError: false
    }, this.getHistoryData);
  }


  render() {
    const { selectedComponent, personalHistoryData, loadingHistory, personalHistoryConfig, updateRequired, selectedYear, innerLoading, selectedScoring } = this.state;
    const SelectedComponent = selectedComponent;

    if (this.state.errorMessage && !personalHistoryData) {
      return (
        <div className="p-20">
          <p>Getting your league history has timed out. As we're saving every detail of your season this can take a while,
            so this is expected. Please click below to keep loading your data.</p>
          <button onClick={this.esetPersonalHistory}>Continue loading</button>
        </div>
      );
    }

    if (!personalHistoryConfig) {
      return (<div className="p-20">
        <p>Looking for existing history. This will take a while if you have many leagues. </p>
        <p>We have recently added new features to the time capsule so we are loading more data than normal. </p>
      </div>)
    }

    if (personalHistoryConfig == 'no_data') {
      if (!loadingHistory) {
        return (
          <div>
          <p>We've noticed some leagues that we need to save for you.</p>
          <p>We're saving every detail of your season. This can take a while so please don't navigate away from this page.</p>
        </div>
        )
      }
      return (
        <div className="p-20">
          <p>It looks like you have no history stored with us, you'll be able to save this season once GW38 has finished for your official Fantasy Premier League Draft Leagues</p>
        </div>)
    }

    if (personalHistoryConfig == 'update_required') {
      return (<div className="p-20">
        <p>We've noticed some leagues that we need to save for you.</p>
        <p>We're saving every detail of your season. This can take a while so please don't navigate away from this page.</p>
      </div>)
    }

    return (
      <div>
        {personalHistoryConfig ?
          <div className="history-menu black-bg">
            <div>
              <label className="white">
                Select Year:
                <select onChange={this.handleYearChange}>
                  {personalHistoryConfig.years.map((years, index) => (
                    <option key={index} value={years.year}>{years.year}</option>
                  ))}
                </select>
              </label></div>
            <div >
              <label className="white">
                Select League:
                <select onChange={this.handleLeagueChange}>
                  {personalHistoryConfig.years
                    .filter((year) => year.year === parseInt(selectedYear))
                    .map((year, index) =>
                      year.leagues.map((league) => (
                        <option key={league.league_id} value={league.league_id}>
                          {league.league_name}
                        </option>
                      ))
                    )}
                </select>
              </label></div>
          </div>
          : null
        }
        { innerLoading ? 
        <div className="p-20">
        <h2>Loading Analytics</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>:
        (personalHistoryData ? (
            <div>
              <PersonalMenu onChange={this.onChange.bind(this)} personalData={personalHistoryData} selectedYear={selectedYear} selectedScoring={selectedScoring}/>
              <div className="p-20 personal">
                {SelectedComponent && <SelectedComponent personalData={personalHistoryData} selectedYear={selectedYear} selectedScoring={selectedScoring} />} {/* Render the selected component here */}
              </div>
            </div> 
        ) :
          null)}
      </div>
    );
  }
}

export default PersonalHistoryLayout;
