import React from 'react';
import axios from 'axios';
import PersonalMenu from './draftRoomMenu';
import DraftRankingLayout from './draftRankingLayout';
import KeeperPairingsLayout from './keeperPairingsLayout';

class DraftRoomLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      product: this.props.product,
      role: this.props.role,
      gw: this.props.gw,
      selectedComponent: DraftRankingLayout,
      placeholder: true
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({ leagueID: this.props.leagueID })
      //this.initializeTable();
    }
  }

  render() {
    const { selectedComponent, leagueID, product, role, gw } = this.state;
    const SelectedComponent = selectedComponent;

    const products = [734, 4144, 4145, 4146].includes(product);
    const isadmin = ['administrator', 'author'].includes(role);

    if (!products && !isadmin && gw === 0) {
      return (
        <div className="p-20">
          <h2>Pre-season Pass required</h2>
          <p>Access to the Draft Room before 16th August requires a pre-season pass.</p>
          <p>It looks like your plan doesn't include this, so head to draftfc.co.uk to upgrade.</p>
          <a href="https://draftfc.co.uk/" target="_blank" rel="noopener noreferrer">
            <button className="download-data">Go to My Account</button>
          </a>
        </div>
      )
    }

    return (
      <div>
        <PersonalMenu onChange={this.onChange.bind(this)} />
        <div className={SelectedComponent === KeeperPairingsLayout ? "" : "p-20 personal"}>
          {SelectedComponent && <SelectedComponent leagueID={leagueID} />} {/* Render the selected component here */}
        </div>
      </div>
    );
  }
}

export default DraftRoomLayout;
