import React from 'react';
import axios from 'axios';
import TotalPointsChart from './totalPointsTable';
import PersonalMenu from './personalMenu';
import H2HPersonalMenu from './h2hPersonalMenu';
import teamImages from '../../images/shirts/shirtsLookup.js';

class PersonalLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponent: TotalPointsChart,
      leagueID: this.props.leagueID,
      personalData: '',
      errorMessage: false,
      gw: this.props.gw,
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
    if (this.state.gw !=0 ) {
    this.getData(this.props.leagueID)};
  }

  componentDidUpdate(prevProps) {                                           
    if (prevProps.leagueID !== this.props.leagueID) {
        this.updateContent(this.props.leagueID)    
    }
  }

  updateContent(prop) {
    this.setState({ league_ids: prop });
    this.setState({personalData : ''});
    this.getData(prop);           
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  getData(prop) {
    if (prop) {
      axios.post('/api/getGameElements', {leagueID: prop})
      .then((res) => {
        const response = res.data;
        this.setState({personalData :response});
        this.setState({errorMessage: false});
      })
      .catch((error) => this.setState({errorMessage: true}));
    }
  }

  render() {
    const {selectedComponent, personalData, gw} = this.state;
    const SelectedComponent = selectedComponent;

    if (gw == 0) {
      return (<div className="p-20">
        <h2>League Analytics will be available in GW1</h2>
        <p>As the season hasn't started, there is no data to display in this dashboard.</p>
        </div>)
    }

    if (this.state.errorMessage & !personalData) {
      return <div className="p-20"><p>There was en error in getting the league Analytics data. Please refresh the page to try again</p></div>;
    }

    if (!personalData) {
      return (
        <div className="p-20">
        <h2>League Analytics</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>)
    }

    /*if (personalData.gwStatus.currentGW == 1 & personalData.gwStatus.gwStatus == "false") {
      return (<div className="p-20">
        <h2>League analytics will be available after GW1</h2>
        <p>Your league analytics will begin to load when Gamewek 1 has been flagged as completed by FPL</p>
        </div>)
    }*/

    return (
      <div>
        {personalData.scoring == "c" ?
          <div>
            <PersonalMenu onChange={this.onChange.bind(this)} />
            <div className="p-20 personal">
              {SelectedComponent && <SelectedComponent personalData={personalData} gw={gw} />} {/* Render the selected component here */}
            </div>
          </div> :
          <div>
            <H2HPersonalMenu onChange={this.onChange.bind(this)} />
            <div className="p-20 personal">
              {SelectedComponent && <SelectedComponent personalData={personalData} gw={gw}/>} {/* Render the selected component here */}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default PersonalLayout;
