import React from 'react';
import axios from 'axios';
import GWStatus from '../personalDash/gwStatus';
import Shield from '../../images/logo_shield.png';
//import TableSort from "table-sort-js/table-sort.js";
import html2canvas from 'html2canvas';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class GlobalRankLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalRankSummary: this.props.personalData?.personalGlobalRanks?.globalRankSummary ?? {},
      globalRankMap: this.props.personalData?.personalGlobalRanks?.globalRankMap ?? [],
      scoring: this.props.selectedScoring,
      year: this.props.selectedYear,
    };
  }

  componentDidMount() {
    // Initialization code if needed
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ 
        globalRankSummary: this.props.personalData?.personalGlobalRanks?.globalRankSummary ?? {},
        globalRankMap: this.props.personalData?.personalGlobalRanks?.globalRankMap ?? []
      });
    }
  }

  updateContent(prop) {
    this.setState({ league_ids: prop });
    this.getData(prop);
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  render() {
    const { globalRankSummary, globalRankMap, year } = this.state;

    if (year == 2022) {
      return (
        <div>
          <h4>There are no Global Ranks for 2022. This data is available for 2023 onwards.</h4>
        </div>
      );
    }

    return (
      <div>
        <div className="global-card mb-30">
          <div className="grey-bg content-wrapper mb-10">
            <div className="flex space-between">
              <h3 className="mb-5">GW{globalRankSummary.gw ?? ''} OFPL Draft Rank</h3>
              <img className="shield" src={Shield} alt="Shield" />
            </div>
            <div className="mb-20">
              <p className="italic">{globalRankSummary.league_size ?? 0} person leagues</p>
            </div>
            <div className="flex space-between">
              <p className="bold">#1 Ranked League Total Points</p>
              <p className="bold">{globalRankSummary.top_total_points?.toLocaleString() ?? 'N/A'}</p>
            </div>
            <div className="flex space-between">
              <p className="bold">Your League Total Points</p>
              <p className="bold">{globalRankSummary.league_total_points?.toLocaleString() ?? 'N/A'}</p>
            </div>
            <div className="flex space-between">
              <p className="bold">Your League Global Rank</p>
              <p className="bold">{(globalRankSummary.league_global_rank?.toLocaleString() ?? 'N/A') + '/' + (globalRankSummary.total_leagues?.toLocaleString() ?? 'N/A')}</p>
            </div>
          </div>
        </div>
        <div className="content-wrapper mb-30">
          <h3>Rank History</h3>
          {globalRankMap.length > 0 ? (
            <div className="mt-20 vh-50" style={{ width: '100%' }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={globalRankMap}
                  margin={{ top: 0, right: 0, left: 5, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="0 10" />
                  <XAxis dataKey="gw" label={{ value: 'Gameweek', position: 'insideBottom', dy: 15 }} />
                  <YAxis tickFormatter={(tick) => `${tick}`} label={{ value: 'Rank', position: 'insideLeft', dx: 0, angle: -90 }} />
                  <Tooltip formatter={(value, name) => [`${value}`, 'Global Rank']} labelFormatter={(label) => `Gameweek ${label}`} />
                  <Line dataKey="global_rank" fill="#8884d8" strokeWidth={3} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default GlobalRankLayout;
