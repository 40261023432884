import React, { useMemo } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
//import TableSort from "table-sort-js/table-sort.js";
//import Shield from '../../images/logo_shield.png';

class DraftRankingLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      draftData: '',
      selectedPlayer: '',
      selectedPlayerID: '',
      draftDetail: '',
      errorMessage: false,
      selectedLeagueSize: 8,
      isSpecialLeague: false,
      selectedTypes: new Set(),
      filteredSummaryData: [],
      sortedColumn: null,
      sortDirection: null, // 'asc', 'desc', or null
      showDashboardInfo: false, 
    };
  }

  intervalId = null;

  componentDidMount() {
    this.setSpecialLeague(() => this.getData(this.props.leagueID));

    // Set the interval
    this.intervalId = setInterval(() => {
      // Check if the draftData is live
      if (this.state.draftData && this.state.draftData.is_live) {
        this.getData(this.props.leagueID);
      }
    }, 10000); // 10000 ms = 10 seconds
  }

  componentWillUnmount() {
    // Clear the interval
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({ draftData: '', leagueID: this.props.leagueID }, () => {
        this.setSpecialLeague(() => this.updateContent(this.props.leagueID));
      });
    }
    if (prevProps.product !== this.props.product) {
      this.setState({ product: this.props.product })
    }
    if (prevProps.role !== this.props.role) {
      this.setState({ role: this.props.role })
    }
  }

  updateContent(prop) {
    this.getData(prop);
    this.updateDraftDetail(this.state.selectedPlayer, this.state.selectedPlayerID, prop);
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }

  getData(prop) {
    if (prop) {
      if (this.state.isSpecialLeague == true) {
        axios
          .post('/api/getDraftData', { leagueID: prop, leagueSize: this.state.selectedLeagueSize })
          .then((res) => {
            const response = res.data;
            this.setState({ draftData: response, filteredSummaryData: response.draftSummary}, () => {
              const typeNames = this.getDistinctTypeShortNames();
              
            });
            this.setState({ errorMessage: false })
            //this.initializeTable();
          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
      else {
        axios
          .post('/api/getDraftData', { leagueID: prop })
          .then((res) => {
            const response = res.data;
            this.setState({ draftData: response, filteredSummaryData: response.draftSummary}, () => {
              const typeNames = this.getDistinctTypeShortNames();
              
            });
            this.setState({ errorMessage: false })
            //this.initializeTable();
          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
    }
  }

  updateDraftDetail(selectedPlayer, selectedPlayerID, prop) {
    if (selectedPlayer) {
      if (this.state.isSpecialLeague == true) {
        axios
          .post('/api/getDraftDetail', { playerName: selectedPlayer, playerID: selectedPlayerID, leagueID: prop, leagueSize: this.state.selectedLeagueSize })
          .then((res) => {
            const response = res.data.draftDetail
            this.setState({ draftDetail: response });
            this.setState({ errorMessage: false });
          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
      else {
        axios
          .post('/api/getDraftDetail', { playerName: selectedPlayer, playerID: selectedPlayerID, leagueID: prop })
          .then((res) => {
            const response = res.data.draftDetail
            this.setState({ draftDetail: response });
            this.setState({ errorMessage: false });
          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
    }
  }


  handlePlayerSelect = (event) => {
    const selectedPlayer = event.target.value;
    const selectedPlayerID = event.target.options[event.target.selectedIndex].getAttribute('data-id');
    this.setState({ selectedPlayer, selectedPlayerID });

    if (selectedPlayer) {
      if (this.state.isSpecialLeague == true) {
        axios
          .post('/api/getDraftDetail', { playerName: selectedPlayer, playerID: selectedPlayerID, leagueID: this.props.leagueID, leagueSize: this.state.selectedLeagueSize })
          .then((res) => {
            const response = res.data.draftDetail
            this.setState({ draftDetail: response });
            this.setState({ errorMessage: false })
          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
      else {
        axios
          .post('/api/getDraftDetail', { playerName: selectedPlayer, playerID: selectedPlayerID, leagueID: this.props.leagueID })
          .then((res) => {
            const response = res.data.draftDetail
            this.setState({ draftDetail: response });
            this.setState({ errorMessage: false })
          })
          .catch((error) => this.setState({ errorMessage: true }));
      }
    }
  }

  handleLeagueSizeChange = (event) => {
    this.setState({
      selectedLeagueSize: event.target.value,
    }, () => {
      this.getData(this.props.leagueID)
      this.updateDraftDetail(this.state.selectedPlayer, this.state.selectedPlayerID, this.props.leagueID)
      // You can call the function you want to run here
    });
  }

  createNumbersArray() {
    let numbers = [];
    for (let i = 2; i <= 16; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  setSpecialLeague(callback) {
    const isSpecialLeague = ['9999996', '9999997', '9999998'].includes(this.props.leagueID);
    this.setState({ isSpecialLeague }, callback);
  }

  getDistinctTypeShortNames() {
    if (!this.state.draftData || !this.state.draftData.draftSummary) {
      return [];
    }
    const typeNames = this.state.draftData.draftSummary.map(item => item.type_short_name);
    const distinctTypeNames = [...new Set(typeNames)];
  
    // Set selectedTypes only if it is null
    if (!this.state.selectedTypes || this.state.selectedTypes.size === 0) {
      this.setState({ selectedTypes: new Set(distinctTypeNames) });
    }
  
    return distinctTypeNames;
  }

  handleFilterButtonClick(labelValue, label) {
    const selectedTypes = new Set(this.state.selectedTypes);

    if(label == 'typeFilter') {
      if (selectedTypes.has(labelValue)) {
        selectedTypes.delete(labelValue);
      } else {
        selectedTypes.add(labelValue);
      }}   
  
    const filteredSummaryData = this.state.draftData.draftSummary.filter(item => selectedTypes.has(item.type_short_name));

    this.setState({ selectedTypes, filteredSummaryData });
  }
  
  onColumnHeaderClick = (columnKey) => {
    this.setState((prevState) => {
      let { sortedColumn, sortDirection } = prevState;
      if (sortedColumn === columnKey) {
        if (sortDirection === 'asc') {
          sortDirection = 'desc';
        } else if (sortDirection === 'desc') {
          sortDirection = null;
          sortedColumn = null;
        } else {
          sortDirection = 'asc';
        }
      } else {
        sortedColumn = columnKey;
        sortDirection = 'asc';
      }
      return { sortedColumn, sortDirection };
    });
  }

  toggleDashboardInfo = () => {
    this.setState((prevState) => ({ showDashboardInfo: !prevState.showDashboardInfo }));
  }



  render() {
    const { draftData, selectedPlayer, isSpecialLeague, filteredSummaryData, sortedColumn, sortDirection, showDashboardInfo  } = this.state
    const numbersArray = this.createNumbersArray();

    if (!draftData) {
      return (<div>
        <h2>Draft Room</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>)
    }

    if (this.state.errorMessage & !draftData) {
      return <div><p>There was en error in getting the Draft Room data. Please refresh the page to try again</p></div>;
    }

    const rounds = [...new Set(draftData.choices.map((data) => data.round))]; // Get distinct rounds
    const managers = [...new Set(draftData.choices.map((data) => data.entry_name))]; // Get distinct managers

    const distinctValues = Array.from(new Set(draftData.draftSummary.map((row) => row.type_short_name)));
    distinctValues.sort((a, b) => {
      const order = { GKP: 0, DEF: 1, MID: 2, FWD: 3 };
      return order[a] - order[b];
    });
    //const filteredData = draftData.draftSummary.filter((row) => selectedPosition.has(row.type_short_name));

    const LiveSummaryTable = ({ data }) => {

      const columns = useMemo(
        () => [
          { header: 'Player', accessorKey: 'web_name', size: 100, className: 'content-width' },
          { header: 'Position', accessorKey: 'type_short_name', size: 80, className: 'w-80' },
          { header: 'Team', accessorKey: 'team_short_name', size: 80, className: 'w-80' },
          ...(this.state.isSpecialLeague ? [] : [
            { header: 'Drafted By', accessorKey: 'entry_name', size: 100, className: 'content-width' },
            { header: 'Your Draft Position', accessorKey: 'pick', size: 80, className: 'w-80' },
          ]),
          { header: 'Risk', accessorKey: 'risk', size: 80, className: 'w-80'},
          { header: 'Draft FC Rank', accessorKey: 'dfc_rank', size: 80, className: 'w-80'},
          { header: 'OFPL Draft Rank', accessorKey: 'draft_rank', size: 80, className: 'w-80' },
          { header: 'Global ADP', accessorKey: 'avg_drafted', size: 80, className: 'w-80' },
          { header: 'ADP Rank', accessorKey: 'adp_rank', size: 80, className: 'w-80'},
        ], [this.state.isSpecialLeague])

      const tableData = data.map((item) => ({
        web_name: item.web_name || '-',
        type_short_name: item.type_short_name || '-',
        team_short_name: item.team_short_name || '-',
        entry_name: item.entry_name || '-',
        risk: item.risk || '-',
        pick: item.pick || '-',
        dfc_rank: item.dfc_draft_rank || '-',
        draft_rank: item.draft_rank || '-',
        avg_drafted: item.avg_drafted !== undefined && !isNaN(item.avg_drafted) ? parseFloat(item.avg_drafted).toFixed(1) : '-',
        adp_rank: item.adp_rank !== undefined && !isNaN(item.adp_rank) ? item.adp_rank : '-',
      }))

    const sortedData = [...tableData].sort((a, b) => {
      let aValue = a[sortedColumn];
      let bValue = b[sortedColumn];

      if (aValue === '-' || aValue === Infinity) return 1;
      if (bValue === '-' || bValue === Infinity) return -1;

      // Check if values are numbers
      const aValueIsNumber = !isNaN(parseFloat(aValue));
      const bValueIsNumber = !isNaN(parseFloat(bValue));

      if (aValueIsNumber && bValueIsNumber) {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
      }

      if (!sortedColumn || !sortDirection) return 0;

      if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
      } else {
          return aValue < bValue ? 1 : -1;
      }
  });
  
    return (
      <table>
          <thead>
            <tr>
              {columns.map(column => (
                <th className="onclick" key={column.accessorKey}  onClick={() => this.onColumnHeaderClick(column.accessorKey)}>
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(column => (
                  <td key={column.accessorKey} 
                  className={column.className}>
                    <span className={column.accessorKey === 'type_short_name' ? `${row[column.accessorKey]}` : ''}>{row[column.accessorKey]}</span></td>
                ))}
              </tr>
            ))}
          </tbody>
      </table>
  );
    }

    const typeNames = this.getDistinctTypeShortNames();

    return (
      <div>

        {isSpecialLeague == true &&
          <div className="mb-20">
            <h3 className="mb-10">Select League Size</h3>
            <select
              value={this.state.selectedLeagueSize}
              onChange={this.handleLeagueSizeChange}
            >
              <option value="">Select a league size</option>
              {numbersArray.map((number, index) => (
                <option key={index} value={number}>{number}</option>
              ))}
            </select>
          </div>
        }
        
        <div className="mb-20">
        <h3 className="mb-10">Draft Day Rankings</h3>
          <span className="bold mb-10 purple onclick" onClick={this.toggleDashboardInfo}>
            {showDashboardInfo ? 'Hide dashboard info >' : 'Read dashboard info >'}
          </span>
          {showDashboardInfo && (
            <div>
              <p className="italic mb-10 mt-5">ADP = Global average draft pick in {draftData.league_size} person leagues. If ADP is empty, it means not enough people have drafted that player to get an accurate average. </p>
              <p className="italic mb-10">Draft FC Ranking last updated on {draftData.rank_date}. </p>
              <p className="italic mb-10">Remember! When your draft is happening, the below tables will updated in real time!. </p>
            </div>
          )}
        </div>
        <div className="mb-20">
          <div className="flex flex-wrap g-10 mb-20">
          <div className="filter-wrapper">
            <p className="bold mb-5">Position</p>
                {typeNames.map(typeName => (
                  <button key={typeName} className={this.state.selectedTypes.has(typeName) ? 'button-on' : 'button-off'} onClick={() => this.handleFilterButtonClick(typeName, 'typeFilter')}>
                    {typeName}
                  </button>))}

          </div>
          </div>
          <div className="table-wrapper vh-70"><LiveSummaryTable data={filteredSummaryData} /></div>
        </div>
        <div className=" content-wrapper mb-20">
          <h3>Player Draft Map</h3>
          <select value={selectedPlayer} onChange={this.handlePlayerSelect}>
            <option value="">Select a player</option>
            {draftData.draftSummary.map((data, index) => (
              <option key={index} value={data.web_name} data-id={data.el_id}>{data.web_name + ' (' + data.team_short_name + ')'}</option>
            ))}
          </select>
          {this.state.draftDetail ? (
            <div className="mt-20 vh-50" style={{ width: '100%' }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={this.state.draftDetail}
                  margin={{ top: 0, right: 0, left: 5, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="0 10" />
                  <XAxis dataKey="overall_pick" label={{ value: 'Draft Pick', position: 'insideBottom', dy: 15 }} />
                  <YAxis tickFormatter={(tick) => `${tick}%`} label={{ value: '% Picked', position: 'insideLeft', dx: 0, angle: -90 }} />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Bar dataKey="percent_picked" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : null}
        </div>
        {isSpecialLeague == false && draftData.choices && draftData.choices.length > 0 ?
          <div className="content-wrapper">
            <h3>Draft Day</h3>
            <div className="table-wrapper draft-day">
              <table>
                <thead>
                  <tr>
                    <th>Manager</th>
                    {rounds.map((round) => (
                      <th key={round}>{round}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {managers.map((manager) => (
                    <tr key={manager}>
                      <td className="text-overflow">{manager}</td>
                      {rounds.map((round) => {
                        const data = draftData.choices.find((item) => item.entry_name === manager && item.round === round);
                        return (
                          <td className="maxw-100px minw-100px" key={`${manager}-${round}`}>
                            {data ? <span ><div className="text-overflow">{data.web_name} </div> <div className={data.type_short_name}>{data.type_short_name}</div></span> : ''}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> : null}

      </div>
    );
  }
}

export default DraftRankingLayout;
