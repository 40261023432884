import React from 'react';
import axios from 'axios';
import discordImage from '../../images/discord.png';


class DiscordLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false,
      wp_id: this.props.wp_id,
      placeholder: true,
      discordLink: null
    };
  }

  componentDidMount() {
    if (this.state.placeholder != true) {
    this.getDiscordLink(this.state.wp_id);}
    //this.getRankDates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wp_id !== this.props.wp_id) {
        this.setState({
            wp_id: this.props.wp_id
        }, () => {
          this.getDiscordLink(this.state.wp_id);;
        });
    }
}



  getDiscordLink(wp_id) {
    axios
      .post('/api/getDiscordLink', {wp_id: wp_id})
      .then((res) => {
        this.setState({discordLink : res.data.discordLink})
      })
      .catch((error) => {
        this.setState({errorMessage : true})
        console.error('Error getting Discord Invite:', error);
      });
  }




  render() {
    const { wp_id, discordLink} = this.state;

    if (this.state.placeholder) {
      return (
        <div className="centre-margin">
      <div className="flex column align-center">
        <h2>Join our Discord</h2>
        <img src={discordImage} className="w-80 text-center mb-20" alt="Discord"/>
        <a href="https://discord.gg/KHqKfRazh6"><button  className="w-200">Click to Join</button></a>
        </div></div>)
    }

    if (this.state.errorMessage & !discordLink) {
      return <div><p>There was en error in getting your personalised Discord Link. Please refresh the page to try again</p></div>;
    }

    if (!discordLink) {
      return (
        <div>
       <h2>Getting Personal Discord Invite Link</h2> 
      <div className="skeleton-container">
      <div className="skeleton-header"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-block"></div>
      </div></div>)
    }


    return (
      <div>
      </div>
    );

  }
}

export default DiscordLayout;
