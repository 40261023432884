import React from 'react';
import { DraftRoomConfiguration } from './draftRoomConfiiguration';


class PersonalMenu extends React.Component {

  constructor(props){
    super(props);

  }

  handleClick(component){
    this.props.onChange(component);
  }
  
  render() {

    const buttons = DraftRoomConfiguration.map(obj => {
      const Component = obj.component; // Get the component from the configuration
      return (
        <button onClick={() => this.handleClick(Component)} className="menuItem">{obj.name}</button>
      );
    });

    return (
      <div className="menuWrapper">
        {buttons}
      </div>
    );
  }
}

export default PersonalMenu;
