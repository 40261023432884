import OptimalLineupLayout from "./optimalLineupLayout";
import SuggestedWaiversLayout from "./suggestedWaiversLayout";
import WaiverPlannerLayout from "./waiverPlannerLayout"
import FDRLayout from "./fdrLayout"
import StatsRoom from "./statsRoom";

export const SquadPlannerConfig = [
	{
		name: 'Waiver Planner',
		component: WaiverPlannerLayout,
	},
	{
		name: 'NEW Gameweek Planner',
		component: SuggestedWaiversLayout,
	},
	{
		name: 'NEW Stats Room',
		component: StatsRoom,
	},
	{
		name: 'Optimal Lineups',
		component: OptimalLineupLayout,
	},
	{
		name: 'FDR',
		component: FDRLayout,
	}

];