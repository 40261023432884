import React from 'react';
import axios from 'axios';

class AIRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogCopy: null,
      errorLoading: false,
      loading: false,
      podcastUrl: ''  // State to store the entered URL
    };

    // Bind methods
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUrlChange(event) {
    this.setState({ podcastUrl: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault(); // Prevent default form submission behavior
    this.transcribePodcast(this.state.podcastUrl);
  }

  transcribePodcast(url) {
    this.setState({loading: true})
    axios.post('/api/transcribeAudio', {url: url})
      .then((response) => {
        this.setState({
          errorLoading: false,
          blogCopy: response.data.blog_post,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({errorLoading: true, loading: false});
      });
  }

  render() {
    const {errorLoading, loading, blogCopy} = this.state;

    if(loading){
      return <div><h2>Loading...</h2></div>
    }

    if (errorLoading) {
      return <div><p>Error. Refresh Page</p></div>;
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label>
            Podcast URL:
            <input type="text" value={this.state.podcastUrl} onChange={this.handleUrlChange} />
          </label>
          <button type="submit">Transcribe Podcast</button>
        </form>

        {blogCopy && <div><p>{blogCopy}</p></div>}
      </div>
    );
  }
}

export default AIRequests;
