import React, { Component } from 'react';
import axios from 'axios';

class GWStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      gwResponse: {},
      errorMessage: false
    }
  }
    
  getData() {
    axios.get('/api/getGWStatus')
    .then((response) => {
      const gwResponse = {
        gwStatus: response.data.gwStatus,
        currentGW: response.data.currentGW,
      }
      this.setState({ gwResponse: gwResponse });
      this.setState({errorMessage: false})
      this.props.onCurrentGWChange({gw: gwResponse.currentGW});
    })
    .catch(error => {
      this.setState({errorMessage: true});
    });
  }

  componentDidMount(){
    this.getData();
  }
  
  render() {

    if (this.state.errorMessage & !this.state.gwResponse) {
      return <div><p>Error. Refresh Page</p></div>;
    }

    return (
      <div className={`gw-status-wrapper ${this.state.gwResponse.currentGW === 0 ? 'status-preseason' : ''} ${this.state.gwResponse.gwStatus === 'true' ? 'status-finished' : 'status-live'}`}>
        {this.state.gwResponse.currentGW === 0 ? (
          <span className="preSeason">Pre-season</span>
        ) : (
          <>
            <span className="currentGW">
              GW{this.state.gwResponse.currentGW} -
            </span>
            <span className="gwStatus">
              {this.state.gwResponse.gwStatus === 'true' ? ' Finished' : ' Live'}
            </span>
          </>
        )}
      </div>
    );
  }
}

export default GWStatus;