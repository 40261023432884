import React, { Component } from 'react';
import axios from 'axios';
import Logo from '../../images/main_logo.png';


class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      token: '',
      wp_id: '',
      role: '',
      loginError: false,
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getUserDetail(token) {
    axios.post('/api/getUserRole', {token: token}, {
    })
      .then((response) => {
        localStorage.setItem('wp_id', response.data.id);
        localStorage.setItem('token', token);
        this.props.parentCallback({role : response.data.role, product: response.data.product});
        this.setState({
          token: localStorage.getItem('token'),
          wp_id: localStorage.getItem('wp_id'),
          role: response.data.role,
          product: response.data.product
        })
        window.dispatchEvent(new Event('storage'));
      })
      .catch(function (error) {
        this.setState({loginError: true})
      });
  }

  handleSubmit(event) {
    this.setState({isLoading: true})
    axios.post('https://draftfc.co.uk/wp-json/jwt-auth/v1/token', {
      username: this.state.username,
      password: this.state.password
    })
      .then((response) => {
        this.getUserDetail(response.data.token)
        this.setState({loginError: false, isLoading: false})
        //window.dispatchEvent(new Event('storage'));
      })
      .catch((error) => {
        this.setState({loginError: true, isLoading: false})
      });
    event.preventDefault();
  }


  render() {
    return (
      <div className="middleBlock mt-30">
        <div className="center-content">
          <img className="logo mb-30" src={Logo} />
        </div>
        <div className="formBox">
          <h2>Log in</h2>
          <form onSubmit={this.handleSubmit}>
            <label >
              Email:
              <input className="mb-20 mt-5 grey-border" type="text" name="username" value={this.state.username} onChange={this.handleChange} />
            </label>
            <label >
              Password:
              <input className="mb-20 mt-5 grey-border" type="password" name="password" value={this.state.password} onChange={this.handleChange} />
            </label>
            <div className="mt-10">
              { !this.state.isLoading ? <input type="submit" value="Login" /> : <input type="submit" value="Logging in..." />}
            </div>
          </form>
        </div>
        {this.state.loginError ?
        <div className="mt-30">
        <p>Your email and password combination has not been recognised, please try again</p>
        <p><a href="https://draftfc.co.uk/my-account/lost-password/">I've forgotten my password</a></p>
        </div>:
        null}
      </div>
    );
  }
}

export default LoginForm;