import React from 'react';
import axios from 'axios';
import teamImages from '../../images/shirts/shirtsLookup.js';
import removeTransaction from '../../images/remove_transaction.png';
import trade from '../../images/trade.png';

class SuggestedWaiversLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      wp_id: this.props.wp_id,
      errorMessage: false,
      isSpecialLeague: false,
      gameweekSelectionsData: null,
      currentSelections: null,
      gameweekWaivers: null,
      loading: true,
      scoring: null,
      gw: this.props.gw + 1 ,
      placeholder: false,
      gameweekPlayerDetails: null,
      selectedGW: this.props.gw + 1,
      gameweeks: [], // Initialized as an empty array
      entryID: this.props.entryID,
      gameweekOwnerData: null,
      transactions: [], // New state variable for transactions
      transactionInProgress: false, // Indicates if a transaction is in progress
      selectedElementType: null, // Stores the element type of the player being swapped out
      selectedElementOutId: null, // Stores the el_id of the player being swapped out
    };

    // Binding methods
    this.changeGameweek = this.changeGameweek.bind(this);
    this.setGameweeksList = this.setGameweeksList.bind(this);
    this.getOwnershipData = this.getOwnershipData.bind(this);
    this.getSelectionsData = this.getSelectionsData.bind(this);
    this.getPlayerDetails = this.getPlayerDetails.bind(this);
    this.setSpecialLeague = this.setSpecialLeague.bind(this);
    this.handleRemovePlayer = this.handleRemovePlayer.bind(this);
    this.handleAddPlayer = this.handleAddPlayer.bind(this);
    this.generateUniqueId = this.generateUniqueId.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
    this.revalidateTransactions = this.revalidateTransactions.bind(this);
    this.cancelTransaction = this.cancelTransaction.bind(this);

  }

  componentDidMount() {
    if (!this.state.placeholder) {
      // Correctly invoke functions in callback style
      this.setSpecialLeague(() => {
        this.getOwnershipData(this.props.leagueID, this.state.wp_id);
        this.getSelectionsData(this.props.leagueID, this.state.wp_id);
        this.getPlayerDetails(this.props.leagueID, this.state.wp_id, this.state.gw);
        this.setGameweeksList(this.state.gw);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({ leagueID: this.props.leagueID, globalData: '' }, () => {
        this.setSpecialLeague(() => this.updateContent(this.props.leagueID));
      });
    }

    if (prevProps.entryID !== this.props.entryID) {
      this.setState({ entryID: this.props.entryID });
    }

    // Reapply transactions if selectedGW or transactions have changed
    if (
      prevState.selectedGW !== this.state.selectedGW ||
      prevState.transactions !== this.state.transactions
    ) {
      const updatedData = this.applyTransactionsToOwnerData(
        this.state.gameweekOwnerData,
        this.state.transactions,
        this.state.selectedGW,
        this.state.entryID
      );

      // Update the transactions state if it has changed
      if (this.state.transactions.length !== updatedData.validTransactions.length) {
        this.setState({ transactions: updatedData.validTransactions });
      }
    }

    // Revalidate transactions if gameweekOwnerData has changed
    if (prevState.gameweekOwnerData !== this.state.gameweekOwnerData) {
      const updatedTransactions = this.revalidateTransactions(this.state.transactions);
      if (this.state.transactions.length !== updatedTransactions.length) {
        this.setState({ transactions: updatedTransactions });
      }
    }
  }

  updateContent(prop) {
    this.setState({ leagueID: prop }, () => {
      this.setSpecialLeague(() => {
        const { leagueID, wp_id, gw } = this.state;
        this.getOwnershipData(leagueID, wp_id);
        this.getSelectionsData(leagueID, wp_id);
        this.getPlayerDetails(leagueID, wp_id, gw);
        this.setGameweeksList(gw);
      });
    });
  }

  getOwnershipData(leagueID, wp_id) {
    this.setState({ loading: true });
    if (leagueID) {
      axios
        .post('/api/getGameweekOwnerData', { leagueID, wp_id })
        .then((res) => {
          this.setState({ gameweekOwnerData: res.data, loading: false, errorMessage: false });
        })
        .catch(() => this.setState({ errorMessage: true }));
    }
  }

  getSelectionsData(leagueID, wp_id) {
    this.setState({ loading: true });
    if (leagueID) {
      axios
        .post('/api/getGameweekSelectionsData', { leagueID, wp_id })
        .then((res) => {
          this.setState({ gameweekSelectionsData: res.data, loading: false, errorMessage: false });
        })
        .catch(() => this.setState({ errorMessage: true }));
    }
  }

  getPlayerDetails(leagueID, wp_id, gw) {
    this.setState({ loading: true });
    if (leagueID) {
      axios
        .post('/api/getGameweekPlayerDetails', { leagueID, wp_id, gw, league_size: 2 })
        .then((res) => {
          this.setState({ gameweekPlayerDetails: res.data, loading: false, errorMessage: false });
        })
        .catch(() => this.setState({ errorMessage: true }));
    }
  }

  setGameweeksList(gw) {
    const gameweeks = Array.from({ length: 39 - gw }, (_, i) => i + gw);
    this.setState({ gameweeks });
  }

  setSpecialLeague(callback) {
    const isSpecialLeague = ['9999996', '9999997', '9999998'].includes(this.props.leagueID);
    this.setState({ isSpecialLeague }, callback);
  }

  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
  };

  changeGameweek(event) {
    const selectedGw = parseInt(event.target.value, 10);
    this.setState({ selectedGW: selectedGw }, () => {
      this.getPlayerDetails(this.props.leagueID, this.state.wp_id, selectedGw);

      // Revalidate transactions
      const updatedTransactions = this.revalidateTransactions(this.state.transactions);
      this.setState({ transactions: updatedTransactions });
    });
  }

  generateUniqueId() {
    this.transactionCounter = (this.transactionCounter || 0) + 1;
    return this.transactionCounter;
  }

  handleRemovePlayer(el_id, element_type) {
    const transaction_id = this.generateUniqueId();
    const newTransaction = {
      selectedGW: this.state.selectedGW,
      transaction_id,
      element_out: el_id,
      element_in: null,
    };
    let updatedTransactions = [...this.state.transactions, newTransaction];

    // Revalidate transactions starting from this gameweek
    updatedTransactions = this.revalidateTransactions(updatedTransactions);

    this.setState({
      transactions: updatedTransactions,
      transactionInProgress: true,
      selectedElementType: element_type,
      selectedElementOutId: el_id,
    });
  }

  handleAddPlayer(el_id) {
    const { transactions, selectedGW } = this.state;
    // Find the live transaction (element_in === null)
    const transactionIndex = transactions.findIndex(
      (t) => t.element_in === null && t.selectedGW === selectedGW
    );
    if (transactionIndex !== -1) {
      const updatedTransaction = {
        ...transactions[transactionIndex],
        element_in: el_id,
      };
      let updatedTransactions = [...transactions];
      updatedTransactions[transactionIndex] = updatedTransaction;

      // Revalidate transactions starting from this gameweek
      updatedTransactions = this.revalidateTransactions(updatedTransactions);

      this.setState({
        transactions: updatedTransactions,
        transactionInProgress: false,
        selectedElementType: null,
        selectedElementOutId: null,
      });
    }
  }

  deleteTransaction(transaction_id) {
    const { transactions, selectedElementOutId } = this.state;
    const transactionToDelete = transactions.find(
      (t) => t.transaction_id === transaction_id
    );

    // Remove the transaction
    let updatedTransactions = transactions.filter(
      (t) => t.transaction_id !== transaction_id
    );

    // Check if the deleted transaction is the one in progress
    if (
      transactionToDelete &&
      transactionToDelete.element_out === selectedElementOutId &&
      transactionToDelete.element_in === null
    ) {
      // Reset the transactionInProgress and related states
      this.setState({
        transactions: updatedTransactions,
        transactionInProgress: false,
        selectedElementType: null,
        selectedElementOutId: null,
      });
    } else {
      // Just update the transactions
      this.setState({
        transactions: updatedTransactions,
      });
    }
  }

  cancelTransaction() {
    const { transactions, selectedElementOutId } = this.state;

    // Remove the incomplete transaction
    let updatedTransactions = transactions.filter(
      (t) =>
        !(
          t.element_out === selectedElementOutId &&
          t.element_in === null
        )
    );

    // Reset state variables
    this.setState({
      transactions: updatedTransactions,
      transactionInProgress: false,
      selectedElementType: null,
      selectedElementOutId: null,
    });
  }


  revalidateTransactions(transactions) {
    const { gameweekOwnerData, entryID } = this.state;
    let updatedOwnerData = [...gameweekOwnerData];
    let validTransactions = [];

    // Sort transactions by selectedGW
    const sortedTransactions = transactions.sort((a, b) => a.selectedGW - b.selectedGW);

    sortedTransactions.forEach((transaction) => {
      const { element_out, element_in } = transaction;

      // Check if element_out is in the team
      const hasElementOut = updatedOwnerData.some(
        (player) =>
          player.el_id === element_out && parseInt(player.entry_id, 10) === parseInt(entryID, 10)
      );

      if (!hasElementOut) {
        // Skip this transaction
        return;
      }

      // Transaction is valid; add to validTransactions
      validTransactions.push(transaction);

      // Remove element_out from owner's players
      updatedOwnerData = updatedOwnerData.filter(
        (player) =>
          !(player.el_id === element_out && parseInt(player.entry_id, 10) === parseInt(entryID, 10))
      );

      // Add element_in to owner's players
      if (element_in !== null) {
        // Find player in gameweekOwnerData
        const playerToAdd = gameweekOwnerData.find((player) => player.el_id === element_in);
        if (playerToAdd) {
          // Add player to updatedOwnerData with entry_id set to entryID
          const newPlayer = { ...playerToAdd, entry_id: entryID };
          updatedOwnerData.push(newPlayer);
        }
      }
    });

    return validTransactions;
  }

  applyTransactionsToOwnerData(gameweekOwnerData, transactions, selectedGW, entryID) {
    // Start with a copy of gameweekOwnerData
    let updatedOwnerData = [...gameweekOwnerData];

    // Sort transactions by selectedGW to ensure they're applied in order
    const sortedTransactions = transactions.sort((a, b) => a.selectedGW - b.selectedGW);

    // Keep track of valid transactions and pending removals
    const validTransactions = [];
    const pendingRemovalIds = new Set();

    // Apply transactions that are effective up to the selected gameweek
    sortedTransactions.forEach((transaction) => {
      if (transaction.selectedGW <= selectedGW) {
        const { element_out, element_in } = transaction;

        // Check if element_out is in the team
        const hasElementOut = updatedOwnerData.some(
          (player) =>
            player.el_id === element_out && parseInt(player.entry_id, 10) === parseInt(entryID, 10)
        );

        if (!hasElementOut) {
          // Skip this transaction
          return;
        }

        // Transaction is valid; add to validTransactions
        validTransactions.push(transaction);

        if (element_in === null) {
          // Transaction is incomplete; mark element_out as pending removal
          pendingRemovalIds.add(element_out);
          // Do not remove element_out from updatedOwnerData yet
        } else {
          // Remove element_out from owner's players
          updatedOwnerData = updatedOwnerData.filter(
            (player) =>
              !(
                player.el_id === element_out && parseInt(player.entry_id, 10) === parseInt(entryID, 10)
              )
          );

          // Add element_in to owner's players
          // Find player in gameweekOwnerData
          const playerToAdd = gameweekOwnerData.find((player) => player.el_id === element_in);
          if (playerToAdd) {
            // Add player to updatedOwnerData with entry_id set to entryID
            const newPlayer = { ...playerToAdd, entry_id: entryID };
            updatedOwnerData.push(newPlayer);
          }
        }
      }
    });

    // Now separate updatedOwnerData into ownPlayers and otherPlayers
    const ownPlayers = [];
    const otherPlayers = [];

    updatedOwnerData.forEach((player) => {
      if (parseInt(player.entry_id, 10) === parseInt(entryID, 10)) {
        // Check if player is pending removal
        const isPendingRemoval = pendingRemovalIds.has(player.el_id);
        ownPlayers.push({ ...player, isPendingRemoval });
      } else {
        otherPlayers.push(player);
      }
    });

    return { ownPlayers, otherPlayers, validTransactions };
  }


  render() {
    const {
      isSpecialLeague,
      loading,
      errorMessage,
      placeholder,
      gameweeks,
      gameweekOwnerData,
      gameweekPlayerDetails,
      entryID,
      transactionInProgress,
      selectedElementType,
      selectedGW,
    } = this.state;

    if (placeholder) {
      return (
        <div>
          <h2>Waiver Suggestions will be replaced by our NEW Gameweek Planner!</h2>
          <p>
            We have made major upgrades to our prediction model, and are finalising this before
            launching the Gameweek Planner.
          </p>
          <p>
            This will feature a pitch view where you can simulate waivers and trades for every
            gameweek in the season to see what your team would look like in any scenario.
          </p>
          <p>
            This tool will be driven by our upgraded AI powered xPts model to give you the ultimate
            edge in your league.
          </p>
        </div>
      );
    }

    if (loading) {
      return (
        <div>
          <h2>Waiver Suggestion Engine</h2>
          <div className="skeleton-container">
            <div className="skeleton-header"></div>
            <div className="skeleton-paragraph"></div>
            <div className="skeleton-block"></div>
          </div>
        </div>
      );
    }

    if (isSpecialLeague) {
      return (
        <div>
          <h2>This feature is available if you have synced a FPL Draft Team ID</h2>
          <p>Please select a FPL Draft League, or go to 'Add New Leagues' to sync a new league.</p>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div>
          <h3>There was an error loading the data. Please refresh the page.</h3>
        </div>
      );
    }

    // Define the OtherPlayersTable component
const OtherPlayersTable = ({
  data,
  transactionInProgress,
  selectedElementType,
  handleAddPlayer,
  getTeamImage,
}) => {
  const [searchText, setSearchText] = React.useState('');
  const [startingFilter, setStartingFilter] = React.useState('All');

  // Get the distinct values for starting
  const startingOptions = React.useMemo(() => {
    const optionsSet = new Set(
      data.map((player) => (player.starting == 1 ? 'Y' : 'N'))
    );
    return ['All', ...Array.from(optionsSet)];
  }, [data]);

  // Prepare the columns
  let columns = [
    { header: 'Player Name', accessorKey: 'web_name', size: 150 },
    { header: 'In Lineup', accessorKey: 'starting', size: 150 },
    { header: 'Opponent', accessorKey: 'opp_team_short_name', size: 150 },
    { header: 'xPts', accessorKey: 'expected_fpl_points', size: 100 },
    { header: 'Rostered', accessorKey: 'percent_picked', size: 100 },
    { header: '% Win', accessorKey: 'percent_win', size: 100 },
    { header: 'Manager', accessorKey: 'short_name', size: 100 },
    { header: '', accessorKey: 'add_button', size: 50 },
  ];

  // Prepare tableData
  let tableData;

  if (transactionInProgress) {
    // Filter data by selectedElementType
    data = data.filter((player) => player.element_type === selectedElementType);

    tableData = data.map((player) => ({
      add_button: (
        <img
          src={trade}
          className="add-transaction-button onclick"
          alt="Add Player"
          onClick={() => handleAddPlayer(player.el_id)}
        />
      ),
      web_name: (
        <div className="player-name flex align-center g-5">
          {getTeamImage(player.team)}
          <span className="ml-2">{player.web_name}</span>
        </div>
      ),
      web_name_text: player.web_name,
      expected_fpl_points: player.expected_fpl_points
        ? Number(player.expected_fpl_points).toFixed(1)
        : '0.0',
      percent_picked: player.percent_picked
        ? `${parseInt(player.percent_picked * 100)}%`
        : '0%',
      percent_win: player.percent_win ? `${parseInt(player.percent_win)}%` : '0%',
      short_name: player.short_name || '',
      opp_team_short_name:
        player.opp_team_short_name && player.h_a
          ? `${player.opp_team_short_name} (${player.h_a})`
          : '-',
      starting: player.starting == 1 ? 'Y' : 'N',
    }));
  } else {
    tableData = data.map((player) => ({
      add_button: (
        <img
          src=""
          className="placeholder-image"
          alt="Placeholder"
        />
      ),
      web_name: (
        <div className="player-name flex align-center g-5">
          {getTeamImage(player.team)}
          <span className="ml-2">{player.web_name}</span>
        </div>
      ),
      web_name_text: player.web_name,
      expected_fpl_points: player.expected_fpl_points
        ? Number(player.expected_fpl_points).toFixed(1)
        : '0.0',
      percent_picked: player.percent_picked
        ? `${parseInt(player.percent_picked * 100)}%`
        : '0%',
      percent_win: player.percent_win ? `${parseInt(player.percent_win)}%` : '0%',
      short_name: player.short_name || '',
      opp_team_short_name:
        player.opp_team_short_name && player.h_a
          ? `${player.opp_team_short_name} (${player.h_a})`
          : '-',
      starting: player.starting == 1 ? 'Y' : 'N',
    }));
  }

  // Apply filters to tableData
  const filteredData = tableData.filter((player) => {
    const matchesSearchText =
      player.web_name_text.toLowerCase().includes(searchText.toLowerCase());
    const matchesStarting =
      startingFilter === 'All' || player.starting === startingFilter;
    return matchesSearchText && matchesStarting;
  });

  // Sorting
  const [sortedColumn, setSortedColumn] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState(null);

  const onColumnHeaderClick = (columnKey) => {
    if (sortedColumn === columnKey) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortDirection(null);
        setSortedColumn(null);
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortedColumn(columnKey);
      setSortDirection('asc');
    }
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!sortedColumn || !sortDirection) return 0;

    let aValue = a[sortedColumn];
    let bValue = b[sortedColumn];

    if (sortedColumn === 'web_name') {
      aValue = a['web_name_text'].toLowerCase();
      bValue = b['web_name_text'].toLowerCase();
    } else {
      // Handle values that are React elements
      if (React.isValidElement(aValue)) {
        aValue = aValue.props.children;
        bValue = bValue.props.children;
      }
    }

    if (aValue === '-' || aValue === Infinity) return 1;
    if (bValue === '-' || bValue === Infinity) return -1;

    // Check if values are numbers
    const aValueIsNumber = !isNaN(parseFloat(aValue));
    const bValueIsNumber = !isNaN(parseFloat(bValue));

    if (aValueIsNumber && bValueIsNumber) {
      aValue = parseFloat(aValue);
      bValue = parseFloat(bValue);
    }

    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  return (
    <div>
      {/* Render filters */}
      <div className="filters mb-10 flex g-5 p-10 space-between align-center">
        <div className="filter-wrapper w-200">
        <input
          type="text"
          placeholder="Search Player Name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="p-5 w-200 select"
        />
        </div>
        <div className="filter-wrapper p-5">
          <label htmlFor="startingFilter" className="p-5 bold">
            Starting:
          </label>
          <select
            id="startingFilter"
            value={startingFilter}
            onChange={(e) => setStartingFilter(e.target.value)}
            className="grey-border"
          >
            {startingOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

      </div>
      {/* Render the table */}
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                className="onclick"
                key={column.accessorKey}
                onClick={() => onColumnHeaderClick(column.accessorKey)}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td key={column.accessorKey}>{row[column.accessorKey]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


    // Create a mapping from el_id to player details for quick lookup
    const playerDetailsMap = {};
    if (gameweekPlayerDetails) {
      gameweekPlayerDetails.forEach((player) => {
        playerDetailsMap[player.el_id] = player;
      });
    }

    // Apply transactions to gameweekOwnerData to get updated ownPlayers and otherPlayers
    let ownPlayers = [];
    let otherPlayers = [];

    if (gameweekOwnerData) {
      const updatedData = this.applyTransactionsToOwnerData(
        gameweekOwnerData,
        this.state.transactions,
        selectedGW,
        entryID
      );
      ownPlayers = updatedData.ownPlayers;
      otherPlayers = updatedData.otherPlayers;
    }

    // Prepare otherPlayersWithDetails by attaching player details
    const otherPlayersWithDetails = otherPlayers.map((player) => {
      const details = playerDetailsMap[player.el_id] || {};
      return {
        ...player,
        expected_fpl_points: details.expected_fpl_points || 0,
        percent_picked: details.percent_picked || 0,
        percent_win: details.percent_win || 0,
        opp_team_short_name: details.opp_team_short_name || '-',
        h_a: details.h_a || '',
        starting: details.starting || 0
      };
    });

    // Proceed only if we have ownPlayers and playerDetailsMap
    if (ownPlayers.length > 0 && Object.keys(playerDetailsMap).length > 0) {
      // Attach expected_fpl_points to ownPlayers
      const ownPlayersWithPoints = ownPlayers.map((player) => {
        const details = playerDetailsMap[player.el_id] || {};
        const expected_points = details.expected_fpl_points || 0;
        const opp_team_short_name = details.opp_team_short_name || '-';
        const h_a = details.h_a || '-';
        const starting = details.starting || 0;
        return {
          ...player,
          expected_fpl_points: expected_points,
          opp_team_short_name: opp_team_short_name,
          h_a: h_a,
          starting: starting
        };
      });

      // Separate players into positions and sort them
      const gk_players = ownPlayersWithPoints
        .filter((player) => player.element_type === 1)
        .sort((a, b) => b.expected_fpl_points - a.expected_fpl_points);

      const def_players = ownPlayersWithPoints
        .filter((player) => player.element_type === 2)
        .sort((a, b) => b.expected_fpl_points - a.expected_fpl_points);

      const mid_players = ownPlayersWithPoints
        .filter((player) => player.element_type === 3)
        .sort((a, b) => b.expected_fpl_points - a.expected_fpl_points);

      const fwd_players = ownPlayersWithPoints
        .filter((player) => player.element_type === 4)
        .sort((a, b) => b.expected_fpl_points - a.expected_fpl_points);

      // Define valid combinations
      const validCombinations = [
        { DEF: 3, MID: 4, FWD: 3 },
        { DEF: 3, MID: 5, FWD: 2 },
        { DEF: 4, MID: 3, FWD: 3 },
        { DEF: 4, MID: 4, FWD: 2 },
        { DEF: 4, MID: 5, FWD: 1 },
        { DEF: 5, MID: 2, FWD: 3 },
        { DEF: 5, MID: 3, FWD: 2 },
        { DEF: 5, MID: 4, FWD: 1 },
      ];

      let bestCombination = null;
      let maxTotalPoints = -1;

      validCombinations.forEach((combo) => {
        const num_DEF = combo.DEF;
        const num_MID = combo.MID;
        const num_FWD = combo.FWD;
        const num_GK = 1;

        if (
          gk_players.length >= num_GK &&
          def_players.length >= num_DEF &&
          mid_players.length >= num_MID &&
          fwd_players.length >= num_FWD
        ) {
          const startingGK = gk_players.slice(0, num_GK);
          const startingDEF = def_players.slice(0, num_DEF);
          const startingMID = mid_players.slice(0, num_MID);
          const startingFWD = fwd_players.slice(0, num_FWD);

          const startingPlayers = [
            ...startingGK,
            ...startingDEF,
            ...startingMID,
            ...startingFWD,
          ];

          const totalPoints = startingPlayers.reduce(
            (sum, player) => sum + player.expected_fpl_points,
            0
          );

          if (totalPoints > maxTotalPoints) {
            maxTotalPoints = totalPoints;
            bestCombination = {
              GK: startingGK,
              DEF: startingDEF,
              MID: startingMID,
              FWD: startingFWD,
              totalPoints: totalPoints,
              combo: combo,
            };
          }
        }
      });

      // If we found a valid combination
      if (bestCombination) {
        const startingPlayers = [
          ...bestCombination.GK,
          ...bestCombination.DEF,
          ...bestCombination.MID,
          ...bestCombination.FWD,
        ];

        const startingPlayerIds = new Set(startingPlayers.map((player) => player.el_id));

        const managerName = startingPlayers.length > 0 ? startingPlayers[0].entry_name : 'Unknown Manager';

        const subPlayers = ownPlayersWithPoints
          .filter((player) => !startingPlayerIds.has(player.el_id))
          .sort((a, b) => {
            if (a.element_type === 1 && b.element_type !== 1) return -1;
            if (a.element_type !== 1 && b.element_type === 1) return 1;
            return 0;
          });

        // Now you can render the lineup
        return (
          <div>

            {/*Top bar*/}
            <div className="mb-20 text-center">
              {/* Gameweek Selector */}
                <select value={selectedGW} onChange={this.changeGameweek} className="grey-border">
                  {gameweeks.map((week) => (
                    <option key={week} value={week}>
                      Gameweek {week}
                    </option>
                  ))}
                </select>
            </div>

            <div className="flex flex-wrap g-20 flex-center mb-20">
              {/* Lineup Section */}
              <div>
                <div className="black-bg flex space-between p-10 mb-10 br-8">
                  <h4 className="white mb-0 font-weight-700">{managerName}</h4>
                  <h4 className="white mb-0 font-weight-700">{maxTotalPoints.toFixed(1)} xPts</h4>
                </div>

                <div
                  id="lineup"
                  className="live-gw_details text-center flex g-20 flex-wrap center-content space-around"
                >
                  <div
                    key={entryID}
                    className="entry-card flex space-between column"
                    style={{
                      backgroundImage:
                        'url(https://draftfc.co.uk/wp-content/uploads/pitch.png)',
                      backgroundSize: 'cover',
                    }}
                  >
                    {/* Render starting players grouped by position */}
                    <div>
                      <div className="player-row flex space-evenly mt-10">
                        {/* Goalkeeper */}
                        {bestCombination.GK.map((player) => (
                          <div key={player.el_id} className={`player-single flex-grow-1 ${player.isPendingRemoval ? 'temp-remove' : ''}`}>

                            <div className="player-shirt-container">
                              {!transactionInProgress && (
                                <img src={removeTransaction} className="cancel-transaction-button onclick" alt="Remove Player" onClick={() =>
                                  this.handleRemovePlayer(player.el_id, player.element_type)
                                } />
                              )}
                              {this.getTeamImage(player.team)}
                            </div>

                            <div className="player_element_name text-overflow">
                              {player.web_name}
                            </div>
                            <div className="player_element_middle text-overflow">
                              {player.opp_team_short_name + ' (' + player.h_a + ')'}
                            </div>
                            <div className="player_element_points" style={{color: player.starting != 1 ? 'red' : 'inherit',}}>
                              {Number(player.expected_fpl_points).toFixed(1)}{' '}
                            </div>
                            {transactionInProgress && player.el_id === this.state.selectedElementOutId && (
                              <button onClick={this.cancelTransaction} className="cancel-transaction">Cancel</button>
                            )}
                          </div>
                        ))}
                      </div>

                      {/* Defenders */}
                      <div className="player-row flex space-evenly mb-20 mt-10">
                        {bestCombination.DEF.map((player) => (
                          <div key={player.el_id} className={`player-single flex-grow-1 ${player.isPendingRemoval ? 'temp-remove' : ''}`}>
                            <div className="player-shirt-container">
                              {!transactionInProgress && (
                                <img src={removeTransaction} className="cancel-transaction-button onclick" alt="Remove Player" onClick={() =>
                                  this.handleRemovePlayer(player.el_id, player.element_type)
                                } />
                              )}
                              {this.getTeamImage(player.team)}
                            </div>
                            <div className="player_element_name text-overflow">
                              {player.web_name}
                            </div>
                            <div className="player_element_middle text-overflow">
                              {player.opp_team_short_name + ' (' + player.h_a + ')'}
                            </div>
                            <div className="player_element_points" style={{color: player.starting != 1 ? 'red' : 'inherit',}}>
                              {Number(player.expected_fpl_points).toFixed(1)}{' '}
                            </div>
                            {transactionInProgress && player.el_id === this.state.selectedElementOutId && (
                              <button onClick={this.cancelTransaction} className="cancel-transaction">Cancel</button>
                            )}
                          </div>
                        ))}
                      </div>

                      {/* Midfielders */}
                      <div className="player-row flex space-evenly mb-20 mt-10">
                        {bestCombination.MID.map((player) => (
                          <div key={player.el_id} className={`player-single flex-grow-1 ${player.isPendingRemoval ? 'temp-remove' : ''}`}>
                            <div className="player-shirt-container">
                              {!transactionInProgress && (
                                <img src={removeTransaction} className="cancel-transaction-button onclick" alt="Remove Player" onClick={() =>
                                  this.handleRemovePlayer(player.el_id, player.element_type)
                                } />
                              )}
                              {this.getTeamImage(player.team)}
                            </div>
                            <div className="player_element_name text-overflow">
                              {player.web_name}
                            </div>
                            <div className="player_element_middle text-overflow">
                              {player.opp_team_short_name + ' (' + player.h_a + ')'}
                            </div>
                            <div className="player_element_points" style={{color: player.starting != 1 ? 'red' : 'inherit',}}>
                              {Number(player.expected_fpl_points).toFixed(1)}{' '}
                            </div>
                            {transactionInProgress && player.el_id === this.state.selectedElementOutId && (
                              <button onClick={this.cancelTransaction} className="cancel-transaction">Cancel</button>
                            )}
                          </div>
                        ))}
                      </div>

                      {/* Forwards */}
                      <div className="player-row flex space-evenly mb-20 mt-10">
                        {bestCombination.FWD.map((player) => (
                          <div key={player.el_id} className={`relative player-single flex-grow-1 ${player.isPendingRemoval ? 'temp-remove' : ''}`}>
                            <div className="player-shirt-container">
                              {!transactionInProgress && (
                                <img src={removeTransaction} className="cancel-transaction-button onclick" alt="Remove Player" onClick={() =>
                                  this.handleRemovePlayer(player.el_id, player.element_type)
                                } />
                              )}
                              {this.getTeamImage(player.team)}
                            </div>
                            <div className="player_element_name text-overflow">
                              {player.web_name}
                            </div>
                            <div className="player_element_middle text-overflow">
                              {player.opp_team_short_name + ' (' + player.h_a + ')'}
                            </div>
                            <div className="player_element_points" style={{color: player.starting != 1 ? 'red' : 'inherit',}}>
                              {Number(player.expected_fpl_points).toFixed(1)}{' '}
                            </div>
                            {transactionInProgress && player.el_id === this.state.selectedElementOutId && (
                              <button onClick={this.cancelTransaction} className="cancel-transaction">Cancel</button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Substitute Players */}
                    <div className="sub-players player-row flex space-evenly mt-10">
                        {subPlayers.map((player) => (
                          <div key={player.el_id} className={`relative player-single flex-grow-1 ${player.isPendingRemoval ? 'temp-remove' : ''}`}>
                            <div className="player-shirt-container">
                              {!transactionInProgress && (
                                <img src={removeTransaction} className="cancel-transaction-button onclick" alt="Remove Player" onClick={() =>
                                  this.handleRemovePlayer(player.el_id, player.element_type)
                                } />
                              )}
                              {this.getTeamImage(player.team)}
                            </div>
                            <div className="player_element_name text-overflow">
                              {player.web_name}
                            </div>
                            <div className="player_element_middle text-overflow">
                              {player.opp_team_short_name + ' (' + player.h_a + ')'}
                            </div>
                            <div className="player_element_points" style={{color: player.starting != 1 ? 'red' : 'inherit',}}>
                              {Number(player.expected_fpl_points).toFixed(1)}{' '}
                            </div>
                            {transactionInProgress && player.el_id === this.state.selectedElementOutId && (
                              <button onClick={this.cancelTransaction} className="cancel-transaction">Cancel</button>
                            )}
                          </div>
                        ))}
                      </div>
                  </div>
                </div>
              </div>

              {/* Other Managers' Players Section */}
                <div className="table-wrapper vh-70">
                <div className="table-wrapper vh-70">
  <OtherPlayersTable
    data={otherPlayersWithDetails}
    transactionInProgress={this.state.transactionInProgress}
    selectedElementType={this.state.selectedElementType}
    handleAddPlayer={this.handleAddPlayer}
    getTeamImage={this.getTeamImage}
  />
</div>
                </div>
              </div>
            {/* Transactions Section */}
            <div >
              <h4 className="text-center mb-20">Your Transactions</h4>
              <div className="flex flex-wrap g-20 flex-center">
              {this.state.transactions.map((transaction) => {
                // Retrieve player details using playerDetailsMap
                const elementOutDetails = playerDetailsMap[transaction.element_out];
                const elementInDetails = playerDetailsMap[transaction.element_in];

                return (
                  <div key={transaction.transaction_id} className=" br-8 grey-border p-10">
                    <div className="flex column align-center">
                      <div>
                        <span>GW {transaction.selectedGW}: </span>
                        <button className ="font-12 p-5" onClick={() => this.deleteTransaction(transaction.transaction_id)}>
                        Delete
                        </button>
                      </div>
                      <div className="player-row flex space-evenly mb-20 mt-10 g-20">
                        <div className="flex column align-center">

                          <div><strong>Out:</strong></div>
                          {elementOutDetails ? (
                            <div className="flex column align-center w-80">
                              <span className="player-shirt-container text-center">
                                {this.getTeamImage(elementOutDetails.team)}
                              </span>
                              <span className="player_element_name text-overflow text-center white-bg fullwidth">
                                {elementOutDetails.web_name}
                              </span>
                              <span className="player_element_middle text-overflow text-center white-bg fullwidth">
                                {elementOutDetails.opp_team_short_name + ' (' + elementOutDetails.h_a + ')'}
                              </span>
                              <span className="player_element_points text-center white-bg fullwidth" style={{color: elementOutDetails.starting != 1 ? 'red' : 'inherit',}}>
                                {Number(elementOutDetails.expected_fpl_points).toFixed(1)}{' '}
                              </span> </div>
                          ) : ''}</div>

                        <div className="flex column align-center">
                        <div><strong>In:</strong></div>
                        {elementInDetails ? (
                            <div className="flex column align-center w-80">
                              <span className="player-shirt-container text-center">
                                {this.getTeamImage(elementInDetails.team)}
                              </span>
                              <span className="player_element_name text-overflow text-center white-bg fullwidth">
                                {elementInDetails.web_name}
                              </span>
                              <span className="player_element_middle text-overflow text-center white-bg fullwidth">
                                {elementInDetails.opp_team_short_name + ' (' + elementInDetails.h_a + ')'}
                              </span>
                              <span className="player_element_points text-center white-bg fullwidth" style={{color: elementInDetails.starting != 1 ? 'red' : 'inherit',}}>
                                {Number(elementInDetails.expected_fpl_points).toFixed(1)}{' '}
                              </span> </div>
                          ) : 'Pending'}
                        </div>
                      </div> </div></div>
                );
              })}
            </div></div>


          </div>
        );
      } else {
        // No valid combination found
        return (
          <div>
            <h3>No valid lineup could be created with your current players.</h3>
          </div>
        );
      }
    } else {
      // Data not loaded yet
      return (
        <div>
          <h3>Loading player data...</h3>
        </div>
      );
    }
  }

}

export default SuggestedWaiversLayout;
