import TotalPointsChart from "./totalPointsTable";
import RunningPointsChart from "./runningPointsTable";
import HowPointsScoredChart from "./howPointsScoredTable";
import PointsPositionChart from "./pointsPositionTable";
import SubPointsChart from "./subPointsTable";
import TreemapChart from "./treemapTable";
import PersonalGWTable from "./personalGWTable";


export const personalChartConfig = [
	{
		name: 'Total Points',
		component: TotalPointsChart,
	},
	{
		name: 'Running Points',
		component: RunningPointsChart,
	},
	{
		name: 'Start v Sub',
		component: SubPointsChart,
	},
	{
		name: 'Treemap',
		component: TreemapChart,
	},
	{
		name: 'Points By Position',
		component: PointsPositionChart,
	},
	{
		name: 'How Points Scored',
		component: HowPointsScoredChart,
	},
	{
		name: 'Gameweeks',
		component: PersonalGWTable,
	}

];