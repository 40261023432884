import React from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { CSVLink } from 'react-csv';
import { FilterList } from '@mui/icons-material';

class TreemapChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal: this.props.personalData,
      selectedMonth: new Set(['August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May']),
      viewToggle: 'table'
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ personal: this.props.personalData })
    }
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const { selectedMonth } = this.state;

    if (selectedMonth.has(name)) {
      selectedMonth.delete(name);
    } else {
      selectedMonth.add(name);
    }

    this.setState({ selectedMonth });
  };

  handleTableClick = () => {
    this.setState({ viewToggle: 'table' });
  }

  handleChartClick = () => {
    this.setState({ viewToggle: 'chart' });
  }

  toggleFilter = () => {
    !this.state.filter ? this.setState({ filter: true }) : this.setState({ filter: false })
  }

  render() {
    const { personal, selectedMonth } = this.state

    if (!personal) {
      return <div>loading...</div>;
    }

    const months = [...new Set(personal.personalTreemap.map((data) => data.gw_month))]; // Get distinct rounds
    const sortedMonths = months
      .map(m => [new Date(`${m} 1, 2000`).getMonth(), m])
      .sort((a, b) => (a[0] < 7 ? a[0] + 12 : a[0]) - (b[0] < 7 ? b[0] + 12 : b[0]))
      .map(([_, m]) => m);

    const filteredData = personal.personalTreemap.filter((row) => selectedMonth.has(row.gw_month));

    const chartData = Object.values(filteredData.reduce((acc, curr) => {
      const { entry_id, entry_name, colour, el_id, web_name, fixture_total_points } = curr;
      const key = `${entry_id}-${entry_name}-${colour}-${el_id}-${web_name}`;
      if (!acc[key]) {
        acc[key] = {
          entry_id,
          entry_name,
          colour,
          el_id,
          web_name,
          fixture_total_points: 0
        };
      }
      acc[key].fixture_total_points += fixture_total_points;
      return acc;
    }, {})).sort((a, b) => b.fixture_total_points - a.fixture_total_points);

    const managers = [...new Set(filteredData.map((data) => data.entry_name))]; // Get distinct managers
    const managerPoints = managers.map(manager => ({
      entry_name: manager,
      fixture_total_points: filteredData
        .filter(data => data.entry_name === manager)
        .reduce((acc, data) => acc + data.fixture_total_points, 0)
    }));
    const sortedManagers = managerPoints.sort((a, b) => b.fixture_total_points - a.fixture_total_points)
      .map(manager => manager.entry_name);

    const headers = [
      { label: 'Manager', key: 'manager' },
      {label: 'Player', key :'web_name'},
      ...sortedMonths.map((gw_month) => ({ label: gw_month, key: gw_month })),
    ];

    const csvData = [];
    sortedManagers.forEach((manager) => {
      // Extract unique web_names for the current manager
      const webNamesForManager = [...new Set(personal.personalTreemap.filter(item => item.entry_name === manager).map(item => item.web_name))];
    
      webNamesForManager.forEach((web_name) => {
        const rowData = { manager, web_name };
    
        sortedMonths.forEach((gw_month) => {
          // Filter data by manager, month, and web_name
          const filteredData = personal.personalTreemap.filter((item) => item.entry_name === manager && item.gw_month === gw_month && item.web_name === web_name);
    
          // Sum the fixture_total_points for the filtered data
          const sum = filteredData.reduce((accumulator, currentValue) => accumulator + currentValue.fixture_total_points, 0);
          
          rowData[gw_month] = sum || 0;
        });
    
        csvData.push(rowData);
      });
    });
    


    let groupedData = [];
    chartData.forEach(item => {
      const entry = groupedData.find(e => e.entry_id === item.entry_id);
      if (entry) {
        entry.children.push(item);
      } else {
        groupedData.push({
          entry_id: item.entry_id,
          children: [item]
        });
      }
    });

    // Then pass this groupedData to the Treemap component as data.


    const CustomizedContent = (props) => {
      const { x, y, width, height, value, web_name, colour } = props;

      const textSize = 10; // Assuming font size for the text
      const textHeight = textSize * 1.2;
      const nameLength = web_name ? web_name.length : 0;
      const textWidth = Math.max(nameLength, String(value).length) * textSize * 0.6; // Rough estimate

      const yOffset = 16; 

  if (textWidth > width || textHeight * 2 > height) {
    return (
      <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: colour, // Use the colour attribute from data
              stroke: '#fff',
              strokeWidth: 1,
            }}
          />
        </g>
    ); // Don't render the text if it's too large for the cell
  }
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: colour, // Use the colour attribute from data
              stroke: '#fff',
              strokeWidth: 1,
            }}
          />
          <text
        x={x + width / 2}
        y={y + height / 2 - yOffset/2}
        textAnchor="middle"
        fill="#fff"
        fontSize={10}
        fontWeight="400"
      >
        {web_name}
      </text>
      <text
        x={x + width / 2}
        y={y + height / 2 + yOffset/2}
        textAnchor="middle"
        fill="#fff"
        fontSize={12}
      >
        {value}
      </text>
        </g>
      );
    };

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="mb-5">{`Player: ${payload[0].payload.web_name}`}</p>
            <p className="mb-0">{`Points: ${payload[0].value}`}</p>
          </div>
        );
      }
      return null;
    };


    return (
      <div>
        <div className="content-wrapper mb-10">
          <div className="flex flex-wrap space-between mb-20 align-center g-10">
            <h3 className="mb-0">Treemap</h3>
            <div className="chart-buttons flex flex-wrap space-between align-center g-20">
            <button className="show-desktop download-data"><CSVLink className="download-link" data={csvData} headers={headers} filename={'totalPoints_data.csv'}>Download Data</CSVLink></button>
          <div className="text-right">
            <button className="filter" onClick={this.toggleFilter}><FilterList/></button>
            {!this.state.filter ? null :
              <div className="filter-container flex column g-5 mb-10 text-left ">
                {sortedMonths.map((value, index) => (
                  <label className="text-overflow" key={index}>
                    <input
                      type="checkbox"
                      name={value}
                      checked={selectedMonth.has(value)}
                      onChange={this.handleCheckboxChange}
                      />
                    {value}
                  </label>
                ))}
              </div>}</div>
          </div>
          </div>

          <div className="mt-20 vh-50" style={{ width: '100%' }}>
            <ResponsiveContainer width="100%" height="100%">
              <Treemap
                data={groupedData}
                dataKey="fixture_total_points"
                nameKey="el_id"
                ratio={4 / 3}
                stroke="#fff"
                fill="#8884d8"
                content={<CustomizedContent />}
                isAnimationActive={false} 
              >
                <Tooltip content={<CustomTooltip />} />
              </Treemap>
            </ResponsiveContainer>

          </div>
        </div>
        <button className="show-mobile mb-20 download-data fullwidth"><CSVLink className="download-link" data={csvData} headers={headers} filename={'totalPoints_data.csv'}>Download Data</CSVLink></button>
        <div className="br-16 p-20 grey-bg flex flex-wrap flex-start g-10">
          {sortedManagers.map((manager) => {
            const managerData = personal.personalTreemap.find((data) => data.entry_name === manager);
            return (
              <span key={manager} className="p-5">
                <span className="circle" style={{ background: managerData.colour }}></span>
                {manager}
              </span>
            );
          })}</div>

      </div>
    );
  }
}

export default TreemapChart;
