import React from 'react';
import InputDraftRankDash from './inputDraftRankDash';
import MitchRoomMenu from './mitchRoomMenu';


class MitchRoomLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponent: InputDraftRankDash,
      gw: this.props.gw,
      wp_id: this.props.wp_id,
      currentSeason: this.props.currentSeason
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
  }


  onChange(component) {
    this.setState({ selectedComponent: component });
  }


  render() {
    const {selectedComponent, gw, wp_id, currentSeason} = this.state;
    const SelectedComponent = selectedComponent;


    return (
      <div>
            <MitchRoomMenu onChange={this.onChange.bind(this)} />
            <div className="p-20 mitchroom">
              {SelectedComponent && <SelectedComponent gw={gw} wp_id={wp_id} currentSeason={currentSeason}/>} {/* Render the selected component here */}
            </div>
      </div>
    );
  }
}

export default MitchRoomLayout;
