import React from 'react';
import Shield from '../../images/logo_shield.png';
import Pitch from '../../images/pitch.png';
import teamImages from '../../images/shirts/shirtsLookup.js';
import teamImages_2022 from '../../images/shirts/2022_shirtsLookup.js';
import teamImages_2023 from '../../images/shirts/2023_shirtsLookup.js';


class PersonalGWTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal: this.props.personalData,
      selectedGW: 38,
      selectedPlayer: null,
      scoring: this.props.selectedScoring,
      selectedYear: this.props.selectedYear
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ personal: this.props.personalData, scoring: this.props.selectedScoring })
    }
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const { selectedGW } = this.state;

    if (selectedGW.has(name)) {
      selectedGW.delete(name);
    } else {
      selectedGW.add(name);
    }

    this.setState({ selectedGW });
  };

  updateContent(prop) {
    this.setState({ league_ids: prop })
    this.getData(prop)
  }

  onChange(component) {
    this.setState({ selectedComponent: component });
  }


  handlePlayerClick = (entryId, elId) => {
    this.setState({
      selectedPlayer: { entryId, elId }
    });
  };

  handleModalClose = () => {
    this.setState({
      selectedPlayer: null
    });
  };

  getPlayerFixtureDetails = (entryId, elId) => {
    const gw = this.state.selectedGW
    const entry = this.state.personal.personalGWData[gw].data.find((entry) => entry.entry_id === entryId);
    const player = entry.Elements.find((el) => el.el_id === elId);

    if (player !== undefined) {
      const fixturesArr = Object.values(player.fixtures);
      const webName = player.web_name

      return (
        <div className="points-module">
          {fixturesArr.map((fixture, index) => (
            <div className="live-fixture" key={index}>
              {fixture.opp ?
                <div className="live-fixture_title">
                  <span className="live-fixture_name"> {webName}: {fixture.opp}  ({fixture["H/A"]})</span><span className="live-fixture_status"> {fixture.fixture_status}</span>
                </div> :
                <div className="live-fixture_title">
                  <span className="live-fixture_name"> {webName}:</span><span className="live-fixture_status"> No Fixture</span>
                </div>}
              <div className="live-fixture_details">
                {fixture.minutes != 0 && <div>Minutes: {fixture.minutes}</div>}
                {fixture.clean_sheets != 0 && <div>Clean Sheets: {fixture.clean_sheets}</div>}
                {fixture.assists != 0 && <div>Assists: {fixture.assists}</div>}
                {fixture.goals_scored != 0 && <div>Goals: {fixture.goals_scored}</div>}
                {fixture.own_goals != 0 && <div>Own Goals: {fixture.own_goals}</div>}
                {fixture.penalties_missed != 0 && <div>Pens Missed: {fixture.penalties_missed}</div>}
                {fixture.penalties_saved != 0 && <div>Pens Saved: {fixture.penalties_saved}</div>}
                {fixture.yellow_cards != 0 && <div>Yellow Cards: {fixture.yellow_cards}</div>}
                {fixture.red_cards != 0 && <div>Red Cards: {fixture.red_cards}</div>}
                {fixture.saves != 0 && <div>Saves: {fixture.saves}</div>}
                {fixture.bonus != 0 && <div>Bonus: {fixture.bonus}</div>}
                {fixture.goals_conceded != 0 && <div>Goals Conceded: {fixture.goals_conceded}</div>}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }


  getTeamImages = (selectedYear) => {
    switch (selectedYear) {
      case 2022:
        return teamImages_2022;
      case 2023:
        return teamImages_2023;
      default:
        return teamImages;
    }
  };

  getTeamImage = (teamId) => {
    const teamImages = this.getTeamImages(this.state.selectedYear);
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
  };

  render() {
    const { personal, selectedGW, scoring } = this.state

    const liveGWDetail = personal.personalGWData.filter(item => item.gw === selectedGW);

    const gwMenu = [...new Set(personal.personalGWData.map((data) => data.gw))];

    return (
      <div >
        <div className="flex g-10 mb-30 align-center center-content">
          <h2 className="mb-0">Gameweek</h2>
          <select className="w-100 grey-border" value={this.state.selectedGW} onChange={(event) => this.setState({ selectedGW: parseInt(event.target.value) })}>
            {gwMenu.map((gw) => (<option key={gw} value={gw}> {gw} </option>))}
          </select></div>
        <div className="live-gw_details text-center flex g-20 flex-wrap center-content space-evenly">
          {liveGWDetail[0].data.map((entry) => (
            <div key={entry.entry_id} className="entry-card flex space-evenly column" style={{ backgroundImage: `url(${Pitch})`, backgroundSize: 'cover' }}>
              <div className="live-header g-10 mt-10">
                <div className="flex align-center g-10">
                  <img className="shield" src={Shield} />
                  {scoring === 'c' ? (<h4 className="mb-0 white">{entry.entry_name}</h4>) 
                  : (<div className="block text-left">
                      <h4 className="mb-0 white">{entry.entry_name} ({entry.win})</h4>
                      <p className="italic white">{entry.opp_entry_name}</p>
                    </div>)}
                </div>
                <h4 className="mb-0 p-5 br-8 border-white white">{entry.current_total_points}</h4>
              </div>

              <div className="start-players">
                <div className="player-row flex space-evenly mb-10">
                  {entry.Elements.filter(
                    (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "GKP"
                  ).map((el) => (
                    <div key={el.el_id} className=" onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                      <div>{this.getTeamImage(el.team)}</div>
                      <div className="player_element_name text-overflow">{el.web_name}</div>
                      <div className="player_element_points">
                        {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="player-row flex space-evenly mb-10 g-5">
                  {entry.Elements.filter(
                    (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "DEF"
                  ).map((el) => (
                    <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                      <div>{this.getTeamImage(el.team)}</div>
                      <div className="player_element_name text-overflow">{el.web_name}</div>
                      <div className="player_element_points">
                        {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="player-row flex space-evenly mb-10  g-5">
                  {entry.Elements.filter(
                    (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "MID"
                  ).map((el) => (
                    <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                      <div>{this.getTeamImage(el.team)}</div>
                      <div className="player_element_name text-overflow">{el.web_name}</div>
                      <div className="player_element_points">
                        {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="player-row flex space-evenly mb-20  g-5">
                  {entry.Elements.filter(
                    (el) => el.position >= 1 && el.position <= 11 && el.singular_name_short === "FWD"
                  ).map((el) => (
                    <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                      <div>{this.getTeamImage(el.team)}</div>
                      <div className="player_element_name text-overflow">{el.web_name}</div>
                      <div className="player_element_points">
                        {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sub-players player-row flex space-evenly g-5">
                {entry.Elements.filter((el) => el.position >= 12 && el.position <= 15).map((el) => {
                  const totalPoints = Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0);
                  return (
                    <div key={el.el_id} className="onclick player-single flex-grow-1" onClick={() => this.handlePlayerClick(entry.entry_id, el.el_id)}>
                      <div>{this.getTeamImage(el.team)}</div>
                      <div className="player_element_name text-overflow">{el.web_name}</div>
                      <div className="player_element_points">
                        {Object.values(el.fixtures).reduce((acc, fixture) => acc + fixture.points, 0)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div>
          {this.state.selectedPlayer && (
            <div className="live-fixture_modal">
              <div className="modal-content">
                <span className="modal-close onclick" onClick={this.handleModalClose}>
                  &times;
                </span>
                {this.getPlayerFixtureDetails(this.state.selectedPlayer.entryId, this.state.selectedPlayer.elId)}
              </div>
            </div>
          )}
        </div>

      </div>
    );
  }
}

export default PersonalGWTable;
