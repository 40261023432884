import React, { useMemo } from 'react';
import axios from 'axios';

class StatsRoom extends React.Component {
  constructor(props) {
    super(props);
    //const distinctValues = ['GKP', 'DEF', 'MID', 'FWD']; // add this line to initialize distinctValues
    this.state = {
      loading: false,
      errorMessage: false,
      placeholder: true
    }
  }

  componentDidMount() {
    if (this.state.placeholder == false) {
    //do something
  }
  }

  render() {

    const {loading, errorMessage, placeholder} = this.state

    if (placeholder) {
      return (<div>
        <h2 className="mb-10">NEW Stats Room coming soon</h2>
        <p className="mb-30">Coming soon.</p>
        <h4 className="mb-10">In-depth player and team comparisons</h4>
        <p>This year we have made major upgrades to the player stats available on Draft FC</p>
        <p>Use the Stats Room to compare players and teams using: </p> 
        <ul>
          <li>In depth Attacking, Defending & General Play stats</li>
          <li>Global rostered & waivered history</li>
          <li>AI powered team outcomes and player xPts predictions</li>
          <li>Fixture tracker</li>
          </ul>
        </div>)
    }
  }
}

export default StatsRoom;
