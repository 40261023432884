import React from 'react';
import LoginForm from '../forms/login';
import MainNav from '../menu/navigation';
import PersonalLayout from '../personalDash/personalLayout';
import LiveGWLayout from '../liveGW/liveGWLayout';
import SquadPlannerLayout from '../waiverPlanner/squadPlannerLayout';
import DraftRoomLayout from '../draftRoom/draftRoomLayout';
import EnterTeam from '../forms/enterTeam';
import GlobalRankLayout from '../globalRank/globalRankLayout';
import PersonalHistoryLayout from '../personalHistory/personalHistoryLayout';
import HistoryPlaceholder from '../personalHistory/historyPlaceholder';
import MitchRoomLayout from '../mitchRoom/mitchRoomLayout';
import LineupsLayout from '../lineups/lineupsLayout';
import Logo from '../../images/main_logo.png';
import axios from 'axios';
import { responsiveFontSizes } from '@mui/material';
import FDRLayout from '../waiverPlanner/fdrLayout';
import PersonalMonthSummary from '../monthlyReport/personalMonthSummary';
import SelectLeague from '../menu/selectLeague';
import DiscordLayout from '../discord/discordLayout';


class MainLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      has_leagues: false,
      logged_in: false,
      view: 'draftroom',
      token: localStorage.getItem('token'),
      wp_id: localStorage.getItem('wp_id'),
      all_ids: null,
      errorLoading: false,
      holding: false,
      gw: null,
      role: null,
      product: null,
      currentSeason: null

    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleFilteredStorageChange);
    this.handleStorageChange();
  }

  
  componentWillUnmount() {
    window.addEventListener('storage', this.handleFilteredStorageChange);
    window.removeEventListener('resize', this.handleScreenWidthChange);
  }

  handleCallback = (data) => {
    console.log(data)
    this.setState(data, () => {
    this.doTeamIDsExist();})
  }

  handleLoginCallback = (data) => {
    this.setState(data, () => {
    this.testFunction();})
  }

  handleFilteredStorageChange = (event) => {
    if (event.key !== 'view') {
      this.handleStorageChange();
    }
  }
    
  handleStorageChange = () => {
    this.setState({
      token: localStorage.getItem('token'),
      wp_id: localStorage.getItem('wp_id'),
      view: localStorage.getItem('view'),
    }, () => {
      this.testFunction();
      this.doTeamIDsExist();
    }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.view !== prevState.view) {
      const contentElement = document.querySelector('.content');
      if (contentElement) {
        contentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  testFunction = () => {
    if (this.state.token) {
      this.setState({ logged_in: true });
      
      axios.post('/api/getUserRole', { token: this.state.token })
        .then((response) => {
          // Check if the response contains an error message
          if (response.data && response.data.error) {
            // Handle the specific error scenario
            this.setState({ errorLoading: true });
            this.clearData();
          } else {
            // Process the response normally if no error
            this.setState({
              wp_id: localStorage.getItem('wp_id'),
              role: response.data.role,
              product: response.data.product
            });
          }
        })
        .catch((error) => {
          // Handle any other errors
          this.setState({ errorLoading: true });
          this.clearData();
        });
    } else {
      this.setState({ logged_in: false });
    }
  }
  
  
  
  doLeaguesExist = () => {
    if (this.state.token) {
      this.setState({ logged_in: true });
      axios.post('https://draftfc.co.uk/wp-json/wp/v2/users/me', {}, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.state.token}`,
        //"Access-Control-Allow-Origin": "*"
      }
    })
      .then((response) => {
        this.setState({
          wp_id: localStorage.getItem('wp_id'),
          role: response.data.roles[0]
        })
      })
      .catch((error) => {
        this.setState({ errorLoading: true });
        this.clearData()
      });
    }  else {
      this.setState({ logged_in: false });
    }
  }

  doTeamIDsExist = () => {
    if (Array.isArray(this.state.all_ids) && this.state.all_ids.length > 0) {
      this.setState({ has_leagues: true });
    }
    else {
      this.setState({ has_leagues: false });
    }
  }

  clearData = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.setState({
      has_leagues: false,
      logged_in: false,
      view: 'draftroom',
      token: null,
      wp_id: null,
      role:  null,
      all_ids: null,
      errorLoading: false,
      holding: false,
      gw: null,
      product: null
    }, () => window.location.reload());
  }


  render() {
    const {all_ids, leagueID, gw, product, wp_id, currentSeason, entryID} = this.state;

    if (this.state.logged_in && !this.state.role) {
      return (
        <div className="middleBlock mt-60 p-20">
          <div className="center-content">
            <img className="logo mb-30" src={Logo} />
          </div>
        <div className="content-wrapper center-content mb-20">
        <h2>Loading app...</h2>
        </div>
        <p>We've made a lot of updates over the summer, so if you're stuck on this page for a long time click below to clear out the cobwebs and try again.</p>
        <button className="download-data fullwidth" onClick={this.clearData}>Clear app data</button>
        </div>)

    }

    if (this.state.holding == true) {
      return (
      <div className="middleBlock mt-60 p-20 text-center">
        <div className="center-content">
          <img className="logo mb-30" src={Logo} />
        </div>
      <div className="content-wrapper">
      <h4>Under Construction</h4>
      <p>draft.premierleague.com will soon be live for the 24/25 season.</p>
      <p>We are finalisng the details to launch the Draft FC app for this year, and will be back on Wednesday 17th July. To manage your membership click on the button below.</p>
      <button className="download-data fullwidth mt-30"><a className="download-link" href="https://draftfc.co.uk/" target="_blank">Go to My Account</a></button>
      </div></div>)
    }
  
    if (this.state.logged_in && !['customer', 'administrator', 'author'].includes(this.state.role)) {
      return (
      <div className="middleBlock mt-60 p-20">
        <div className="center-content">
          <img className="logo mb-30" src={Logo} />
        </div>
      <div className="content-wrapper">
      <h4>Become a member to access Draft FC</h4>
      <p>It looks like you have registered a Draft FC account, but don't have a monthly or annual membership.</p>
      <p>Click on the button below to sign up and access all of the Draft FC features.</p>
      <button className="download-data fullwidth mt-30"><a className="download-link" href="https://draftfc.co.uk/pricing" target="_blank">Sign up now</a></button>
      </div></div>)
    }

    return (
      <div >
        {this.state.logged_in ?
                  (this.state.has_leagues ?
            <div className="body">
              <div className="navigation"><MainNav all_ids={all_ids} role={this.state.role} leagueID={leagueID} parentCallback={this.handleCallback} /></div>
              {this.state.gw != null ?
              <div className="content">
                {this.state.view !== 'history' && (
                <SelectLeague all_ids={all_ids} leagueID={leagueID} parentCallback={this.handleCallback} />
                )}
                {this.state.view === 'setteam' && (<div className="p-20"> <EnterTeam parentCallback={this.handleCallback} logged_in={this.state.logged_in} /> </div>)}
                {this.state.view === 'personal' && (<PersonalLayout leagueID={leagueID} gw={gw} />)}
                {this.state.view === 'livegw'  && (<div className="p-20"> <LiveGWLayout className="p20" leagueID={leagueID} gw={gw} /></div>)}
                {(this.state.view === 'global' || this.state.view === null) && (<div> <SquadPlannerLayout leagueID={leagueID} gw={gw} wp_id={wp_id} entryID={entryID} /></div>)}
                {this.state.view === 'lineups' && (<div className="p-20"><LineupsLayout  gw={gw} leagueID={leagueID}/></div>)}
                {(this.state.view === 'draftroom') && (<div> <DraftRoomLayout leagueID={leagueID} gw={gw} product={product} role={this.state.role}/></div>)}
                {this.state.view === 'globalrank' && (<div className="p-20"> <GlobalRankLayout leagueID={leagueID} gw={gw} /></div>)}
                {this.state.view === 'history' && (< PersonalHistoryLayout all_ids={all_ids} />)}
                {this.state.view === 'mitch' && (<MitchRoomLayout gw={gw} wp_id={wp_id} currentSeason={currentSeason}/>)}
                {this.state.view === 'fdr' && (<div className=""><FDRLayout gw={gw} wp_id={wp_id} leagueID={leagueID}/> </div>)}
                {this.state.view === 'monthlyreport' && (<div className="p-20"><PersonalMonthSummary gw={gw} leagueID={leagueID}/> </div>)}
                {this.state.view === 'discord' && (<div className="p-20"><DiscordLayout wp_id={wp_id} gw={gw} leagueID={leagueID}/> </div>)}
              </div> : 
              <div className="content"><h2>Loading main data...</h2></div>}
            </div> :
            <div className="p-20" >
            <EnterTeam parentCallback={this.handleCallback} />
            </div>):
          <div className="content p-20">
            <LoginForm parentCallback={this.handleLoginCallback} />
          </div>}
      </div>
    );
  }
}

export default MainLayout;