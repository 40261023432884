import React from 'react';
import './App.css';
import MainLayout from './components/layout/mainLayout';

function App() {
  return (
    <div className="App">
      <MainLayout />
      <div className="footer">
      </div>
    </div>
  );
}

export default App;
