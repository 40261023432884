import React from 'react';
import teamImages from '../../images/shirts/shirtsLookup';
import axios from 'axios';


class PersonalMonthSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal: this.props.personalData,
      selectedMonth: this.props.personalData.monthlyPersonalSummary[0].months[0].month,
      scoring: this.props.selectedScoring,
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ personal: this.props.personalData, scoring: this.props.selectedScoring })
    }
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const { selectedGW } = this.state;

    if (selectedGW.has(name)) {
      selectedGW.delete(name);
    } else {
      selectedGW.add(name);
    }

    this.setState({ selectedGW });
  };

  onChange(component) {
    this.setState({ selectedComponent: component });
  }


  getTeamImage = (teamId) => {
    const teamImage = teamImages.find((team) => team.team_id === teamId);
    return teamImage ? <img className="shirt-icon" src={teamImage.src} alt={teamImage.team_id} /> : null;
  }

  render() {
    const { personal, scoring, selectedMonth } = this.state

    if (!personal) {
      return (
        <div>
        <h2>Monthly Reports</h2> 
       <div className="skeleton-container">
       <div className="skeleton-header"></div>
       <div className="skeleton-paragraph"></div>
       <div className="skeleton-block"></div>
       </div></div>)
    }

    const monthMenu = [...new Set(personal.monthlyPersonalSummary.flatMap((entry) => entry.months.map((monthEntry) => monthEntry.month)))];

    return (
      <div className="max-content center-margin" >
        <div className="flex column g-10 mb-30 align-center flex-center">
          <h3 className="mb-0">Monthly Report</h3>
          <select className="grey-border" value={selectedMonth} onChange={(event) => this.setState({ selectedMonth: event.target.value })}>
            {monthMenu.map((month) => (<option key={month} value={month}> {month} </option>))}
          </select></div>


      <div>
        {personal.monthlyPersonalSummary.sort((a, b) => {
    const aMonth = a.months.find(month => month.month === selectedMonth);
    const bMonth = b.months.find(month => month.month === selectedMonth);
    if (scoring === 'h') {
      // Primary sort by h2h_pts in descending order
      if (bMonth.h2h_pts !== aMonth.h2h_pts) {
        return bMonth.h2h_pts - aMonth.h2h_pts;
      }
      // Secondary sort by total_points in descending order if h2h_pts are equal
      return bMonth.total_points - aMonth.total_points;
    } else {
      // Sort by total_points in descending order
      return bMonth.total_points - aMonth.total_points;
    }
  })
  .map((entry) => (
          <div className="monthly-wrapper flex-center column flex" >
            <div className="flex flex-center g-20 align-center mb-20">
            <h3 className="mb-0">{entry.entry_name}</h3>
            {entry.months.filter((month) => month.month === selectedMonth).map((month) => (
              <div className="flex g-10 align-center">
                <h4 className="mb-0 p-10 br-8 text-center grey-border monthly-points">{scoring === 'c' ? month.total_points : month.h2h_pts}</h4>
                <div>
                <p className="mb-0 font-12">Rank: {month.end_rank}</p>
                {month.rank_change === 0 ? (<p className="mb-5 font-12">Change: {month.rank_change}</p>) :
                  (month.rank_change < 0 ? (<p className="red mb-5 font-12">Change: {month.rank_change}</p>) :
                    (<p className="green mb-5 font-12">Change: {month.rank_change}</p>))}
                </div>
              </div>
            ))}
          </div> 
          {entry.months.filter((month) => month.month === selectedMonth).map((month) => (
          <div className="flex column align-center">
              {scoring === 'h' && month.fixtures.map((fixture) => (
                <div className="flex column">
                  <p className="mb-5 font-12">
                  <span className={
                      fixture.win === 'L' ? 'lose' :
                      fixture.win === 'W' ? 'win' :
                      fixture.win === 'D' ? 'draw' : ''
                    }>{fixture.win}</span>
                    <span className="italic"> vs. </span>
                    <span>{fixture.opp_entry_name}</span> {/* Corrected to display the variable */}
                  </p>
                </div>
              ))}
              </div>))}

          {entry.months.filter((month) => month.month === selectedMonth).map((month) => (
            <div className="flex flex-wrap flex-center">
              <div className="flex flex-wrap g-20 flex-center mt-20">
                <div>
                  <p className="bold mb-5 text-center">Best Players</p>
                  <div className="flex g-5 space-evenly">
                  {month.top_players.map((player) => (
                  <div className="player-single text-center flex-grow-1">
                    <div>{this.getTeamImage(player.team)}</div>
                    <div className="player_element_name text-overflow">{player.web_name}</div>
                    <div className="player_element_points black-border green">{player.fixture_total_points_x}</div>
                  </div>
                ))} 
                </div>
                </div>
                <div>
                  <p className="bold mb-5 text-center">Worst Players</p>
                  <div className="flex g-5 space-evenly">
                  {month.bottom_players.map((player) => (
                  <div className="player-single text-center flex-grow-1">
                    <div>{this.getTeamImage(player.team)}</div>
                    <div className="player_element_name text-overflow ">{player.web_name}</div>
                    <div className="player_element_points black-border red">{player.fixture_total_points_x}</div>
                  </div>
                ))} 
                </div>
                </div>

                <div className="flex g-10">
              <div className="flex column g-5 space-between ">
                  {month.position_contribution.map((position) => (
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">{position.singular_name_short}</span>
                    <span className="right-mini-card black-border text-center" >{position.fixture_total_points}</span>
                  </div>
                ))} 
                </div>

                  <div className="flex column g-5 space-between "><div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Mins</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.minutes}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Gls</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.goals_scored}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Asst</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.assists}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">CS</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.clean_sheets}</span>
                  </div></div>


                  <div className="flex column g-5 space-between "><div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">GC</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.goals_conceded}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">OG</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.own_goals}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">PMiss</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.penalties_missed}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Saves</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.saves}</span>
                  </div></div>


                  <div className="flex column g-5 space-between ">
                    <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">PSave</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.penalties_saved}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Red</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.red_cards}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Yel</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.yellow_cards}</span>
                  </div>
                  <div className="flex align-stretch minw-80">
                    <span className="left-mini-card text-center">Bonus</span>
                    <span className="right-mini-card black-border text-center" >{month.points_breakdown.bonus}</span>
                  </div></div>
                  </div>
                </div>
            </div>
            ))}
          </div>
            ))
            }
            </div>
      </div>
    );
  }
}

export default PersonalMonthSummary;
