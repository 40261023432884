import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { CSVLink } from 'react-csv';
import { FilterList } from '@mui/icons-material';

class TotalPointsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal: this.props.personalData,
      selectedMonth: new Set(['August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May']),
      viewToggle: 'table'
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personalData !== this.props.personalData) {
      this.setState({ personal: this.props.personalData })
    }
  }

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    const { selectedMonth } = this.state;
  
    if (selectedMonth.has(name)) {
      selectedMonth.delete(name);
    } else {
      selectedMonth.add(name);
    }
  
    this.setState({ selectedMonth });
  };

  handleTableClick= () => {
    this.setState({viewToggle: 'table'});
  }
  
  handleChartClick= () => {
    this.setState({viewToggle: 'chart'});
  }

  toggleFilter = () => {
    !this.state.filter ? this.setState({filter: true}) : this.setState({filter: false})
  }
  
   render() {
    const { personal, selectedMonth } = this.state

    if (!personal) {
      return <div>loading...</div>;
    }

    const months = [...new Set(personal.personalTotalPoints.map((data) => data.gw_month))]; // Get distinct rounds
    const sortedMonths = months
    .map(m => [new Date(`${m} 1, 2000`).getMonth(), m])
    .sort((a, b) => (a[0] < 7 ? a[0] + 12 : a[0]) - (b[0] < 7 ? b[0] + 12 : b[0]))
    .map(([_, m]) => m);

    const filteredData = personal.personalTotalPoints.filter((row) => selectedMonth.has(row.gw_month));

    const chartData = Object.values(filteredData.reduce((acc, curr) => {
      const { entry_id, entry_name, colour, short_name, fixture_total_points } = curr;
      const key = `${entry_id}-${entry_name}-${colour}-${short_name}`;
      if (!acc[key]) {
        acc[key] = {
          entry_id,
          entry_name,
          colour,
          short_name,
          fixture_total_points: 0
        };
      }
      acc[key].fixture_total_points += fixture_total_points;
      return acc;
    }, {})).sort((a, b) => b.fixture_total_points - a.fixture_total_points);

    const managers = [...new Set(filteredData.map((data) => data.entry_name))]; // Get distinct managers
    const managerPoints = managers.map(manager => ({
      entry_name: manager,
      fixture_total_points: filteredData
        .filter(data => data.entry_name === manager)
        .reduce((acc, data) => acc + data.fixture_total_points, 0)
    }));    
    const sortedManagers = managerPoints.sort((a, b) => b.fixture_total_points - a.fixture_total_points)
      .map(manager => manager.entry_name);

    const headers = [
        { label: 'Manager', key: 'manager' },
        ...sortedMonths.map((gw_month) => ({ label: gw_month, key: gw_month })),
      ];
    
    const csvData = sortedManagers.map((manager) => {
        const rowData = { manager };
        sortedMonths.forEach((gw_month) => {
          const filteredData = personal.personalTotalPoints.filter((item) => item.entry_name === manager && item.gw_month === gw_month);
          const sum = filteredData.reduce((accumulator, currentValue) => accumulator + currentValue.fixture_total_points, 0);
          rowData[gw_month] = sum || 0;
        });
        return rowData;
      });


    return (
      <div>
        <div className="content-wrapper mb-10">
        <div className="flex flex-wrap space-between mb-20 align-center g-10">
            <h3 className="mb-0">Total Points</h3>
            <div className="chart-buttons flex flex-wrap space-between align-center g-20">
            <button className="show-desktop download-data"><CSVLink className="download-link" data={csvData} headers={headers} filename={'totalPoints_data.csv'}>Download Data</CSVLink></button>
            <div className="tablechart-toggle onclick">
                  <button className={this.state.viewToggle == 'table' ? "active": null} onClick={this.handleTableClick}>Table</button>
                  <button className={this.state.viewToggle == 'chart' ? "active": null} onClick={this.handleChartClick}>Chart</button>
                </div>
          <div className="text-right">
            <button className="filter" onClick={this.toggleFilter}><FilterList/></button>
            {!this.state.filter ? null :
              <div className="filter-container flex column g-5 mb-10 text-left ">
                {sortedMonths.map((value, index) => (
                  <label className="text-overflow" key={index}>
                    <input
                      type="checkbox"
                      name={value}
                      checked={selectedMonth.has(value)}
                      onChange={this.handleCheckboxChange}
                      />
                    {value}
                  </label>
                ))}
              </div>}</div>
          </div>
                </div>
          {this.state.viewToggle == 'table' ?
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Manager</th>
                  {sortedMonths.map((month) => (
                    <th key={month}>{month}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedManagers.map((manager) => (
                  <tr key={manager}>
                    <td className="content-width">{manager}</td>
                    {sortedMonths.map((month) => {
                      const data = personal.personalTotalPoints.find((item) => item.entry_name === manager && item.gw_month === month);
                      return (
                        <td className="content-width" key={`${manager}-${month}`}>
                          {data ? <div>{data.fixture_total_points.toLocaleString()} </div>: ''}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div> :
          <div className="mt-20 vh-50" style={{ width: '100%'}}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{ top: 0, right: 0, left: 5, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="short_name" label={{ value: 'Manager', position: 'insideBottom', dy: 15 }} />
                  <YAxis label={{ value: 'Points', position: 'insideLeft', dx: 0, angle: -90 }} />
                  <Tooltip />
                  <Bar dataKey="fixture_total_points" radius={[4, 4, 0, 0]}>
                    {chartData.map(data => (
                      <Cell key={data.short_name} fill={data.colour} />
                    ))}
                  </Bar>

                </BarChart>
              </ResponsiveContainer>

            </div>}
        </div>
        <button className="show-mobile mb-20 download-data fullwidth"><CSVLink className="download-link" data={csvData} headers={headers} filename={'totalPoints_data.csv'}>Download Data</CSVLink></button>
        <div className="br-16 p-20 grey-bg flex flex-wrap flex-start g-10">
        {sortedManagers.map((manager) => {
          const managerData = personal.personalTotalPoints.find((data) => data.entry_name === manager);
          return (
            <span key={manager} className="p-5">
              <span className="circle" style={{background: managerData.colour }}></span>
              {manager}
            </span>
          );
        })}</div>

      </div>
    );
  }
}

export default TotalPointsChart;
