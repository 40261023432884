import InputDraftRankDash from "./inputDraftRankDash";
import xptsTest from "./xptsTest";
import downloadStat from "./downloadStat";
import InputPredictedLineups from "./inputPredictedLineups";
import AIRequests from "./aiRequests";
import TaranButtons from "./taranButtons";


export const mitchRoomConfig = [
	{
		name: 'Draft Ranking',
		component: InputDraftRankDash,
	},
	{
		name: 'Global Stats',
		component: downloadStat,
	},
	{
		name: 'xPts',
		component: xptsTest,
	},
	{
		name: 'Predicted Lineups',
		component: InputPredictedLineups,
	},
	{
		name: 'Taran Buttons',
		component: TaranButtons,
	}

];